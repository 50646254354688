.b-product_prices {
    display: flex;
    justify-content: space-between;
    margin-bottom: 0;
    padding-top: 0;
    font-size: rem-calc(14);

    &.bvWrap {
        .b-price {
            padding: 8px 0;
            margin: 8px 0 0;
        }
    }
}
