@mixin t-title-1 {
    @include font(main_bold, rem-calc(64), rem-calc(72));

    letter-spacing: -1px;

    @include respond-to(mobile) {
        font-size: rem-calc(32);
        line-height: rem-calc(40);
    }
}

@mixin t-title-2 {
    @include font(main_bold, rem-calc(48), rem-calc(56));

    @include respond-to(mobile) {
        font-size: rem-calc(24);
        line-height: rem-calc(32);
    }
}

@mixin t-title-3 {
    @include font(main_bold, rem-calc(40), rem-calc(48));

    @include respond-to(mobile) {
        font-size: rem-calc(24);
        line-height: rem-calc(32);
    }
}

@mixin t-title-4 {
    @include font(main_bold, rem-calc(32), rem-calc(40));

    @include respond-to(mobile) {
        font-size: rem-calc(16);
        line-height: rem-calc(24);
    }
}

@mixin t-title-5 {
    @include font(main_bold, rem-calc(24), rem-calc(32));

    @include respond-to(mobile) {
        font-size: rem-calc(16);
        line-height: rem-calc(24);
    }
}

@mixin t-title-6 {
    @include font(main_med, rem-calc(16), rem-calc(24));

    @include respond-to(mobile) {
        font-size: rem-calc(14);
        line-height: rem-calc(20);
    }
}
