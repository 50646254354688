.ua-rewards-checkout-container {
    border-top: rem-calc(1) solid $grey3;
    border-bottom: rem-calc(1) solid $grey3;
    padding: rem-calc(24) 0 rem-calc(6);
    margin-top: rem-calc(24);

    @include respond-to(tablet-landscape) {
        border-bottom: none;
    }

    .g-carousel {
        padding: 0;
    }

    .ua-rewards-heading {
        @include font(main_semi_bold, rem-calc(16), 1.5);

        margin-bottom: rem-calc(5);
    }

    .ua-rewards-checkout-vouchers {
        background-color: $white;

        .js-carousel.carousel-disabled {
            .m-more-one {
                justify-content: left;

                .g-carousel-slide {
                    padding: 0;
                }
            }
        }

        .swiper-container.only-one {
            margin-bottom: rem-calc(10);
        }

        .g-carousel-scrollbar {
            position: absolute;
            bottom: 0;
            left: 0;
            right: 0;
            overflow: hidden;
            background: $white;
            cursor: pointer;
        }

        .swiper-scrollbar-drag {
            background: $grey3;
            height: 5px;
            position: relative;
        }

        .swiper-container {
            margin: rem-calc(10);
            margin-bottom: rem-calc(17);
        }

        .g-carousel-slide {
            width: rem-calc(190);

            @media screen and (min-width: 375px) {
                width: rem-calc(233);
            }

            @media screen and (min-width: 425px) {
                width: rem-calc(272);
            }

            @include respond-to(phones-landscape) {
                width: rem-calc(314);
            }

            @include respond-to(tablets) {
                width: rem-calc(298);
            }

            @include respond-to(desktop) {
                width: rem-calc(172);
            }

            @include respond-to(desktop-large) {
                width: rem-calc(268);
            }
        }

        .ua-rewards-checkout-card {
            cursor: pointer;
            padding: rem-calc(5);
            border: rem-calc(1) solid $grey3;
            min-width: 100%;
            background-color: $grey1;

            &.selected {
                border: rem-calc(1) solid $black;

                &::after {
                    position: absolute;
                    right: rem-calc(5);
                    bottom: rem-calc(5);
                    content: '';
                    height: rem-calc(25);
                    width: rem-calc(25);
                    background-image: url(../images/checkmark-green.svg);
                    background-size: cover;
                }

                .ua-rewards-checkout-card-content span {
                    margin-right: 30px;
                }
            }

            .ua-rewards-checkout-card-content {
                display: flex;

                .ua-rewards-checkout-voucher-img {
                    margin-right: rem-calc(5);
                }

                span {
                    max-height: rem-calc(40);
                    overflow: hidden;
                    text-overflow: ellipsis;
                    display: -webkit-box;
                    -webkit-line-clamp: 2;
                    -webkit-box-orient: vertical;
                }
            }
        }
    }

    .ua-rewards-no-rewards {
        padding-top: rem-calc(15);
        padding-bottom: rem-calc(6);

        .no-rewards-heading {
            @include font(main_semi_bold, rem-calc(14), rem-calc(21));

            color: $grey5;
        }

        .no-rewards-text-grey {
            @include font(main_semi_bold, rem-calc(12), rem-calc(20));

            color: $grey4;
        }
    }
}

.cart-no-atome-text .ua-rewards-checkout-container {
    border-top: none;
    margin-top: 0;
}

.b-promo_checkout {
    .ua-rewards-checkout-vouchers {
        .g-carousel-slide {
            @include respond-to(desktop) {
                width: rem-calc(277);
            }
        }
    }
}

.b-order-total_summary,
.points-container-mobile,
.points-container-desktop {
    .rewards-box {
        .rewards-info-orderconfirm {
            display: none;
        }
    }
}

.b-order-confirmation_right {
    .order-confirm-reward-points {
        .rewards-box {
            .registered-rewards-info {
                display: none;
            }
        }
    }
}

.order-confirm-reward-points {
    float: left;
    width: 100%;
}

.rewards-box {
    display: flex;
    align-items: center;
    padding: rem-calc(15);
    margin-top: rem-calc(24);
    background-color: $grey2;

    @include respond-to(mobile) {
        width: 100%;
    }

    .ua-rewards-checkout-voucher-img {
        margin-right: rem-calc(10);
    }

    .rewards-info-guest,
    .rewards-info {
        flex-grow: 1;
        display: flex;
        flex-direction: column;
        justify-content: center;
        margin-right: auto;

        h3 {
            font-weight: bold;
        }

        p {
            margin: 0;
            display: block;
        }

        a.js-register {
            color: $red;
            font-weight: bold;
            text-decoration: none;
            white-space: nowrap;
        }
    }

    .rewards-points {
        font-size: rem-calc(16);
        font-weight: bold;
        color: $red;
        margin-left: rem-calc(10);
        white-space: nowrap;
    }
}

.uar-estimated-points::after {
    width: rem-calc(32);
    line-height: 14px;
    content: '';
    display: inline-block;
    font-size: 20px;
    letter-spacing: 2px;
    animation: ellipsis 1.5s infinite;
}

@keyframes ellipsis {
    0% {
        content: '';
    }

    33% {
        content: '.';
    }

    66% {
        content: '..';
    }

    100% {
        content: '...';
    }
}

.points-ready::after {
    content: none;
    animation: none;
}

.uar-tooltip-content {
    position: relative;
    padding: 0 0.625rem 0 0.25rem;
    text-transform: none;

    .g-tooltip-icon {
        @include icon(question);

        cursor: pointer;
        align-items: center;

        &::before {
            font-size: 0.8rem;
        }
    }

    .g-tooltip-text {
        z-index: 2;
        @include respond-to(no-phones) {
            width: rem-calc(370);
            top: calc(100% + #{rem-calc(8)});
            left: rem-calc(-93);
        }

        @include respond-to (desktop) {
            &::after {
                left: 77%;
            }
        }

        @include respond-to(tablets) {
            left: rem-calc(90);

            &::after {
                left: 28%;
            }
        }

        @include respond-to(phones) {
            width: calc(55vw - 48px);
            left: 0;
            transform: none;
            top: calc(100% + #{rem-calc(6)});

            &::after {
                left: rem-calc(8);
            }

            .g-tooltip {
                position: static;
            }
        }
    }
}
