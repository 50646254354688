.b-wishlist {
    &-link {
        @include icon(fav-default);

        display: none;

        @include respond-to(desktop) {
            display: flex;
            padding: 0;
            text-decoration: none;
            color: $grey4;
            position: relative;
            left: 4px;
            margin-bottom: 3px;
        }
    }

    &-icon {
        @include icon(fav-default);
    }
}
