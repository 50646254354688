.b-checkout_right {
    @include respond-to(mobile) {
        display: flex;
        flex-direction: column;
    }

    .b-checkout_product-summary {
        &.b-order_est_coupon {
            @include respond-to(mobile) {
                order: 2;
            }
        }

        .product-summary-block {
            .t-lineitem_attributes {
                text-transform: none;
            }

            .shipment-block + .shipment-block {
                &::before {
                    display: none;
                }
            }
        }

        .b-order-total_summary {
            span.order-receipt-label,
            span.order-summary_itemsvalue {
                font-size: rem-calc(14);
            }

            .order-summary_items {
                &.grand-total-element {
                    padding-top: rem-calc(24);
                    margin-top: rem-calc(24);
                    border-top: 1px solid $grey3;

                    @include respond-to(mobile) {
                        margin-bottom: rem-calc(24);
                    }

                    span.order-receipt-label,
                    span.order-summary_itemsvalue,
                    .grand-total {
                        font-size: rem-calc(16) !important;
                        font-weight: $font-semi-bold;
                    }
                }
            }

            .b-order-saved-total {
                margin-top: rem-calc(10);

                span {
                    color: $red;
                    letter-spacing: rem-calc(0.25);
                    font-size: rem-calc(16);
                    font-weight: $font-medium;
                }
            }
        }

        &.order-product-summary {
            @include respond-to(mobile) {
                -webkit-order: 1;
                order: 1;
            }

            .pricing,
            .non-adjusted-price {
                font-size: rem-calc(12);
                color: $grey5;
            }

            .b-header_minicart-item-details {
                &.b-header_minicart-item-row {
                    @include respond-to(mobile) {
                        display: flex;
                    }
                }
            }

            .line-item-no-promo {
                @include icon(alert);

                &::before {
                    margin-right: rem-calc(5);
                    vertical-align: middle;
                }

                @include font(main, rem-calc(12), 1.6);

                color: $black;
            }

            .b-promo_bag_summary {
                font-size: rem-calc(12);
                color: $grey5;

                .b-promo {
                    &_applied {
                        padding: 0;

                        .icon-promo {
                            @include icon(promo-tag);

                            padding: 0 rem-calc(5) 0 0;
                        }
                    }
                }
            }
        }
    }

    .idme {
        &__promo-button {
            display: flex;
            justify-content: space-between;
            padding-top: rem-calc(6);

            .login-trigger {
                flex-basis: 50%;
                max-width: 50%;
                margin-bottom: rem-calc(16);
                width: 100%;
                padding-left: 8px;

                &:first-of-type {
                    padding-right: 8px;
                    padding-left: 0;
                }

                img {
                    width: 100%;
                }
            }
        }

        &__promo-verification {
            @include font(main, rem-calc(11), 1.6);

            a {
                color: $black;
                text-decoration: none;
            }
        }

        &-icon {
            img {
                margin-right: rem-calc(4);
            }
        }
    }
}
