.b-product_actions {

    @include respond-to(desktop) {
        &.prices-add-to-cart-actions {
            border-bottom: 0.0625rem solid $grey3;
            padding-bottom: 2rem;
        }
    }
}

.product-quickview {
    .e-giftcard {
        .b-product_attrs-item,
        .b-product_attribute {
            border-bottom: none;
        }

        .b-product_attrs-item {
            &.has-error {
                border-bottom: 1px solid $red;
            }
        }

        .b-product_attribute {
            padding: 0;
        }
    }
}

.b-size_pdp {
    .b-select-size-outer {
        ul {
            &.b-swatches_amount {
                margin-bottom: rem-calc(10);
            }
        }
    }
}

.b-physicalgiftcard_outer {
    .b-input_row-input {
        font-size: 0.9rem;
    }
}

.b-egiftcard-message {
    .b-input_row-label {
        z-index: 1;
    }

    textarea.b-input_row-input {
        height: auto;
        resize: none;
        min-height: rem-calc(88);
        padding: rem-calc(16);
        line-height: 1.5;
    }
}
