.b-physicalgiftcard_outer {
    .l-pdp {
        &-images {
            .b-product_carousel-wrapper {
                justify-content: center;
            }
        }

        &-info {
            .b-product_attrs {
                .b-product_actions {
                    border-top: none;
                }
            }
        }
    }
}
