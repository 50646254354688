.g-selectric {
    display: flex;
    justify-content: space-between;
    border: 1px solid $grey3;
    border-radius: rem-calc(4);
    background: $white;

    &-wrapper {
        position: relative;
        cursor: pointer;
    }

    &-responsive {
        width: 100%;
    }

    &-container {
        position: relative;
    }

    &-label {
        @include font(main_med, rem-calc(11), rem-calc(16));

        position: absolute;
        top: -1 * spacing(xs2);
        left: rem-calc(12);
        z-index: 2;
        padding: 0 calc(spacing(xs2) / 2);
        background: $white;
    }

    &-items {
        position: absolute;
        top: 100%;
        left: 0;
        z-index: -1;
        display: none;
        padding: spacing(xs) spacing(xs2) spacing(xs2);
        border-color: $black;
        border-style: solid;
        border-width: 0 1px 1px;
        border-radius: 0 0 rem-calc(4) rem-calc(4);
        background: $white;
        color: $grey5;

        .g-selectric-above & {
            top: auto;
            bottom: 100%;
        }

        li {
            display: flex;
            align-items: center;
            justify-content: space-between;
            padding: calc(spacing(xs2) / 2)  spacing(xs2);
            font-size: rem-calc(12);
            line-height: rem-calc(16);
            cursor: pointer;

            &.selected,
            &.highlighted {
                @include icon('check', 'after', 16);

                color: $black;
            }

            &:hover {
                background: $grey2;
                font-weight: $font-medium;
                color: $black;
            }
        }

        .disabled {
            background: none !important;
            opacity: 0.4;
            user-select: none;
            cursor: default !important;
        }
    }

    &-items &-scroll {
        height: 100%;
        overflow: auto;

        // For Internet Explorer
        scrollbar-track-color: $white;
        scrollbar-face-color: $grey3;
        -ms-overflow-style: -ms-autohiding-scrollbar;

        &::-webkit-scrollbar {
            width: rem-calc(4);
            height: rem-calc(32);
            border-radius: rem-calc(4);
        }

        &::-webkit-scrollbar-thumb {
            background: $grey3;
        }

        &::-webkit-scrollbar-track {
            background: $white;
        }
    }

    &-input {
        @include hide(visually);

        margin: 0 !important;
        background: none !important;
        outline: none !important;
    }

    .label {
        display: inline-block;
        flex: 1 1 auto;
        height: rem-calc(44);
        padding-left: spacing(xs);
        font-size: rem-calc(12);
        font-weight: $font-medium;
        line-height: rem-calc(44);
        text-overflow: ellipsis;
        white-space: nowrap;
        color: $black;
        overflow: hidden;
        user-select: none;
    }

    .button {
        @include icon('caret-down', 'after', 16);

        display: block;
        min-height: 100%;
        padding: calc(spacing(xs) / 2) spacing(xs);
        color: transparent;
        user-select: none;

        &::after {
            position: absolute;
            top: 50%;
            right: spacing(xs);
            color: $black;
            transform: translateY(-50%);
        }
    }
}

.g-selectric-hover .g-selectric,
.g-selectric-focus .g-selectric {
    border-color: $black;
}

.g-selectric-focus .g-selectric {
    .m-accessible-on & {
        box-shadow: 0 0 4px 2px $focus-color !important;
        transition: box-shadow 0.15s ease-in-out;
    }
}

.g-selectric-open {
    z-index: 2;

    .g-selectric {
        border-color: $black;
        border-radius: 4px 4px 0 0;
    }

    .g-selectric-items {
        display: block;
        z-index: 2;
        top: 46px;
    }

    .button {
        transform: rotate(180deg);
    }
}

.g-selectric-disabled,
.g-selectric-disabled + label {
    opacity: 0.4;
    user-select: none;
    cursor: default;
}

.g-selectric-hide-select {
    position: relative;
    width: 0;
    height: 0;
    overflow: hidden;

    select {
        position: absolute;
        left: -100%;
    }

    &.g-selectric-is-native {
        position: absolute;
        z-index: 10;
        width: 100%;
        height: 100%;

        select {
            position: absolute;
            top: 0;
            right: 0;
            left: 0;
            z-index: 1;
            width: 100%;
            height: 100%;
            border: none;
            opacity: 0;
        }
    }
}

.g-selectric-temp-show {
    position: absolute !important;
    display: block !important;
    visibility: hidden !important;
}
