.bonusProductModel {
    position: fixed;
    top: 0;
    left: 0;
    z-index: 1050;
    display: none;
    width: 100%;
    height: 100%;
    overflow: hidden;
    background: #1d1e1d29;
    outline: 0;
}

.choose-bonus-product-dialog {
    background: #fff;
    position: absolute;
    float: left;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
}

.modal-dialog.choose-bonus-product-dialog.pdp-bonus-product-model.disbalePdpPage {
    display: none !important;
}

.bouns-detail-panel span.color-value.swatch-circle.swatch-value.selected.unselectable::after {
    content: "" !important;
    background: transparent;
    cursor: not-allowed;
}

.modal-dialog.choose-bonus-product-dialog.pdp-bonus-product-model span.color-value.swatch-circle.swatch-value.unselectable {
    background: #fff;
    border-radius: 1.25em;
    border: 0.063em solid rgba(0, 0, 0, 0.3);
    display: inline-block;
    margin-right: 0.313em;
    position: relative;
}

.modal-dialog.choose-bonus-product-dialog.pdp-bonus-product-model span.color-value.swatch-circle.swatch-value.selected.selectable::after {
    outline: none;
    position: relative;
    width: 1.875rem;
    height: 1.875rem;
    padding: 15px;
    border: 1px solid #0d0c0c;
    border-radius: 50%;
    cursor: pointer;
    display: block;
}

.choose-bonus-product-dialog .modal-header {
    background-color: #fff;
    margin-bottom: 2px;
    padding-bottom: 0.3125rem;
}

button.btn.btn-block.btn-outline-primary.select-bonus-product:disabled {
    background-color: #fff !important;
    pointer-events: none !important;
    border: 2px solid $grey3;
}

.modal-dialog.choose-bonus-product-dialog.pdp-bonus-product-model .close {
    width: 1rem;
    height: 1rem;
    border: 0;
    background-color: transparent;
    cursor: pointer;
    float: right;
    margin-right: 13px;
    font-size: 23px !important;
}

.modal-dialog.choose-bonus-product-dialog button.close.pull-right {
    float: right;
    background: #fff !important;
    border: none;
}

.modal-dialog.choose-bonus-product-dialog .modal-content {
    padding: 1.875rem 1.5625rem;
    width: 56.25rem;
    border: 0;
}

span.color-value.swatch-circle.swatch-value.selected.selectable::after {
    background: transparent;
    content: "";
    width: 32px;
    height: 32px;
    border: 1px solid #0d0c0c;
    border-radius: 100%;
    position: relative;
    top: -5px;
    left: -5px;
    pointer-events: none;
    box-shadow: inset 0 0 0 0.03125rem #1d1d1d;
}

.bonus-product-details .detail-panel {
    width: 100%;
    display: flex;
    justify-content: center;
}

.bonus-product-details {
    max-width: 50%;
    float: right;

    .bonus-line-item-row {
        .line-item-price-info {
            margin-bottom: 0;
        }
    }
}

.choose-bonus-product-dialog {
    max-width: 56.25rem !important;
}

.bonus-quantity .bonus-quantity-select {
    display: inline-block;
    width: 100%;
    height: calc(1.5em + 0.75rem + 2px);
    padding: 5px 13px;
    font-size: 0.8rem;
    font-weight: 400;
    line-height: 1.5;
    color: #495057;
    vertical-align: middle;
    background-color: #fff;
    border: 1px solid #ced4da;
    border-radius: 0.1875rem;
}

.bonus-product-item .bonus-product-details {
    float: right;
}

.bonus-product-item .js-pdp-open-zoommodal {
    display: inline-block;
    width: 50%;
}

.add-to-bag-bonus {
    text-align: center;
    margin: 16px;
}

.choose-bonus-product-dialog .align-items-end {
    display: flex;
    margin: 24px 0;
}

.add-to-bag-bonus {
    margin: 0 auto;
    @include respond-to(mobile) {
        min-width: rem-calc(118);
        margin: 0 0 0 auto;
    }
}

.g-button_base-bonus {
    border-color: #e93e51;
    background-color: #e93e51;
    color: #fff;
    font-weight: 500;
    font-size: 0.75rem;
    line-height: 1.67;
    position: relative;
    display: inline-flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    max-width: 212px;
    min-height: 2.75rem;
    padding: 0.625rem 0.9375rem;
    border: none;
    border: 1px solid transparent;
    border-radius: 0.25rem;
    text-align: center;
    text-decoration: none;
    cursor: pointer;
}

.choose-bonus-product-dialog .modal-header {
    background: #fff !important;
}

.bonus-product-details {
    width: 100%;
    max-width: 50%;
}

.bonus-product-details .detail-panel {
    display: inline-grid;
}

.bouns-detail-panel .swatch.row.size-variation {
    display: flex;
}

.bonus-product-details .quantity {
    margin: 13px;
    float: right;
}

button.btn.btn-block.btn-outline-primary.select-bonus-product {
    background: #fff;
    color: #000;
    height: 45px;
    border-radius: 4px;
    width: 100%;
    text-align: center;
    line-height: 3;
    justify-content: center;
    border-color: #000;
}

.b-cartlineitem_row.bonus-line-item-row.bfx-price-product .product-card-footer {
    font-weight: 400;
    font-size: 0.75rem !important;
    line-height: 1.6;
    color: #5f5f5f;
    text-transform: capitalize;
}

button.btn.btn-block.btn-outline-primary.select-bonus-product:hover {
    background: #000 !important;
    color: #fff !important;
}

.b-cartlineitem_row.bonus-line-item-row.bfx-price-product .item-attributes.d-flex.flex-column .line-item-attributes {
    font-weight: 400;
    font-size: 0.75rem !important;
    line-height: 1.6;
    color: #5f5f5f;
    text-transform: capitalize;
}

div#bonus-productlineitems-referesh .b-cartlineitem_row.bonus-line-item-row.bfx-price-product .line-item-name {
    font-weight: 500 !important;
    font-size: 0.875rem !important;
    line-height: 1.67;
    margin-bottom: 0.5rem;
    text-transform: capitalize;
    text-decoration: none;
    color: #1d1d1d !important;
    width: 100% !important;
}

.b-cartlineitem_row.bonus-line-item-row.bfx-price-product .product-card-footer .line-item-total-price {
    display: flex !important;
    align-items: baseline;
}

.choose-bonus-product-dialog .modal-body {
    max-height: 28.125em;
    overflow-y: auto;
}

.bouns-detail-panel select#bonus-length-1 {
    display: inline-block;
    height: calc(1.5em + 0.75rem + 2px);
    padding: 5px 13px;
    font-size: 0.8rem;
    font-weight: 400;
    line-height: 1.5;
    color: #495057;
    vertical-align: middle;
    background-color: #fff;
    border: 1px solid #ced4da;
    border-radius: 0.1875rem;
}

select.custom-select.form-control.selectvariation-size {
    display: inline-block;
    height: calc(1.5em + 0.75rem + 2px);
    padding: 5px 13px;
    font-size: 0.8rem;
    font-weight: 400;
    line-height: 1.5;
    color: #495057;
    vertical-align: middle;
    background-color: #fff;
    border: 1px solid #ced4da;
    border-radius: 0.1875rem;
}

.modal-dialog.choose-bonus-product-dialog.pdp-bonus-product-model span.color-value.swatch-circle.swatch-value.selectable {
    width: 2.5em;
    height: 2.5em;
    background: #fff;
    border-radius: 1.25em;
    border: 0.063em solid rgba(0, 0, 0, 0.3);
    display: inline-block;
    margin-right: 0.313em;
    position: relative;
}

.modal-dialog.choose-bonus-product-dialog.pdp-bonus-product-model label.length {
    display: block;
}

.modal-dialog.choose-bonus-product-dialog.pdp-bonus-product-model .bonus-product-details .quantity {
    margin: 0 51px 5px 31px;
    float: right;
}

div#bonusProduct button.btn.btn-outline-primary {
    background-color: transparent;
    border: 1px solid #1d1d1d;
    padding: 0.375rem 0.75rem;
    font-size: 14px;
    line-height: 1.5;
    border-radius: 0.1875rem;
    color: #1d1d1d;
}

div#bonusProduct {
    width: fit-content;
    margin-top: rem-calc(12);
}

div#bonusProduct button.btn.btn-outline-primary:hover {
    background: #1d1d1d;
    color: #fff;
    cursor: pointer;
}

.b-header_minicart-product-item.bfx-minicart-product .mini-cart-bonus-item img.product-image {
    width: 92px;
    height: 103.99px;
}

.card.order-product-summary.b-checkout_product-summary .b-header_minicart-product-item.bfx-minicart-product .checkout-bonus-item img.product-image {
    width: 123px;
    height: 131px;
}

.b-header_minicart-product-item.bfx-minicart-product .product-line-item-details.d-flex.flex-row {
    display: flex;
}

.b-header_minicart-product-item.bfx-minicart-product .item-attributes {
    color: #5f5f5f;
    font-weight: 400;
    font-size: 0.625rem;
    line-height: 1rem;
    margin-left: 16px;
    width: 50%;
}

.b-header_minicart-product-item.bfx-minicart-product .line-item-pricing-info {
    display: flex;
}

.b-header_minicart-product-item.bfx-minicart-product .col-4.line-item-total-price.justify-content-end {
    display: flex;
}

.b-header_minicart-card-body.bfx-price-container .line-item-attributes {
    color: #5f5f5f;
    font-weight: 400;
    font-size: 0.625rem;
    line-height: 1rem;
}

.b-header_minicart-product-item.bfx-minicart-product .line-item-name {
    font-weight: 500;
    font-size: 0.75rem;
    line-height: 1.67;
    text-decoration: none;
    color: #1d1d1d;
    width: 100%;
}

.b-header_minicart-product-item.bfx-minicart-product .price {
    font-size: 9px !important;
}

.b-cartlineitem_row.bonus-line-item-row.bfx-price-product .product-card-footer .col-4.col-lg-3.line-item-quantity {
    display: flex;
    align-items: baseline;
}

.b-cartlineitem_row.bonus-line-item-row.bfx-price-product .product-card-footer span.value {
    font-weight: 400;
    font-size: 0.75rem !important;
    line-height: 1.6;
    color: #5f5f5f;
}

.b-cartlineitem_row.bonus-line-item-row.bfx-price-product .product-card-footer .pricing.line-item-total-price-amount {
    font-weight: 400;
    font-size: 0.75rem !important;
    line-height: 1.6;
    color: #5f5f5f;
}

.b-cartlineitem_row.bonus-line-item-row.bfx-price-product .product-card-footer .col-4.col-lg-3.line-item-quantity .countItemQty {
    font-weight: 400;
    margin: 0 2px;
    line-height: 1.6;
    color: #5f5f5f;
}

.bonus-product-details section.product-name-wrapper h4.product-name {
    font-size: 1rem !important;
    text-align: center;
    font-weight: 700;
    line-height: 2rem;
    position: relative;
    padding-top: 0.5rem;
}

.modal-dialog.choose-bonus-product-dialog.pdp-bonus-product-model {
    display: none !important;
}

.modal.fade.bonusProductModel.bonusProductModelPdp.show {
    background: none !important;
}

.modal-dialog.choose-bonus-product-dialog button.close.pull-right:hover {
    cursor: pointer;
}

@media screen and (max-width: 480px) {
    .bonus-summary.row {
        font-size: 0.75rem !important;
    }

    .bonus-product-item.ready-to-order.choice-of-bonus-product .b-product_carousel-pdp .swiper-slide {
        -ms-flex-negative: 0;
        height: auto !important;
    }

    div#bonusProduct button.btn.btn-outline-primary {
        margin-top: 40px !important;
    }

    .choose-bonus-product-dialog {
        max-width: 100% !important;
        top: 50% !important;
        width: 94%;
        padding: 1rem 1.5rem 1.5625rem !important;
    }

    .choose-bonus-product-dialog {
        background: #fff;
        position: absolute;
        float: left;
        left: 50%;
        top: 50% !important;
        transform: translate(-50%, -50%);
        padding: 1.875rem 1.5625rem;
    }

    .bonus-product-item .js-pdp-open-zoommodal {
        width: 39%;
        display: flex !important;
    }

    .bonus-product-details {
        width: 100% !important;
        max-width: 100% !important;
    }

    .bouns-detail-panel {
        margin-left: 28px;
    }

    .bonus-product-item .js-pdp-open-zoommodal {
        display: inline-block;
        width: 100% !important;
    }

    .choose-bonus-product-dialog .modal-content {
        padding: 0 !important;
        width: 100% !important;
    }

    .bonus-product-item {
        .swiper-slide.swiper-slide-active {
            position: relative;
            padding-bottom: 78%;
            height: 0;
            overflow: hidden;
        }

        .swiper-slide.swiper-slide-active img {
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
            object-fit: contain;
        }

        .swiper-slide.swiper-slide-active {
            background: #f0f0f0 !important;
        }
    }

    .bouns-detail-panel .bonus-quantity {
        margin-left: 5px !important;
    }

    .bouns-detail-panel .swatch.row.color-variation {
        margin-left: auto;
    }

    .bonus-product-details .detail-panel {
        justify-content: flex-start;
        display: block;
    }

    .bonus-product-details .swatch.row {
        float: inherit;
    }

    .bonus-product-details .quantity {
        margin: 13px !important;
        float: right;
    }

    .bouns-detail-panel .swatch.row.size-variation {
        display: flex !important;
        margin: auto !important;
    }

    .bonus-product-details .product-name-wrapper {
        padding: 0 !important;
        line-height: 1;
        text-align: inherit !important;
    }

    .bonus-product-details section.product-name-wrapper h4.product-name {
        font-size: 1rem !important;
    }

    .modal-dialog.choose-bonus-product-dialog button.close.pull-right {
        margin-right: 5px !important;
    }

    .bouns-detail-panel select#bonus-length-1 {
        display: inline-block;
        width: 90%;
        height: calc(1.5em + 0.75rem + 2px);
        padding: 5px 13px;
        font-size: 0.8rem;
        font-weight: 400;
        line-height: 1.5;
        color: #495057;
        vertical-align: middle;
        background-color: #fff;
        border: 1px solid #ced4da;
        border-radius: 0.1875rem;
    }

    .bouns-detail-panel .bonus-quantity-select {
        display: inline-block;
        width: 90%;
        height: calc(1.5em + 0.75rem + 2px);
        padding: 5px 13px;
        font-size: 0.8rem;
        font-weight: 400;
        line-height: 1.5;
        color: #495057;
        vertical-align: middle;
        background-color: #fff;
        border: 1px solid #ced4da;
        border-radius: 0.1875rem;
    }

    .bonus-select-variations select.custom-select.form-control.selectvariation-size {
        display: inline-block;
        height: calc(1.5em + 0.75rem + 2px);
        padding: 5px 13px;
        font-size: 0.8rem;
        font-weight: 400;
        line-height: 1.5;
        color: #495057;
        vertical-align: middle;
        background-color: #fff;
        border: 1px solid #ced4da;
        border-radius: 0.1875rem;
    }

    .modal-dialog.choose-bonus-product-dialog.pdp-bonus-product-model .bonus-product-details .quantity {
        margin: 0 !important;
    }

    .modal-dialog.choose-bonus-product-dialog.pdp-bonus-product-model label.size {
        display: block;
    }

    .modal-dialog.choose-bonus-product-dialog.pdp-bonus-product-model .swatch.row.size-variation {
        margin-top: 13px;
    }

    .modal-dialog.choose-bonus-product-dialog.pdp-bonus-product-model .bonus-quantity {
        margin-left: 13px !important;
    }

    .modal-dialog.choose-bonus-product-dialog.pdp-bonus-product-model .quantity {
        width: 55%;
    }

    button.btn.btn-block.btn-outline-primary.select-bonus-product {
        color: #000;
        height: 45px;
        border-radius: 4px;
        width: 90%;
        text-align: center;
        line-height: 3;
        -ms-flex-pack: center;
        justify-content: center;
        margin: 9px 22px;
        margin-left: 0;
        float: revert;
    }

    .modal-dialog.choose-bonus-product-dialog.pdp-bonus-product-model button.btn.btn-block.btn-outline-primary.select-bonus-product {
        color: #000;
        height: 45px;
        border-radius: 4px;
        width: 91% !important;
        text-align: center;
        line-height: 3;
        -ms-flex-pack: center;
        justify-content: center;
        margin: 9px 0 14px -23px !important;
        margin-left: 0;
        float: revert;
    }

    .b-cartlineitem_togetherbutton {
        float: right !important;
        padding: 0;
    }
}

@media only screen and (min-device-width: 768px) and (max-device-width: 1024px) {
    .choose-bonus-product-dialog {
        top: 50% !important;
    }

    .b-cartlineitem_togetherbutton {
        float: right !important;
    }

    div#bonusProduct button.btn.btn-outline-primary {
        margin-top: 40px !important;
    }

    .bonus-product-item .js-pdp-open-zoommodal {
        display: inline-block;
        width: 100% !important;
    }

    .b-product_carousel-slide.js-product_carousel-slide.swiper-slide.swiper-slide-active:not(.shop-this-outfit-models .b-product_carousel-slide) {
        width: 100% !important;
    }

    .bonus-product-details {
        width: 100%;
        max-width: 100% !important;
    }

    .bonus-product-details .product-name-wrapper {
        padding: 0 !important;
        text-align: inherit !important;
    }

    .bonus-product-details section.product-name-wrapper h4.product-name {
        font-size: 1rem !important;
        text-align: center;
        line-height: 1.5;
    }

    .selected-bonus-products {
        margin-left: 50px !important;
        font-size: 1.3rem !important;
    }

    .add-to-bag-bonus {
        margin-top: -13px !important;
    }

    .modal-dialog.choose-bonus-product-dialog button.close.pull-right {
        float: right;
        background: #fff !important;
        border: none;
        margin-right: 70px !important;
    }

    .modal-dialog.choose-bonus-product-dialog.pdp-bonus-product-model .bonus-product-details .quantity {
        margin: 0 51px 15px 31px !important;
        float: right;
        margin-left: 76px !important;
    }

    .modal-dialog.choose-bonus-product-dialog.pdp-bonus-product-model label.size {
        display: block;
    }

    button.btn.btn-block.btn-outline-primary.select-bonus-product {
        color: #000;
        height: 45px;
        border-radius: 4px;
        width: 100% !important;
        text-align: center;
        line-height: 3;
        -ms-flex-pack: center;
        justify-content: center;
        margin: 0 !important;
        margin-bottom: 44px !important;
        float: revert !important;
    }

    .modal-dialog.choose-bonus-product-dialog.pdp-bonus-product-model button.btn.btn-block.btn-outline-primary.select-bonus-product {
        color: #000;
        height: 45px;
        border-radius: 4px;
        width: 56% !important;
        text-align: center;
        line-height: 3;
        -ms-flex-pack: center;
        justify-content: center;
        margin: 0 73px 30px !important;
        margin-right: 173px !important;
        float: revert !important;
    }

    .bouns-detail-panel select.custom-select.form-control.selectvariation-length {
        display: inline-block;
        height: calc(1.5em + 0.75rem + 2px);
        padding: 5px 13px;
        font-size: 0.8rem;
        font-weight: 400;
        line-height: 1.5;
        color: #495057;
        vertical-align: middle;
        background-color: #fff;
        border: 1px solid #ced4da;
        border-radius: 0.1875rem;
        width: 100%;
    }

    .bonus-product-details .swatch.row {
        float: none !important;
    }

    .bouns-detail-panel .swatch.row.color-variation {
        margin: 0 !important;
    }

    .bouns-detail-panel .swatch.row.size-variation {
        margin: 0 !important;
        display: flex;
    }
}

@media only screen and (min-device-width: 1024px) {
    .b-cartlineitem.card-product-info.card.bonus-product-line-item .b-cartlineitem_remove_edit {
        width: auto !important;
        flex: 1 1 100%;
    }

    .b-cart-content .bonus-product-cart-main {
        width: 100%;
    }

    .b-cart-content .b-cartlineitem_row {
        width: inherit;
    }

    .b-cart-content .b-cartlineitem_image {
        width: inherit;
    }
}

.bonus-product-cart-main .bonus-product-details {
    max-width: 100%;
}

.bonus-product-details .bonus-product-row {
    display: flex;
}

.bonus-product-cart-main hr.line-item-divider {
    display: none;
}

.bonus-product-cart-main .bundled-line-item.clearfix {
    max-width: 100%;
    vertical-align: top;
    margin-left: 1rem;
    width: calc(100% - 124px);
    display: inline-block;
    float: right;
}

.bonus-product-name {
    margin-top: 8px;
}

.selected-bonus-product-container {
    display: flex;

    .selected-pid {
        display: flex;
        align-items: center;
        margin-right: 8px;
        @include respond-to(mobile) {
            min-width: rem-calc(15);
        }
    }
}

.bonus-error-text {
    z-index: 2000;
    @include respond-to(mobile) {
        width: fit-content;
        background: $grey2;
    }
}

.alert {
    font-size: rem-calc(12);
    line-height: 1.67;
    background: $grey2;
    border-radius: rem-calc(4);
    color: $red;
}

.add-to-cart-messages {
    width: fit-content;
    display: inline-block;
    z-index: 2000;
    top: 50% !important;
}
