/* md

# Lists

```html
    <ul class="t-unordered-list">
        <li>Item 1</li>
        <li>Item 2
            <ul>
                <li>Item 2.1</li>
            </ul>
        </li>
        <li>Item 3
            <ul>
                <li>Item 3.1</li>
            </ul>
        </li>
    </ul>
    <ol class="t-ordered-list">
        <li>Item 1</li>
        <li>Item 2
            <ol>
                <li>Item 2.1</li>
            </ol>
        </li>
        <li>Item 3
            <ol>
                <li>Item 3.1</li>
            </ol>
        </li>
    </ol>
    <ul class="t-unordered-list t-twocol-list">
        <li>Item 1</li>
        <li>Item 2</li>
        <li>Item 3</li>
        <li>Item 4</li>
    </ul>
```

# Unordered list

```html_example
    <ul class="t-unordered-list">
        <li>Item 1</li>
        <li>Item 2
            <ul>
                <li>Item 2.1</li>
            </ul>
        </li>
        <li>Item 3
            <ul>
                <li>Item 3.1</li>
            </ul>
        </li>
    </ul>
```

# Ordered list

```html_example
    <ol class="t-ordered-list">
        <li>Item 1</li>
        <li>Item 2
            <ol>
                <li>Item 2.1</li>
            </ol>
        </li>
        <li>Item 3
            <ol>
                <li>Item 3.1</li>
            </ol>
        </li>
    </ol>
```
# Unordered two columns list

```html_example
    <ul class="t-unordered-list t-twocol-list">
        <li>Item 1</li>
        <li>Item 2</li>
        <li>Item 3</li>
        <li>Item 4</li>
    </ul>
```

*/

%t-unordered-list,
.t-unordered-list {
    @include t-unordered-list;
}

%t-ordered-list,
.t-ordered-list {
    @include t-ordered-list;
}

%t-twocol-list,
.t-twocol-list {
    @include t-twocol-list;
}
