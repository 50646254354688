.b-product_attribute {
    &-title {
        font-size: rem-calc(14);
        display: flex;
        padding: 0 0 rem-calc(8) 0;
        white-space: nowrap;
    }

    .b-swatches_circle,
    .b-swatches_text {
        flex-wrap: wrap;
    }

    .b-product_sizeChart {
        display: inline;
        position: absolute;
        margin: 0 5px 0 0;
        padding: 0;
        border: 0;
        left: 0;
        top: -4px;
        z-index: 1;

        a {
            color: $grey5;
            @include font(main_med, rem-calc(14), 1.67);
            @include respond-to(mobile) {
                text-decoration: underline;
            }
        }
    }

    #size-selected {
        display: none;
        padding-top: 20px;
    }

    #size-not-selected {
        padding-top: 20px;
    }

    .b-product_link {
        display: inline;
        margin: 10px 0 0;
        border: 0;
        left: 0;

        a {
            color: $grey5;
            @include font(main_med, rem-calc(14), 1.67);
            @include respond-to(mobile) {
                text-decoration: underline;
            }
        }
    }

    .b-swatches_circle-link {
        width: rem-calc(30);
        height: rem-calc(30);

        &::before {
            width: rem-calc(20);
            height: rem-calc(20);
        }

        &.m-disabled {
            &::after {
                max-width: rem-calc(28);
                width: rem-calc(28);
            }
        }
    }
}
