/* md

# Titles

```html
    <div class="t-title-1">Title 1</div>
    <div class="t-title-2">Title 2</div>
    <div class="t-title-3">Title 3</div>
    <div class="t-title-4">Title 4</div>
    <div class="t-title-5">Title 5</div>
    <div class="t-title-6">Title 6</div>
```

Here is all titles you can use inside your content assets.

Additionaly you can add ```t-center``` class to center align title's text

```html_example
    <div style="padding: 15px 0; display: flex; justify-content: space-around">
        <div class="t-title-1">Title 1</div>
    </div>

    <div style="padding: 15px 0; display: flex; justify-content: space-around">
        <div class="t-title-2">Title 2</div>
    </div>

    <div style="padding: 15px 0; display: flex; justify-content: space-around">
        <div class="t-title-3">Title 3</div>
    </div>

    <div style="padding: 15px 0; display: flex; justify-content: space-around">
        <div class="t-title-4">Title 4</div>
    </div>

    <div style="padding: 15px 0; display: flex; justify-content: space-around">
        <div class="t-title-5">Title 5</div>
    </div>

    <div style="padding: 15px 0; display: flex; justify-content: space-around">
        <div class="t-title-6">Title 6</div>
    </div>
```
*/

.t-title-1 {
    @include t-title-1;
}

.t-title-2 {
    @include t-title-2;
}

.t-title-3 {
    @include t-title-3;
}

.t-title-4 {
    @include t-title-4;
}

.t-title-5 {
    @include t-title-5;
}

.t-title-6 {
    @include t-title-6;
}

.t-title-1,
.t-title-2,
.t-title-3,
.t-title-4,
.t-title-5,
.t-title-6 {
    &.t-center {
        text-align: center;
    }
}
