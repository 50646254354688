.b-footer_logo {
    margin-bottom: rem-calc(15);
    max-width: rem-calc(130);

    img {
        margin-left: rem-calc(4);
    }

    @include respond-to(desktop-large) {
        @include grid-col-offset(1);
    }
}
