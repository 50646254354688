.g-welcome-mat-modal-container {
    &.geolocation-mat-modal {
        li {
            &.flag {
                .second-flag {
                    float: left;
                }

                span {
                    display: block;
                    color: $black;
                    margin-top: rem-calc(10);
                }
            }
        }
    }
}

label {
    &.form-control-label {
        &.postalCodeNotMandatory {
            &::after {
                display: none;
            }
        }
    }
}

.addressHelpText {
    font-size: rem-calc(12);
    margin: rem-calc(4) rem-calc(4) 0 rem-calc(4);
}

.b-contact_row {
    .validatePhoneField {
        ::-webkit-input-placeholder {
            color: transparent;
        }

        ::-moz-placeholder {
            color: transparent;
        }

        :-ms-input-placeholder {
            color: transparent;
        }

        input::placeholder {
            color: transparent;
        }

        &:hover {
            ::-webkit-input-placeholder {
                color: transparent;
            }

            ::-moz-placeholder {
                color: transparent;
            }

            :-ms-input-placeholder {
                color: transparent;
            }

            input::placeholder {
                color: transparent;
            }
        }
    }
}

.shippingAddressOne {
    &.adjustAddressOneField {
        @include respond-to(desktop) {
            width: 49%;
        }
    }
}

.register-login,
.b-registerlink-login {
    .b-header_account-link {
        overflow: initial;
    }
}

.b-country,
.b-state {
    &.adjustArrow {
        &::before {
            right: rem-calc(16);
        }

        .b-state-select,
        .b-country-select {
            padding: 0 1rem;
        }
    }
}

.b-shipping {
    &-summary_shipping {
        &.shipping {
            padding-left: rem-calc(10);
        }
    }

    .shippingPage {
        .manual-input-init {
            .address-picklist-container {
                top: 50%;
            }
        }
    }
}

.summary-details .address-summary {
    padding-left: rem-calc(10);
}

.contact-info-block {
    .b-contact_column {
        .dwfrm_billing_contactInfoFields_email {
            .email {
                padding-right: rem-calc(30);
            }
        }
    }
}

.b-payement-tab-emea {
    background-color: $grey1;
    padding: 1rem 2rem;
    margin-bottom: rem-calc(22);

    .applepay-tab-wrapper {
        .b-payment-accordion-head {
            &.g-accordion-header {
                &.collapsed {
                    @include respond-to(mobile) {
                        display: none;

                        &::after {
                            display: none;
                        }
                    }
                }
            }
        }
    }

    .tab-pane {
        &#applepay-content {
            &.mac-only {
                @include respond-to(mobile) {
                    border-bottom: 1px solid $grey3;
                }

                .b-payment-accordion-head {
                    &.g-accordion-header {
                        @include respond-to(mobile) {
                            display: block;
                        }
                    }
                }
            }
        }
    }

    .paymentMethod {
        display: inline-block;
        width: 100%;
        margin: rem-calc(16) 0 rem-calc(16) 0;

        .additionalFields {
            margin: 0;
        }

        input[type='radio'] {
            margin-right: rem-calc(10);
        }

        .paymentMethod_img {
            display: none;
        }

        &.klarna,
        &.klarna_account,
        &.directEbanking {
            .pay-image-wrapper {
                background: url('../images/cards/klarna.svg') no-repeat;
                width: rem-calc(46);
                height: rem-calc(11);
            }
        }

        &.dotpay {
            .pay-image-wrapper {
                background: url('../images/cards/dotpay.png') no-repeat;
                width: rem-calc(77);
                height: rem-calc(27);
                margin-bottom: rem-calc(2);
            }
        }

        &.bcmc {
            .pay-image-wrapper {
                background: url('../images/cards/bancontact.svg') no-repeat;
                width: rem-calc(46);
                height: rem-calc(33);
            }
        }

        &.giropay {
            .pay-image-wrapper {
                background: url('../images/cards/giropay.svg') no-repeat;
                width: rem-calc(43);
                height: rem-calc(21);
            }
        }

        &.paypal {
            .pay-image-wrapper {
                background: url('../images/cards/payPal.svg') no-repeat;
                width: rem-calc(53);
                height: rem-calc(15);
            }

            .additionalFields {
                margin-top: rem-calc(15);
            }
        }

        &.twint {
            .pay-image-wrapper {
                background: url('../images/cards/twint.svg') no-repeat;
                width: rem-calc(54);
                height: rem-calc(18);
            }
        }

        &.mbway {
            .pay-image-wrapper {
                background: url('../images/cards/mbway.svg') no-repeat;
                width: rem-calc(43);
                height: rem-calc(21);
            }
        }

        &.mobilepay {
            .pay-image-wrapper {
                background: url('../images/cards/mobilepay.svg') no-repeat;
                width: rem-calc(55);
                height: rem-calc(18);
            }
        }

        &.eps {
            .pay-image-wrapper {
                background: url('../images/cards/eps.svg') no-repeat;
                width: rem-calc(40);
                height: rem-calc(25);
            }
        }

        &.ratepay {
            .pay-image-wrapper {
                background: none;
                width: rem-calc(60);
                height: rem-calc(23);
            }
        }

        &.blik {
            .pay-image-wrapper {
                background: url('../images/cards/blik.svg') no-repeat;
                width: rem-calc(31);
                height: rem-calc(15);
            }
        }

        &.onlineBanking_PL {
            .pay-image-wrapper {
                background: url('../images/cards/onlineBanking_PL.svg') no-repeat;
                width: rem-calc(53);
                height: rem-calc(18);
            }
        }

        .pay-image-wrapper {
            float: right;
            display: inline-block;
        }

        &.walley {
            .pay-image-wrapper {
                background: url('../images/cards/walley.svg') no-repeat;
                width: rem-calc(49);
                height: rem-calc(18);
            }
        }

        &.trustly {
            .pay-image-wrapper {
                background: url('../images/cards/trustly.svg') no-repeat;
                width: rem-calc(49);
                height: rem-calc(18);
            }
        }

        &.facilypay_3x,
        &.facilypay_4x,
        &.facilypay_6x,
        &.facilypay_10x,
        &.facilypay_12x {
            .pay-image-wrapper {
                background: url('../images/cards/oney.svg') no-repeat;
                width: rem-calc(53);
                height: rem-calc(15);
            }
        }
    }
}

.adyen-checkout {
    &__card__form {
        width: 80%;
        padding: rem-calc(15) 0;
        @include respond-to(mobile) {
            width: 100%;
        }
    }
}

.vat-included {
    margin-top: rem-calc(15);
}

.b-account-address_book-section,
.b-account-payment_book-section {
    .remove-payment {
        &.adjust-space {
            margin-left: 0;
            position: relative;
            z-index: 1;
        }
    }
}

.vat-tooltip-content {
    position: relative;
    padding: 0 0.625rem 0 0.25rem;

    @include respond-to(mobile) {
        position: static;
    }

    .g-tooltip-icon {
        @include icon(question);

        cursor: pointer;
        align-items: center;

        &::before {
            font-size: 0.8rem;
        }

        @include respond-to(mobile) {
            &:hover {
                &::after {
                    content: '';
                    position: absolute;
                    bottom: -6px;
                    left: 47%;
                    margin-left: rem-calc(-5);
                    border-color: transparent transparent $black transparent;
                    border-style: solid;
                    border-width: rem-calc(8);
                }
            }
        }
    }

    .g-tooltip-text {
        z-index: 2;
        @include respond-to(no-phones) {
            width: rem-calc(370);
            top: calc(100% + #{rem-calc(8)});
            left: rem-calc(-93);

            &::after {
                left: 77%;
            }
        }

        @include respond-to(phones) {
            width: 100%;
            left: 0;
            transform: none;
            top: calc(100% + #{rem-calc(6)});

            &::after {
                display: none;
            }

            .g-tooltip {
                position: static;
            }
        }
    }
}

@include respond-to(phones) {
    .order-summary {
        position: relative;
    }
}

.order-summary_items {
    &.sales-tax-item {
        @include respond-to(mobile) {
            position: relative;
        }
    }
}

.topSpacing {
    margin-top: rem-calc(20);
}

.pick-up-point-content {
    .form-group {
        &.required {
            .b-input_row-label {
                &::before {
                    display: none;
                }

                &::after {
                    content: '*';
                }
            }
        }
    }
}

.ua-returns-container {
    .backbtn {
        display: none;
    }
}

.return-label-print {
    .return-label-imgEMEA {
        &:not(.mobile-barcode) {
            transform: rotate(90deg);
            max-width: 100%;
            margin-top: 22%;
        }

        vertical-align: middle;
        display: inline-block;
    }

    &.l-body {
        .return-label-imgEMEA {
            margin-bottom: 2%;

            &.mobile-barcode {
                margin: 0;
            }
        }

        .return-label--header.header-emea.mobile-barcode {
            .printless-ups-instructions.email-print {
                display: flex;
                flex-direction: column;
                justify-content: center;
                align-items: center;
                padding-top: 50px;

                span {
                    font-size: 30px;
                }

                span.dropoff-location {
                    margin-bottom: -1%;
                }

                .return-details,
                .barcode-ready {
                    display: none;
                }
            }
        }

        .return-label--slip-emea {
            .barcode-download-button {
                min-width: 400px;
                max-width: fit-content;
                font-size: x-large;
                border: black;
                border-width: 1px;
                border-style: solid;
            }
        }
    }

    &:not(.l-body) > .return-label--header.header-emea.mobile-barcode {
        padding: 0;

        > .printless-ups-instructions {
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: center;
            padding: 0 10%;
        }

        span.barcode-ready {
            @include font(main_bold, rem-calc(17), 1.8);

            letter-spacing: 0.6px;
        }

        span.return-details {
            padding-top: 15px;
            text-align: center;

            span.email {
                @include font(main_bold, rem-calc(14), 2);
            }
        }

        span.dropoff-location {
            padding-top: 15px;
            margin-bottom: -5%;
            z-index: 2;
        }
    }

    .return-label--slip-emea {
        .barcode-download-button {
            max-width: 45%;
            border: black;
            border-width: 1px;
            border-style: solid;
        }
    }
}

@media print {
    .return-label-print {
        &.l-body {
            .return-label-imgEMEA {
                margin-bottom: 0;
            }

            .barcode-download-button {
                display: none;
            }
        }
    }

    .vat-tooltip-content {
        position: static;
    }
}

.return-label--section {
    position: relative;
    z-index: 1;
}

.b-checkout_main[data-customer-type=registered] {
    .js-paazlwidget-form {
        .ship-to-address-wrap-content .b-shipping-method {
            border-top: none;
        }
    }
}

.b-gift-message-header {
    margin-right: rem-calc(8);
}

.pazzl-no-response {
    color: $red;
    padding: rem-calc(10) 0;
}

.b-order-details-returns-new {
    .order-return-print-main {
        .b-print-label-rightsec {
            .b-label-print-wrap {
                .order-return-email {
                    button {
                        display: none;
                    }
                }
            }
        }
    }
}

.order-product-summary {
    .card-body {
        .checkout-privacy-links {
            .b-promo-tooltip-content {
                .g-tooltip-text {
                    left: calc(100% - 100px);
                }
            }
        }
    }
}

.account-creation-container {
    .buttons-and-text {
        .hidden-xs {
            max-width: rem-calc(420);
        }
    }
}

.locale-selector-mobile {
    display: none;
}

@include respond-to(mobile) {
    .locale-selector-mobile {
        display: block;
        padding-bottom: 14px;
    }

    .summary-details .address-summary,
    .b-shipping-summary_shipping.shipping {
        padding-left: 0;
    }

    .locale-subitem {
        a,
        button {
            text-decoration: none;
            font-size: 0.875rem;
            line-height: 1rem;
            padding: 1.125rem 1rem;
            color: $black;
            width: 100%;
        }
    }
}

@include respond-to(phones) {
    .account-creation-container {
        .account-advantages {
            .register-cart-text {
                &.description {
                    width: auto;
                }
            }
        }
    }
}

@include respond-to(desktop) {
    .b-checkout_product-summary {
        .product-summary-block {
            .b-header_minicart-item-details {
                .b-header_minicart-item-image {
                    width: rem-calc(123);
                    height: rem-calc(137);
                }
            }
        }
    }

    .b-navigation-link {
        display: inline-block;
        padding: rem-calc(20) rem-calc(16) rem-calc(15) !important;
        color: $white;
    }

    .b-navigation-item {
        flex-grow: 1;
    }
}

.b-tile-quickView-cta {
    [data-action="Product-Show"] & {
        display: block !important;
        pointer-events: inherit !important;
    }
}

#quickViewModal {
    .attributes {
        @include respond-to(no-phones) {
            padding-left: 0 !important;
            padding-right: 0 !important;
        }
    }
}

.b-home-page {
    &-recommendations {
        @include respond-to(no-phones) {
            margin: 0 auto 32px;
            max-width: 1440px;
            position: relative;

            .g-carousel {
                padding: 0.223% 9.862% 2.778%;
            }

            .g-carousel-heading {
                padding-bottom: 0 !important;
            }
        }

        @include respond-to(phones) {
            .g-carousel {
                padding: 0 0 0 10px;
            }

            .g-carousel-control {
                display: none !important;
            }
        }

        .g-carousel-title {
            padding: 40px 10% 0;
            position: relative;
        }
    }
}

.return-label--header {
    &.mobile-barcode {
        padding-bottom: 0;
    }
}
