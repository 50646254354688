.b-checkout-store-pickup {
    &_header {
        padding-bottom: rem-calc(16);
        margin-bottom: rem-calc(24);
        border-bottom: 1px solid $black;
        @include respond-to(mobile) {
            padding-bottom: rem-calc(8);
        }

        .store-pickup-header-text {
            @include font(main_bold, rem-calc(24), rem-calc(32));
            @include respond-to(mobile) {
                @include font(main_bold, rem-calc(16), rem-calc(24));
            }
        }
    }

    &_content {
        margin-bottom: rem-calc(40);

        .b-store-pickup-details {
            display: flex;

            @include respond-to(mobile) {
                display: block;
            }

            .b-store-selected-content {
                display: flex;
                flex-basis: 55%;
                @include respond-to(mobile) {
                    flex-basis: 100%;
                }
            }

            img {
                position: relative;
                bottom: rem-calc(4);
                width: rem-calc(33);
                height: rem-calc(33);
                @include respond-to(mobile) {
                    bottom: rem-calc(6);
                }
            }

            .b-store-pickup-selected {
                margin-left: rem-calc(12);
                font-size: rem-calc(14);
                line-height: rem-calc(20);
                color: $grey5;

                .selected-store-details {
                    .b-store-pickup-status {
                        color: $green;
                        font-weight: 600;
                    }

                    .b-store-pickup-name {
                        font-weight: 600;
                        color: $black;
                    }
                }
            }

            .b-store-address-details {
                color: $grey5;
                @include respond-to(mobile) {
                    margin: rem-calc(8) 0 0 rem-calc(45);
                }

                .b-store-address-name {
                    color: $black;
                    font-weight: $font-semi-bold;

                    .b-promo-tooltip-content {
                        margin-left: rem-calc(10);
                        width: rem-calc(16);

                        @include respond-to(desktop) {
                            top: rem-calc(3);
                        }

                        &:hover {
                            .g-tooltip-text,
                            .g-tooltip-icon .g-tooltip-arrow {
                                visibility: visible;
                                opacity: 1;
                            }
                        }

                        .g-tooltip-icon {
                            @include icon(alert);

                            @include respond-to(desktop) {
                                line-height: initial;
                                vertical-align: initial;
                            }

                            &::before {
                                content: '';
                                width: rem-calc(16);
                                height: rem-calc(16);
                                background-image: url(../../images/icon-black-help.svg);
                                display: block;

                                @include respond-to(desktop) {
                                    display: inline-block;
                                }
                            }
                        }

                        .g-tooltip-arrow {
                            bottom: rem-calc(8);

                            @include respond-to(mobile) {
                                bottom: 0;
                                width: rem-calc(16);
                                height: rem-calc(16);
                            }
                        }

                        .g-tooltip-text {
                            width: rem-calc(212);
                            top: 100%;

                            &::after {
                                @include respond-to(desktop) {
                                    display: none;
                                }
                            }

                            .store-days {
                                width: 35%;
                                display: inline-flex;
                            }

                            @include respond-to(mobile) {
                                min-width: rem-calc(212);
                                left: calc(100% - 24px);
                                top: calc(100% + 7px);
                            }

                            .store-hrs-details {
                                display: flex;
                            }

                            .store-hrs-days {
                                flex-basis: 40%;
                            }
                        }
                    }
                }

                .store-map {
                    color: inherit;
                    text-decoration: none;

                    .b-store-address {
                        display: block;
                        @include respond-to(mobile) {
                            text-decoration: underline;
                        }
                    }
                }
            }
        }
    }
}

.b-checkout_right_summary_container,
.b-order-confirmation_right {
    .product-summary-block {
        .b-store-pickup-heading,
        .b-shipping-summary-heading {
            font-weight: 600;
            margin-bottom: rem-calc(16);
        }
    }
}

.shipping-section {
    .card-header-custom {
        display: inline-block;
    }

    .free-shipping-msg {
        float: right;

        img {
            width: rem-calc(40);
            @include respond-to(mobile) {
                width: rem-calc(32);
            }
        }

        span {
            font-size: rem-calc(14);
            margin-left: rem-calc(10);
            color: $green;
            position: relative;
            bottom: rem-calc(11);
            @include respond-to(mobile) {
                font-size: rem-calc(12);
            }
        }
    }
}
