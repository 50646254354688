#browser-check {
    .browser-compatibility-alert {
        width: 100%;
        display: flex;
        align-items: center;
        justify-content: center;
        padding: rem-calc(10);
        background: $red;
        color: $white;
    }

    .browser-error {
        margin-left: rem-calc(10);
        text-align: center;
    }
}
