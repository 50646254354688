.b-password-reset {
    &-container {
        @include push-to-center(1200px);

        display: flex;
        justify-content: center;
        align-items: center;
        padding: 0 rem-calc(16);
        margin: {
            top: rem-calc(57);
            bottom: rem-calc(64);
        }
    }

    &-content {
        max-width: rem-calc(448);
        flex: 1 1 65%;
    }

    &-form {
        button {
            width: 100%;
            max-width: none;
        }
    }

    &-header {
        h4 {
            @include font(main_bold, rem-calc(24), 1.25);

            letter-spacing: 1px;
            margin-bottom: rem-calc(12);
            text-transform: capitalize;
        }

        span {
            @include font(main, rem-calc(14), 1.43);

            margin-bottom: rem-calc(29);
            display: inline-block;

            @include respond-to(mobile) {
                @include font(main, rem-calc(12), 1.67);
            }
        }
    }

    .password-display {
        position: absolute;
        top: 0;
        right: 0;
        padding: 0.75rem 0.625rem;
        z-index: 99;
        text-align: center;
        cursor: pointer;
    }
}

.g-passwordreset-success {
    .b-password-success-content {
        padding: 0 rem-calc(40);
        text-align: center;

        h4 {
            font-size: rem-calc(24);
            font-weight: $font-bold;
            line-height: 1.2;
            padding-top: 1rem;
        }

        .b-check-icon {
            width: rem-calc(48);
            height: rem-calc(48);
        }
    }

    .b-password-success-button {
        .g-button_base {
            max-width: none;
            width: 100%;
            margin-top: rem-calc(24);
        }
    }

    .g-login-modal-header {
        padding-bottom: 0.8rem;
    }
}
