.b-account-order_history .account-order-details-container {
    .card-body {
        .order-details-container {
            .b-card-track-status {
                .b-selectitems-returns {
                    .order-items {
                        .order-item {
                            .b-order-col-left {
                                .b-order-col-right {
                                    .b-oitem-buyagain {
                                        .order-item-buy-again {
                                            a {
                                                display: inline-block;
                                            }
                                        }
                                    }
                                }

                                .order-item-info {
                                    .b-oitem-details {
                                        .b-item-sec {
                                            .write-review-link {
                                                width: unset;
                                                margin-left: rem-calc(12);
                                            }
                                        }
                                    }
                                }
                            }
                        }
                    }
                }
            }
        }
    }
}

.b-createaccount {
    .b-account_form-row-three {
        .form-group {
            select.order-return-form-input {
                @media screen and (min-width: 1024px) {
                    padding-left: 10%;
                }
                @media screen and (max-width: 767px) {
                    padding-left: 5%;
                }
            }
        }
    }
}
