@mixin input {
    @include t-text-3;
    @include font(main_med);

    width: 100%;
    height: rem-calc(44);
    padding: 0 rem-calc(16);
    border: 1px solid $grey3;
    border-radius: rem-calc(4);
    background-color: transparent;
    line-height: rem-calc(44);

    &::placeholder {
        color: $placeholders-color;
    }

    &[type='search'],
    &[type='search']::-webkit-search-decoration,
    &[type='search']::-webkit-search-cancel-button,
    &[type='search']::-webkit-search-results-button,
    &[type='search']::-webkit-search-results-decoration {
        -webkit-appearance: none;
    }

    &[type='number'] {
        -moz-appearance: textfield;

        &::-webkit-outer-spin-button,
        &::-webkit-inner-spin-button {
            -webkit-appearance: none;
            margin: 0;
        }
    }

    &:hover,
    &:focus {
        border-color: $primary-color;
        outline: none;
        box-shadow: none;
    }

    &:hover {
        &::placeholder {
            color: $placeholders-color;
        }
    }

    &.is-invalid,
    &.m-invalid,
    &.m-error {
        border-color: $error-color;

        &:hover,
        &:focus {
            border-color: $error-color;
        }
    }

    &:-webkit-autofill { //change auto-filled background in Chrome
        -webkit-box-shadow: 0 0 0 1000px $white inset;
    }
}

@mixin label-for-input($type: null) {
    @include t-text-4;
    @include font(main_med);

    position: absolute;
    top: 0;
    left: rem-calc(12);
    padding: rem-calc(2) rem-calc(4);
    background: $white;
    transform: translateY(-50%);

    @if ($type == 'required') {
        &::after {
            content: '*';
            padding-left: rem-calc(2);
        }
    }
}

.error-field {
    .form-control-label {
        color: $error-color;
    }

    input {
        border-color: $error-color;
    }
}

.sf-page__silho {
    .js-recommendation-wrapper {
        .js-swiper-wrapper {
            a {
                padding: 0 !important;
                text-decoration: none;
            }
        }
    }
}

.fitguide-container {

    @include respond-to(desktop) {
        .g-carousel-slide {
            width: 330px;
        }
    }
}

.a_stylesize {
    text-decoration: none;
}
