.l-header {
    z-index: 101;
    background-color: $black;

    &-divider {
        @include respond-to(mobile) {
            position: relative;
            z-index: 102;
        }

        height: 1px;
        border: 0;
        background-color: $white;
    }

    &-divider-emea {
        @include respond-to(mobile) {
            position: relative;
        }

        height: 1px;
        border: 0;
        background-color: $white;
    }

    &-section_top,
    &-section_bottom {
        @include grid-container;
    }

    &-section_top {
        height: rem-calc(40);
    }

    &-section_bottom {
        @include respond-to(mobile) {
            position: relative;
            height: rem-calc(56);
        }

        &.m-sticky {
            background: $black;
        }
    }

    &-inner {
        display: flex;
        height: 100%;

        @include respond-to(desktop) {
            --navbar-gap: 16px;

            padding: 0 16px;
            gap: var(--navbar-gap);
        }
    }

    &-utility {
        justify-content: space-between;

        @include respond-to(tablets) {
            @include grid-col(1, 8);
        }

        @include respond-to(phones) {
            flex: 1 1 33.33%;
            max-width: 33.33%;
        }

        @include respond-to(desktop) {
            justify-content: flex-end;
            // min-width: 185px;
            min-width: 221px;
        }
    }

    &-toggle {
        display: flex;
        align-items: center;

        @include respond-to(tablets) {
            @include grid-col(1, 8);
        }

        @include respond-to(phones) {
            flex: 1 1 33.33%;
            max-width: 33.33%;
        }

        @include respond-to(desktop) {
            display: none;

            &.l-header-utility {
                display: none !important;
            }
        }
    }

    &-logo {
        flex: 1;
        min-width: 42px;

        @include respond-to(mobile) {
            flex: 1 1 auto;
            justify-content: center;
        }
    }

    &-logo,
    &-utility {
        $nav-utilityWidth: 280px;

        display: flex;
        align-items: center;
        flex: 1;

        @include respond-to(tablets-landscape) {
            flex: 1 1 auto;
        }

        @include respond-to(desktop-large) {
            flex: 1 1 calc(#{$nav-utilityWidth} - var(--navbar-gap, 0));
        }
    }
}

.l-header-toggle {
    .b-navigation_utility {
        &-link {
            color: $white;
            font-size: rem-calc(16);
            padding: rem-calc(15);
            width: rem-calc(48);
            height: rem-calc(48);
        }

        &-icon {
            &::before {
                font-size: rem-calc(16);
            }
        }
    }
}
