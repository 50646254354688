.b-tile {
    position: relative;
    max-width: 100%;

    @include respond-to(desktop) {
        &:hover &-swatches {
            opacity: 1;
        }
    }

    &-variations_container {
        position: relative;
        max-width: 100%;
    }

    &-images_container {
        display: block;
        position: relative;
    }

    &-image {
        width: 100%;
    }

    &-images_outer {
        line-height: 0;
    }

    &-swatches {
        background-color: $white;
        display: flex;
        padding: calc(spacing(xs2) / 2) 0;
        z-index: 1;

        & &_slider {
            display: flex;
            overflow: hidden;
            margin-left: 0;
            position: static;

            &.swatch-carousel {
                width: auto;
                margin: 0 spacing(sm);
            }
        }

        .b-swatches_circle-item {
            flex: 1 0 auto;
            width: auto;
        }

        .b-swatches_circle-link {
            margin: calc(spacing(xs2) / 2) rem-calc(7);
            width: rem-calc(30);
        }

        @include respond-to(desktop) {
            opacity: 0;
            position: absolute;
            bottom: 0;
            width: 100%;

            &.emea-swatches {
                opacity: 1;
                position: relative;
            }

            &.emea-mobile-hide {
                display: flex;
            }

            &.emea-mobile-show {
                display: none;
            }
        }

        @include respond-to(mobile) {
            display: none;

            &.emea-swatches {
                opacity: 1;
                position: relative;
            }

            &.emea-mobile-hide {
                display: none;
            }

            &.emea-mobile-show {
                display: flex;
            }
        }
    }

    &-swatches_container {
        position: relative;
    }

    &-swatches_count {
        @include font($size: 12px);

        display: block;
        padding: calc(spacing(xs2) / 2) 0;
        color: $grey5;
        text-decoration: none;

        @include respond-to(mobile) {
            padding-top: 0;
        }
    }

    &-swatches_slider_button {
        @include button-to-link;

        padding: 0 spacing(xs2);
        cursor: pointer;
        position: absolute;
        top: 58%;
        transform: translateY(-58%);

        &.mac-only {
            top: 50%;
            transform: translateY(-50%);
        }

        &.m-left {
            @include icon(caret-left);

            left: 0;
        }

        &.m-right {
            @include icon(caret-right);

            right: 0;
        }

        &.m-disabled {
            color: $grey3;
            cursor: default;
        }
    }

    &-image_link {
        position: relative;
        display: block;
    }

    &-fav_defultButton {
        display: inline-block;
        cursor: pointer;
        position: absolute;
        top: 12px;
        right: 12px;
        z-index: 1;
        background: transparent;
        border: 0;
        border-radius: 50%;
        -webkit-box-shadow: 0 -1px 11px -3px rgba(148, 148, 148, 0.57);
        -moz-box-shadow: 0 -1px 11px -3px rgba(148, 148, 148, 0.57);
        box-shadow: 0 -1px 11px -3px rgba(148, 148, 148, 0.57);

        @include respond-to(phones) {
            top: spacing(xs2);
            right: spacing(xs2);
        }
    }

    &-fav_button,
    &-fav_selectButton {
        display: inline-block;
        border: none;
        outline: none;
        width: 28px;
        height: 28px;
        padding-top: 7px;
        border-radius: 50%;
        background-color: $white;
        color: $black;
        text-align: center;
        @include icon(fav-default);
    }

    &-fav_selectButton {
        @include icon(fav-selected);
    }

    &-badge_top_left {
        @include font($size: 10px);

        position: absolute;
        display: block;
        cursor: default;
        top: rem-calc(12);
        left: rem-calc(12);
        padding: rem-calc(2) rem-calc(8);
        border-radius: rem-calc(4);
        background: $white;
        z-index: 1;
        max-width: calc(100% - 60px);

        @include respond-to (mobile) {
            @include font($size: 9px);

            line-height: rem-calc(12);
            top: rem-calc(5);
            left: rem-calc(5);
            padding: rem-calc(4) rem-calc(4) rem-calc(2);
            border-radius: rem-calc(3);
            background: rgba(255, 255, 255, 0.75);
        }
    }

    &-badge_bottom_left {
        position: absolute;
        display: block;
        bottom: 20px;
        left: spacing(xs);
        z-index: 1;
        line-height: 0;

        @include respond-to(phones) {
            bottom: spacing(xs);
            left: 12px;
        }
    }

    &-badge_bottom_right {
        position: absolute;
        display: flex;
        gap: 8px;
        bottom: var(--badge-space, 12px);
        right: var(--badge-space, 12px);
        z-index: 1;
        line-height: 0;

        &.active {
            .b-tile-quickAdd-cta {
                pointer-events: none;
            }
        }

        &.active::before {
            content: '';
            height: rem-calc(48);
            width: rem-calc(48);
            position: absolute;
            top: rem-calc(-4);
            left: rem-calc(-4);
            border-radius: 50%;
            border: 2px solid $red;
            border-bottom: 0;
            border-left: 0;
            animation: rotateBorder 1s linear infinite;
        }

        @include respond-to(mobile) {
            --badge-space: 8px;
        }
    }

    &-badge-emea {
        font-size: 14px;
        font-weight: 600;
        letter-spacing: 0.7px;
        line-height: rem-calc(24);
        text-transform: uppercase;

        @include respond-to (mobile) {
            font-size: 12px;
            line-height: 1.25;
            letter-spacing: 0.6px;
            margin-bottom: rem-calc(8);
        }
    }

    &-name {
        @include font(main_med, 14px, 1.14);

        display: inline-block;
        text-decoration: none;
        color: $black;
        cursor: pointer;

        @include respond-to (mobile) {
            font-size: 12px;
        }
    }

    &-info {
        padding: spacing(xs) 0;

        .plp-outofstock,
        .gift-outofstock {
            @include font(main_med, rem-calc(12), 1.67);

            color: $green;
        }
    }

    &-recommendation {
        .b-tile-info {
            padding: 0;
        }

        .b-tile-swatches {
            padding: 0 0 rem-calc(5) 0;
        }

        .b-tile-name {
            padding-bottom: rem-calc(5);
        }
    }

    .b-notify-cta:not(.hide) {
        ~ .b-tile-quickAdd-cta {
            display: none;
        }
    }
}

.b-tile-emea,
.b-tile-constructorio {
    .b-price {
        display: flex;
        line-height: rem-calc(24);

        .b-price-value {
            &.m-strikethrough {
                order: 2;
                margin-right: 0;

                &.highlighted {
                    color: $grey5 !important;
                }
            }

            &.m-actual {
                order: 1;

                &.highlighted {
                    color: $black !important;
                }
            }
        }

        .b-price-range_divider {
            padding: 0 rem-calc(2);
        }
    }

    @include respond-to (mobile) {
        .b-tile-info {
            padding-top: 0;
        }

        .b-tile-variations_container {
            .b-tile-images_outer {
                .b-tile-images_container {
                    margin-bottom: rem-calc(8);

                    picture {
                        display: block;
                    }
                }
            }
        }

        .b-tile-swatches {
            .swiper-container.b-tile-swatches_slider {
                margin: 0;

                .b-swatches_circle {
                    .b-swatches_circle-item:nth-of-type(1n+4) {
                        display: none;
                    }
                }

                .b-tile-swatches_slider_button {
                    display: none;
                }
            }

            .b-tile-swatches_count_plus {
                font-weight: 400;
                font-size: 12px;
                display: block;
                color: $grey5;
                text-decoration: none;
                line-height: rem-calc(16);
            }

            &.emea-mobile-show {
                align-items: center;
            }
        }
    }
}

// style for product tile image background

.b-tile-image {
    &.b-tile-main_image {
        position: relative;
        line-height: 0;
    }
}

@keyframes placeHolderShimmer {
    0% { background-position: -468px 0; }
    100% { background-position: 468px 0; }
}

@keyframes rotateBorder {
    0% {
        transform: rotate(0deg);
    }

    100% {
        transform: rotate(360deg);
    }
}

.b-tile-images_container {
    .b-tile-image-background {
        animation-duration: 1.5s;
        animation-fill-mode: forwards;
        animation-iteration-count: infinite;
        animation-timing-function: linear;
        animation-name: placeHolderShimmer;
        background: linear-gradient(to right, $grey2 8%, $grey9 18%, $grey2 33%);
        background-size: rem-calc(800) rem-calc(104);
        position: absolute;
        width: 100%;
        height: calc(100% - 8px);
    }
}

.b-tile-image-carousel {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    padding: 0;
    line-height: 0;

    &:not(.swiper-container-initialized) {
        opacity: 0.001;
        pointer-events: 0;

        .g-carousel-wrapper {
            display: flex;
            flex-wrap: nowrap;
            overflow: hidden;
        }

        .g-carousel-slide:not(:first-child) {
            display: none;
        }
    }

    * {
        line-height: 0;
    }

    @include respond-to(mobile) {
        &.swiper-container-initialized + .b-tile-images_container {
            opacity: 0.001;
            pointer-events: none;
        }
    }

    @include respond-to(desktop) {
        display: none;
    }

    .g-carousel-scrollbar {
        position: absolute;
        transform: translateY(-100%);
        left: 0;
        right: 0;
        overflow: hidden;
        background: $grey3;
    }

    .swiper-scrollbar-drag {
        background: $black;
        height: 3px;
        position: relative;
    }
}

.b-swatches_circle-link {
    &.no-border {
        &::before {
            border: none !important;
        }
    }
}
