.b-product_feature-benefits {
    padding: rem-calc(16) 0 rem-calc(24);
    border-bottom: 1px solid $grey3;

    @include respond-to(phones) {
        margin: 0;
    }

    .feature-benefits_label {
        font-weight: 600;
        margin-bottom: rem-calc(16);
        font-size: rem-calc(14);

        @include respond-to(phones) {
            margin-bottom: rem-calc(12);
        }
    }

    .feature-benefits_data {
        display: flex;

        li {
            &:last-child {
                margin-right: unset;
            }
        }

        .feature-benefits_list {
            display: flex;
            flex-direction: column;
            align-items: center;
            margin-right: rem-calc(48);
            row-gap: rem-calc(8);

            &.flex-grow {
                flex: 1;
            }

            &.flex-fixed-width {
                min-width: rem-calc(100);
                width: rem-calc(100);
            }

            .feature-icon-label {
                text-align: center;
                font-size: rem-calc(14);
            }

            @include respond-to(phones) {
                margin-right: rem-calc(14);
            }
        }
    }
}

@mixin add-icon($icon) {
    background-repeat: no-repeat;
    background-position: center;
    display: inline-block;
    background-image: $icon;
    height: rem-calc(40);
    width: rem-calc(40);
}

.feature-icon_4-WAY-STRETCH {
    @include add-icon($feature-icon_4-WAY-STRETCH);
}

.feature-icon_ANTI-ODOR {
    @include add-icon($feature-icon_ANTI-ODOR);
}

.feature-icon_ADAPTABLE-3-IN-1 {
    @include add-icon($feature-icon_ADAPTABLE-3-IN-1);
}

.feature-icon_INSULATED {
    @include add-icon($feature-icon_INSULATED);
}

.feature-icon_REACTIVE-INSULATION {
    @include add-icon($feature-icon_REACTIVE-INSULATION);
}

.feature-icon_MOISTURE-WICKING {
    @include add-icon($feature-icon_MOISTURE-WICKING);
}

.feature-icon_FAST-DRYING {
    @include add-icon($feature-icon_FAST-DRYING);
}

.feature-icon_REFLECTIVE-DETAILS {
    @include add-icon($feature-icon_REFLECTIVE-DETAILS);
}

.feature-icon_UPF-30-SUN-PROTECTION {
    @include add-icon($feature-icon_UPF-30-SUN-PROTECTION);
}

.feature-icon_UPF-40-SUN-PROTECTION {
    @include add-icon($feature-icon_UPF-40-SUN-PROTECTION);
}

.feature-icon_UPF-45-SUN-PROTECTION {
    @include add-icon($feature-icon_UPF-45-SUN-PROTECTION);
}

.feature-icon_UPF-50-SUN-PROTECTION {
    @include add-icon($feature-icon_UPF-50-SUN-PROTECTION);
}

.feature-icon_UPF50-SUN-PROTECTION {
    @include add-icon($feature-icon_UPF50-SUN-PROTECTION);
}

.feature-icon_VENTILATED {
    @include add-icon($feature-icon_VENTILATED);
}

.feature-icon_WATER-RESISTANT {
    @include add-icon($feature-icon_WATER-RESISTANT);
}

.feature-icon_WATERPROOF {
    @include add-icon($feature-icon_WATERPROOF);
}

.feature-icon_WINDPROOF {
    @include add-icon($feature-icon_WINDPROOF);
}

.feature-icon_ANTI-GLARE {
    @include add-icon($feature-icon_ANTI-GLARE);
}

.feature-icon_KEEPS-YOU-WARM {
    @include add-icon($feature-icon_KEEPS-YOU-WARM);
}

.feature-icon_KEEPS-YOU-COOL {
    @include add-icon($feature-icon_KEEPS-YOU-COOL);
}

.feature-icon_CHAFE-FREE {
    @include add-icon($feature-icon_CHAFE-FREE);
}

.feature-icon_EASY-ACCESS-POCKETS {
    @include add-icon($feature-icon_EASY-ACCESS-POCKETS);
}

.feature-icon_PACKABLE {
    @include add-icon($feature-icon_PACKABLE);
}

.feature-icon_STRETCHY-UPPER {
    @include add-icon($feature-icon_STRETCHY-UPPER);
}

.feature-icon_SCENT-CONTROL {
    @include add-icon($feature-icon_SCENT-CONTROL);
}

.feature-icon_REMOVABLE-SOCKLINER {
    @include add-icon($feature-icon_REMOVABLE-SOCKLINER);
}

.feature-icon_ULTRA-CUSHIONED {
    @include add-icon($feature-icon_ULTRA-CUSHIONED);
}

.icon-exchange {
    @include add-icon($return-icon);

    height: 16px;
    width: 16px;
}

.icon-qatc {
    @include add-icon($qatc-icon);

    height: 40px;
    width: 40px;
}

.icon-qatcg {
    @include add-icon($qatcg-icon);

    height: 40px;
    width: 40px;
}

.icon-qatcr {
    @include add-icon($qatcr-icon);

    height: 40px;
    width: 40px;
}
