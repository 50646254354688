.embeddedServiceHelpButton .helpButton .uiButton {
    background-color: #000;
}

.embeddedServiceHelpButton .helpButton .uiButton:hover {
    font-family: 'Armour_W_Rg', Arial, sans-serif;
    background-color: #333;
    border-color: #333;
    display: block;
    border-radius: 4px 4px 0 0;
}

@media (max-width: 767.98px) {
    .embeddedServiceHelpButton .helpButton .uiButton:hover {
        border-radius: 4px;
    }
}

.embeddedServiceHelpButton .helpButton .uiButton:hover::before {
    display: block;
}

.embeddedServiceHelpButton .helpButton .uiButton:focus {
    border-radius: 4px 4px 0 0;
    outline: 1px solid #000;
}

@media (max-width: 767.98px) {
    .embeddedServiceHelpButton .helpButton .uiButton:focus {
        border-radius: 4px;
    }
}

.embeddedServiceHelpButton .helpButton .uiButton,
.embeddedServiceHelpButton .helpButton .uiButton .helpButtonLabel {
    font-family: 'Armour_W_Rg', Arial, sans-serif;
    font-size: 14px;
    max-width: none;
    display: inline-block;
}

.embeddedServiceHelpButton .helpButton .uiButton,
.embeddedServiceHelpButton .helpButton .uiButton.helpButtonEnabled {
    background: #000;
    display: block;
    border-radius: 4px 4px 0 0;
    width: 230px;
}

.embeddedServiceHelpButton .helpButton .uiButton.helpButtonEnabled::before,
.embeddedServiceHelpButton .helpButton .uiButton::before {
    background-color: #333 !important;
    border-radius: 4px 4px 0 0 !important;
    opacity: 1 !important;
}

@media (max-width: 767.98px) {
    .embeddedServiceHelpButton .helpButton .uiButton.helpButtonEnabled::before,
    .embeddedServiceHelpButton .helpButton .uiButton::before {
        border-radius: 4px !important;
    }
}

@media (max-width: 767.98px) {
    .embeddedServiceHelpButton .helpButton .uiButton,
    .embeddedServiceHelpButton .helpButton .uiButton.helpButtonEnabled {
        border-radius: 4px;
    }
}

.embeddedServiceHelpButton .helpButton .uiButton.helpButtonDisabled {
    background: #e3e3e5;
    display: block;
    border-radius: 4px 4px 0 0;
}

@media (max-width: 767.98px) {
    .embeddedServiceHelpButton .helpButton .uiButton.helpButtonDisabled {
        border-radius: 4px;
    }
}

.sidebarHeader {
    display: block;
    border-radius: 4px 4px 0 0;
    font-size: 14px;
}

.sidebarHeader img {
    display: block;
    border-radius: 50%;
}

.sidebarHeader.minimizedContainer.embeddedServiceSidebarMinimizedDefaultUI {
    display: block;
    border-radius: 4px 4px 0 0;
    bottom: 0;
}

@media (max-width: 767.98px) {
    .sidebarHeader.minimizedContainer.embeddedServiceSidebarMinimizedDefaultUI {
        bottom: 12px;
        border-radius: 4px;
        height: auto;
        width: auto;
        padding: 10px;
    }
}

.sidebarHeader.minimizedContainer.embeddedServiceSidebarMinimizedDefaultUI .messageContent {
    -ms-flex-direction: row;
    flex-direction: row;
}

.sidebarHeader.minimizedContainer.embeddedServiceSidebarMinimizedDefaultUI .minimizedImage {
    margin-right: 10px;
}

.embeddedServiceLiveAgentStateChatItem .isLightningOutContext .avatar.iconAvatar {
    font-size: 0;
    background-image: url(https://ta-underarmour.s3.ap-southeast-2.amazonaws.com/media/static/chatbot/chatbot-avatar-underarmour-red.svg);
    background-color: #e3e3e5;
}

.embeddedServiceSidebar button[embeddedService-chatHeader_chatHeader]:focus,
.embeddedServiceSidebar button[embeddedService-chatHeader_chatHeader]:focus::before,
.embeddedServiceSidebar button[embeddedService-chatHeader_chatHeader]:hover::before {
    outline: 0;
    border: 1px solid #fff;
    border-radius: 4px;
}

@media (max-width: 767.98px) {
    .embeddedServiceSidebar button[embeddedService-chatHeader_chatHeader].closeButton,
    .embeddedServiceSidebar button[embeddedService-chatHeader_chatHeader].minimizeButton {
        padding: 0 7px;
    }
}

.embeddedServiceSidebarMinimizedDefaultUI.minimizedContainer:focus {
    text-decoration: none;
}

.messageWrapper .embeddedServiceLiveAgentStateChatItem .chatContent ul.rich-menu-items li,
.messageWrapper .embeddedServiceLiveAgentStateChatItem .chatContent ul.rich-menu-items li:first-of-type {
    border-color: #9b9b9b;
}

.messageWrapper .embeddedServiceLiveAgentStateChatItem .chatContent ul.rich-menu-items li:first-of-type:hover,
.messageWrapper .embeddedServiceLiveAgentStateChatItem .chatContent ul.rich-menu-items li:hover {
    background-color: #e3e3e5;
    border-color: #9b9b9b;
}

.messageWrapper .embeddedServiceLiveAgentStateChatItem.chatMessage button {
    font-size: 0.8em;
}

.headerAnnouncement[embeddedService-chatHeader_chatHeader] p {
    font-size: 1.2em;
}

.messageWrapper .embeddedServiceLiveAgentStateChatPlaintextMessageDefaultUI.plaintextContent {
    font-size: 0.8em;
}

.embeddedServiceSidebarDialogState .dialogButton,
.endChatButton.closeChatButton.uiButton--default.uiButton.embeddedServiceSidebarButton,
.waitingCancelChat.uiButton--inverse.uiButton.embeddedServiceSidebarButton {
    border-radius: 4px;
}

.embeddedServiceSidebar.layout-docked .dockableContainer.show {
    border-radius: 4px 4px 0 0;
}

.embeddedServiceSidebar .dockableContainer .sidebarBody {
    transform: unset;
}

.embeddedServiceLiveAgentStateChatMessage a,
.embeddedServiceLiveAgentStateChatMessage a:focus,
.embeddedServiceLiveAgentStateChatMessage a:hover {
    color: #000;
    text-decoration: underline;
}

.chasitor.chat-content[c-richtextChatContent_richtextChatContent] {
    background-color: #494949;
}

.chasitor.chat-content[c-richtextChatContent_richtextChatContent] a {
    color: #fff;
}

.embeddedServiceLiveAgentStateChatMenuMessage .chatContent .rich-menu-item,
.embeddedServiceLiveAgentStateChatMenuMessage .chatContent .rich-menu-item:focus,
.embeddedServiceLiveAgentStateChatMenuMessage .chatContent .rich-menu-item:hover {
    color: #000;
}

.embeddedServiceLiveAgentStateChat .chatSessionStartTime {
    color: #000;
}

.embeddedServiceSidebar.modalcontainer {
    font-family: 'Armour_W_Rg', Arial, sans-serif;
}

.embeddedServiceLiveAgentStateChatInputFooter .chasitorControls .uiInput {
    border-color: #000;
}

.embeddedServiceLiveAgentStateChatInputFooter .chasitorText {
    color: #000;
}

h2[embeddedService-chatHeader_chatHeader] {
    font-family: 'Armour_W_Bd', Arial, sans-serif;
    font-weight: 700;
}

.embeddedServiceSidebarMinimizedDefaultUI .minimizedText > .message {
    font-family: 'Armour_W_Bd', Arial, sans-serif;
    font-weight: 700;
    font-size: 14px;
}

@media (max-width: 767.98px) {
    .embeddedServiceHelpButton .embeddedServiceIcon {
        margin-right: 0 !important;
    }

    .embeddedServiceHelpButton .embeddedServiceIcon::before {
        font-size: 2.4em !important;
    }
}

.embeddedServiceHelpButton .helpButton .uiButton .helpButtonLabel .message,
.embeddedServiceHelpButton .helpButton .uiButton .message {
    display: none;
}

@media (min-width: 768px) {
    .embeddedServiceHelpButton .helpButton .uiButton .helpButtonLabel .message,
    .embeddedServiceHelpButton .helpButton .uiButton .message {
        display: block;
    }
}

.embeddedServiceHelpButton .helpButton .uiButton,
.embeddedServiceHelpButton .helpButton .uiButton.helpButtonEnabled {
    background: #000;
    display: block;
    width: 200px;
}

@media (max-width: 767.98px) {
    .embeddedServiceHelpButton .helpButton .uiButton,
    .embeddedServiceHelpButton .helpButton .uiButton.helpButtonEnabled {
        border-radius: 4px;
        width: 60px;
        min-width: 46px;
    }
}

.embeddedServiceHelpButton .helpButton .uiButton.helpButtonEnabled::before,
.embeddedServiceHelpButton .helpButton .uiButton::before {
    background-color: #333 !important;
    opacity: 1 !important;
}

@media (max-width: 767.98px) {
    .embeddedServiceHelpButton .helpButton .uiButton.helpButtonEnabled::before,
    .embeddedServiceHelpButton .helpButton .uiButton::before {
        border-radius: 4px !important;
    }
}

@media (max-width: 767.98px) {
    .embeddedServiceHelpButton .helpButton {
        bottom: 8% !important;
    }
}
