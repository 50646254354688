.b-createaccount {
    .b-return-wrap {
        .g-checkbox {
            position: relative;
            margin-bottom: rem-calc(28);
            text-align: left;
            margin-top: rem-calc(20);

            @include respond-to(mobile) {
                margin-top: rem-calc(28);
            }

            .g-checkbox-label {
                display: inline-block;
                padding-top: 0;
                text-align: left;

                .required-consents {
                    white-space: nowrap;
                    color: $red;
                    text-decoration: none;
                }
            }
        }
    }
}

.emaillist-checkbox {
    margin-bottom: rem-calc(12);
}

#register {
    .naverBtn {
        margin-bottom: 0;
        display: block;
        width: fit-content;
    }

    .top-text {
        margin: rem-calc(19.2) 0 rem-calc(16);
    }
}
