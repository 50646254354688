.l-wishlist {
    &-container {
        max-width: rem-calc(1196);
        margin: 0 auto;
        padding: 0 rem-calc(20);

        .wishlist-sort {
            max-width: 20%;
            margin: 0 0 0 auto;

            @include respond-to(mobile) {
                max-width: 100%;
            }
        }

        #wishlistItemCards {
            @include respond-to(mobile) {
                margin-top: rem-calc(60);

                .b-wishlist-grid {
                    margin-top: rem-calc(-80);
                }
            }
        }
    }

    &-container-top {
        margin-top: rem-calc(56);
        display: block;
        padding-bottom: rem-calc(32);
        border-bottom: 1px solid #d0d0d0;
        margin-bottom: rem-calc(24);
        text-align: center;

        @include respond-to(mobile) {
            margin-top: rem-calc(24);
            padding-bottom: rem-calc(24);
        }
    }
}

.b-wishlist_tile {
    &-fav_defultButton {
        display: inline-block;
        cursor: pointer;
        position: absolute;
        top: 1rem;
        right: 1rem;
        z-index: 1;
        background: transparent;
        border: 0;
        color: $black;

        &::before {
            font-size: rem-calc(32);
            @include respond-to(mobile) {
                font-size: rem-calc(16);
            }
        }
    }

    &-fav_headerButton {
        @include icon(fav-selected);
    }

    &-fav_selectButton {
        @include icon(fav-selected);

        &::before {
            font-size: rem-calc(32);
            @include respond-to(mobile) {
                font-size: rem-calc(16);
            }
        }
    }
}

.b-wishlist {
    &-hr_line {
        margin-bottom: rem-calc(32);
        color: $grey3;
        @include respond-to(mobile) {
            margin-bottom: rem-calc(23);
        }
    }

    &-grid {
        margin: 0;
    }

    &-grid_view {
        flex-basis: 100%;
        display: flex;
        flex-wrap: wrap;
        margin: 0 -1rem;
        margin-top: rem-calc(32);
        @include respond-to(mobile) {
            margin: 0 rem-calc(-20);
        }
    }

    &-products_tile {
        flex-basis: calc(100% / 3);
        padding: 0 1rem;
        margin-bottom: spacing(md);

        .item-image {
            position: relative;
            height: auto;
            width: auto;
            margin-right: 0;

            @include respond-to(mobile) {
                flex-basis: 30%;
                position: unset;
            }

            .b-tile-badge_bottom_right {
                bottom: rem-calc(16);
                right: rem-calc(20);

                @include respond-to(mobile) {
                    bottom: rem-calc(14);
                }
            }

            .b-wishlist_tile-fav_defultButton {
                top: rem-calc(12);

                @include respond-to(mobile) {
                    right: unset;
                    left: calc(30% - 32px);
                    top: rem-calc(16);
                }
            }

            .b-wishlist_tile-fav_selectButton {
                &::before {
                    font-size: rem-calc(16);
                    padding: rem-calc(7);
                    background: $white;
                    border-radius: 50%;
                    display: block;
                }
            }
        }
        @include respond-to(mobile) {
            max-width: 100%;
            flex-basis: 100%;
            padding: 0 rem-calc(20) rem-calc(20);
            align-items: flex-start;
            position: relative;
            display: flex;
            border-bottom: 1px solid $grey3;
            margin-bottom: rem-calc(20);
        }

        &-outofstock {
            position: relative;

            &::after,
            &.product_tile-noimage .item-image::after {
                content: '';
                width: 100%;
                height: 100%;
                position: absolute;
                top: 0;
                left: 0;
                background: $athens-gray;
            }

            &::after {
                background: $white;
                opacity: 0.5;
            }

            .outofstock-message {
                @include t-text-oos;
            }
        }

        .b-tile-info {
            .variation-attr {
                margin: rem-calc(5) 0;

                .t-lineitem_attributes {
                    color: $boulder;
                    font-weight: 700;

                    span {
                        font-weight: 400;
                    }
                }

                @include respond-to(mobile) {
                    margin-bottom: rem-calc(16);
                }
            }

            .b-tile-name {
                @include respond-to(mobile) {
                    font-size: rem-calc(16);
                    font-weight: 600;
                }
            }

            .b-price-value {
                line-height: rem-calc(24);
            }

            @include respond-to(mobile) {
                padding: rem-calc(4) 0 rem-calc(16) rem-calc(16);
                flex-basis: 70%;
            }
        }
    }

    &-products_img {
        width: 100%;
    }

    &-result_countandsort {
        display: flex;
        flex-basis: 100%;
        @include respond-to(desktop) {
            margin-top: rem-calc(-30);
        }

        @include respond-to(mobile) {
            margin-bottom: rem-calc(92);
        }
    }

    &-result_count {
        flex-basis: 80%;
        text-align: right;
        font-weight: bold;
        padding-right: rem-calc(40);
        font-size: rem-calc(16);

        @include respond-to(mobile) {
            text-align: left;
            flex-basis: 100%;
            padding: 0;
        }
    }

    &-btn {
        margin-top: rem-calc(32);
        @include respond-to(mobile) {
            margin-top: rem-calc(16);
            max-width: 100%;
        }
    }

    &-btn_content {
        flex-basis: rem-calc(212);
        @include respond-to(mobile) {
            flex-basis: 100%;
        }
    }

    &-saved_item {
        margin-bottom: rem-calc(8);
        font-size: spacing(md);
        font-weight: bold;
        line-height: 1.25;
        letter-spacing: 1px;
        color: $black;

        @include respond-to(mobile) {
            margin-bottom: 0;
        }
    }

    &-saved_item_desc {
        font-size: rem-calc(14);
        font-weight: 600;
        line-height: rem-calc(24);

        @include respond-to(mobile) {
            font-size: rem-calc(12);
        }

        .b-tile-fav_selectButton {
            box-shadow: none;
            width: auto;
        }
    }
}

.category-slot {
    display: flex;
    max-width: 100%;
    justify-content: space-between;
    margin-top: rem-calc(32);
    @include respond-to(mobile) {
        flex-wrap: wrap;
    }

    .category-item {
        margin-bottom: rem-calc(32);

        a {
            text-decoration: none;
        }
        @include respond-to(mobile) {
            max-width: 48%;
            margin-bottom: rem-calc(16);
        }
    }

    h1 {
        margin-top: rem-calc(32);
        font-size: rem-calc(12);
        font-weight: 600;
        line-height: 1.67;
        letter-spacing: normal;
        color: $grey5;
        @include respond-to(mobile) {
            margin: auto;
            padding: rem-calc(16) 0 rem-calc(2);
            border-bottom: 2px solid;
            display: inline-block;
        }
    }
}

.wishlist-dashboard {
    .b-wishlist_tile-fav_selectButton {
        &::before {
            font-size: rem-calc(16);
        }
    }
}
