.b-physicalgiftcard_outer {
    .b-giftcards-amount-outer {
        display: block;
    }
}

.e-giftcard {
    .b-product_attrs-item {
        margin-top: 0;
        padding-top: 0.9375rem;
        padding-bottom: 0.9375rem;
        margin-bottom: 1rem;
        border: 1px solid transparent;

        .b-egiftcard-amount {
            .invalid-gcamount-feedback {
                color: $black;
                font-size: rem-calc(13);
                position: relative;
                margin-top: 13px;
                @include icon('alert');

                &::before {
                    position: static;
                    margin-right: rem-calc(5);
                    vertical-align: middle;
                }
            }

            .b-input_row {
                margin-bottom: 0;
            }
        }

        &.has-error {
            padding: 0.9375rem 1rem;
            border: 1px solid $red;

            .b-egiftcard-amount {
                .invalid-gcamount-feedback {
                    color: $red;
                }

                label {
                    color: $black;
                }

                #gc-amount {
                    border-color: $grey3;
                }
            }
        }

        .b-product_attribute {
            margin-bottom: rem-calc(15);
        }
    }

    #gc-delivery-date {
        color: $black;
    }

    .b-egiftcard-from {
        .b-input_row {
            margin-bottom: rem-calc(25);
        }
    }

    .js-egiftcard-add-to-cart {
        &.f-added-check {
            align-items: center;
            @include icon(check);
        }
    }
}

.b-cart-added-confirmation-modal {
    .g-modal-dialog {
        .g-modal-content {
            .js-product-detailsConfirmation {
                &.eGiftcard-Added {
                    padding-bottom: rem-calc(24);
                    @include respond-to(mobile) {
                        padding-bottom: rem-calc(32);
                    }
                }
            }
        }
    }
}

.b-product_attrs-item.b-product-giftcard-swatch {
    padding-bottom: 0;
}
