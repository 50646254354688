.g-flyout {
    @include g-flyout-common;

    &-top {
        @include g-flyout(top);
    }

    &-right {
        @include g-flyout(right);
    }

    &-bottom {
        @include g-flyout(bottom);
    }

    &-left {
        @include g-flyout(left);
    }

    &-backdrop {
        @include flyout-backdrop;
    }
}
