.l-consent_banner,
.l-privacy_banner {
    display: none;
    position: fixed;
    bottom: 0;
    left: 0;
    width: 100%;
    background: $black;
    padding: 2rem;
    font-size: rem-calc(14);
    color: $white;
    z-index: 2;

    @include respond-to(mobile) {
        padding: 1rem;
        padding-right: 3rem;
        font-size: rem-calc(12);
    }

    @include respond-to(phones) {
        min-height: rem-calc(72);
    }

    a {
        color: $white;
        text-decoration: underline;

        &:hover {
            text-decoration: none;
        }
    }

    .close {
        position: absolute;
        top: 0;
        right: 0;
        box-sizing: content-box;
        padding: 2rem;
        background: transparent;
        color: #fff;
        outline: none;
        border: none;
        cursor: pointer;
        font-size: 1.8rem;
        display: block;
        line-height: 1rem;
        width: 1rem;

        @include respond-to(mobile) {
            padding: 1.5rem 1rem;
        }
    }
}

.m-consent-show {
    .g-email-pop-modal,
    .g-email-pop-modal-dialog {
        bottom: rem-calc(84);
    }

    .l-privacy_banner {
        bottom: rem-calc(85);
    }
    @include respond-to(mobile) {
        .b-product_actions-inner,
        .g-email-pop-modal,
        .g-email-pop-modal-dialog {
            bottom: rem-calc(52);
        }

        .l-privacy_banner {
            bottom: rem-calc(53);
        }
    }
    @include respond-to(phones) {
        .b-product_actions-inner,
        .g-email-pop-modal,
        .g-email-pop-modal-dialog {
            bottom: rem-calc(72);
        }

        .l-privacy_banner {
            bottom: rem-calc(74);
        }
    }

    &.m-privacy_banner-show {

        @include respond-to(mobile) {
            .b-product_actions-inner {
                bottom: rem-calc(126);
            }
        }
        @include respond-to(phones) {
            .b-product_actions-inner {
                bottom: rem-calc(165);
            }
        }
    }
}

.m-privacy_banner-show {
    .g-email-pop-modal,
    .g-email-pop-modal-dialog {
        bottom: rem-calc(84);
    }

    @include respond-to(mobile) {
        .b-product_actions-inner,
        .g-email-pop-modal,
        .g-email-pop-modal-dialog {
            bottom: rem-calc(72);
        }
    }
    @include respond-to(phones) {
        .b-product_actions-inner,
        .g-email-pop-modal,
        .g-email-pop-modal-dialog {
            bottom: rem-calc(92);
        }
    }
}

.l-body {
    .l-privacy_banner {
        display: block;
    }
}
