@mixin spinner ($type) {
    .b-loader,
    .b-cart-loader {
        @if ($type == button) {
            position: absolute;
            background: inherit;
            opacity: 1;

            .b-loader-icon {
                border-radius: rem-calc(4);
                background-color: $white;
                animation: dots 1s infinite ease-in-out both;

                &:nth-child(2) {
                    animation-delay: 0.166s;
                }

                &:nth-child(3) {
                    animation-delay: 0.333s;
                }
            }

            @keyframes dots {
                0% {
                    opacity: 0.3;
                }

                50% {
                    opacity: 0.6;
                }

                100% {
                    opacity: 1;
                }
            }
        } @else {
            display: flex;
            align-items: center;
            justify-content: center;
            min-height: rem-calc(44);
            position: fixed;
            top: 0;
            width: 100%;
            height: 100%;
            margin: 0;
            background: $white;
            right: 0;
            opacity: 0.7;
            z-index: 1;

            &.m-absolute {
                position: absolute;
            }

            &-icon {
                display: inline-block;
                width: 8px;
                height: 8px;
                background-color: #767676;
                opacity: 1;
                animation: fade infinite 1s;

                & + & {
                    margin-left: 6px;
                }

                &:nth-child(2) {
                    animation-delay: 0.166s;
                }

                &:nth-child(3) {
                    animation-delay: 0.333s;
                }
            }

            &-hidden_text {
                @include hide(visually);
            }
        }
    }
}
