//Freak Flags, Copyright ©2020 Michael P. Cohen. Freak flags is licenced under the MIT licence.
//For complete information visit: www.freakflagsprite.com
.fflag {
    background-image: url(../images/flagSprite42.png);
    background-repeat: no-repeat;
    background-size: 100% 49494%;
    display: inline-block;
    overflow: hidden;
    position: relative;
    vertical-align: middle;
    box-sizing: content-box;
}
.fflag-DZ { background-position: center 0.2287%; }
.fflag-AO { background-position: center 0.4524%; }
.fflag-BJ { background-position: center 0.6721%; }
.fflag-BW { background-position: center 0.8958%; }
.fflag-BF { background-position: center 1.1162%; }
.fflag-BI { background-position: center 1.3379%; }
.fflag-CM { background-position: center 1.5589%; }
.fflag-CV { background-position: center 1.7836%; }
.fflag-CF { background-position: center 2.0047%; }
.fflag-TD { background-position: center 2.2247%; }
.fflag-CD { background-position: left 2.4467%; }
.fflag-DJ { background-position: left 2.6674%; }
.fflag-EG { background-position: center 2.8931%; }
.fflag-GQ { background-position: center 3.1125%; }
.fflag-ER { background-position: left 3.3325%; }
.fflag-ET { background-position: center 3.5542%; }
.fflag-GA { background-position: center 3.7759%; }
.fflag-GM { background-position: center 4.0015%; }
.fflag-GH { background-position: center 4.2229%; }
.fflag-GN { background-position: center 4.441%; }
.fflag-GW { background-position: left 4.66663%; }
.fflag-CI { background-position: center 4.8844%; }
.fflag-KE { background-position: center 5.1061%; }
.fflag-LS { background-position: center 5.3278%; }
.fflag-LR { background-position: left 5.5495%; }
.fflag-LY { background-position: center 5.7712%; }
.fflag-MG { background-position: center 5.994%; }
.fflag-MW { background-position: center 6.2156%; }
.fflag-ML { background-position: center 6.4363%; }
.fflag-MR { background-position: center 6.658%; }
.fflag-MU { background-position: center 6.8805%; }
.fflag-YT { background-position: center 7.1038%; }
.fflag-MA { background-position: center 7.3231%; }
.fflag-MZ { background-position: left 7.5448%; }
.fflag-NA { background-position: left 7.7661%; }
.fflag-NE { background-position: center 7.98937%; }
.fflag-NG { background-position: center 8.2099%; }
.fflag-CG { background-position: center 8.4316%; }
.fflag-RE { background-position: center 8.6533%; }
.fflag-RW { background-position: right 8.875%; }
.fflag-ST { background-position: center 9.0967%; }
.fflag-SN { background-position: center 9.32237%; }
.fflag-SC { background-position: left 9.5426%; }
.fflag-SL { background-position: center 9.7628%; }
.fflag-SO { background-position: center 9.9845%; }
.fflag-ZA { background-position: left 10.2052%; }
.fflag-SR { background-position: center 10.4269%; }
.fflag-SZ { background-position: center 10.6486%; }
.fflag-TG { background-position: left 10.8703%; }
.fflag-TN { background-position: center 11.0945%; }
.fflag-UG { background-position: center 11.3135%; }
.fflag-TZ { background-position: center 11.5354%; }
.fflag-YE { background-position: center 11.7593%; }
.fflag-ZM { background-position: center 11.9799%; }
.fflag-ZW { background-position: left 12.2005%; }
.fflag-AI { background-position: center 12.4222%; }
.fflag-AG { background-position: center 12.644%; }
.fflag-AW { background-position: left 12.8664%; }
.fflag-BS { background-position: left 13.0873%; }
.fflag-BB { background-position: center 13.309%; }
.fflag-BQ { background-position: center 13.5307%; }
.fflag-BZ { background-position: center 13.7524%; }
.fflag-BM { background-position: center 13.9741%; }
.fflag-BO { background-position: center 14.1958%; }
.fflag-VG { background-position: center 14.4175%; }
.fflag-BR { background-position: center 14.6415%; }
.fflag-CA { background-position: center 14.8609%; }
.fflag-KY { background-position: center 15.0826%; }
.fflag-CL { background-position: left 15.306%; }
.fflag-CO { background-position: left 15.528%; }
.fflag-KM { background-position: center 15.7496%; }
.fflag-CR { background-position: center 15.9694%; }
.fflag-CW { background-position: center 16.1911%; }
.fflag-DM { background-position: center 16.4128%; }
.fflag-DO { background-position: center 16.6345%; }
.fflag-EC { background-position: center 16.8562%; }
.fflag-SV { background-position: center 17.0779%; }
.fflag-FK { background-position: center 17.2996%; }
.fflag-GF { background-position: center 17.5213%; }
.fflag-GL { background-position: left 17.743%; }
.fflag-GD { background-position: center 17.968%; }
.fflag-GP { background-position: center 18.1864%; }
.fflag-GT { background-position: center 18.4081%; }
.fflag-GY { background-position: center 18.6298%; }
.fflag-HT { background-position: center 18.8515%; }
.fflag-HN { background-position: center 19.0732%; }
.fflag-JM { background-position: center 19.2987%; }
.fflag-MQ { background-position: center 19.518%; }
.fflag-MX { background-position: center 19.7383%; }
.fflag-MS { background-position: center 19.96%; }
.fflag-NI { background-position: center 20.1817%; }
.fflag-PA { background-position: center 20.4034%; }
.fflag-PY { background-position: center 20.6241%; }
.fflag-PE { background-position: center 20.8468%; }
.fflag-BL { background-position: center 21.0685%; }
.fflag-KN { background-position: center 21.2902%; }
.fflag-LC { background-position: center 21.5119%; }
.fflag-PM { background-position: center 21.7336%; }
.fflag-VC { background-position: center 21.9553%; }
.fflag-SX { background-position: left 22.177%; }
.fflag-TT { background-position: center 22.4002%; }
.fflag-TC { background-position: center 22.6204%; }
.fflag-US { background-position: center 22.8421%; }
.fflag-UY { background-position: left 23.0638%; }
.fflag-VE { background-position: center 23.2855%; }
.fflag-AF { background-position: center 23.5072%; }
.fflag-AZ { background-position: center 23.732%; }
.fflag-BD { background-position: center 23.9506%; }
.fflag-BT { background-position: center 24.1723%; }
.fflag-BN { background-position: center 24.394%; }
.fflag-KH { background-position: center 24.6157%; }
.fflag-CN { background-position: left 24.8374%; }
.fflag-GE { background-position: center 25.0591%; }
.fflag-HK { background-position: center 25.2808%; }
.fflag-IN { background-position: center 25.5025%; }
.fflag-ID { background-position: center 25.7242%; }
.fflag-JP { background-position: center 25.9459%; }
.fflag-KZ { background-position: center 26.1676%; }
.fflag-LA { background-position: center 26.3885%; }
.fflag-MO { background-position: center 26.611%; }
.fflag-MY { background-position: center 26.8327%; }
.fflag-MV { background-position: center 27.0544%; }
.fflag-MN { background-position: left 27.2761%; }
.fflag-MM { background-position: center 27.4978%; }
.fflag-PW { background-position: center 27.9412%; }
.fflag-PG { background-position: center 28.1615%; }
.fflag-PH { background-position: left 28.3846%; }
.fflag-SG { background-position: left 28.6063%; }
.fflag-KR { background-position: center 28.829%; }
.fflag-LK { background-position: right 29.0497%; }
.fflag-TW { background-position: left 29.2714%; }
.fflag-TJ { background-position: center 29.4931%; }
.fflag-TH { background-position: center 29.7148%; }
.fflag-TL { background-position: left 29.9365%; }
.fflag-TM { background-position: center 30.1582%; }
.fflag-VN { background-position: center 30.3799%; }
.fflag-AL { background-position: center 30.6016%; }
.fflag-AD { background-position: center 30.8233%; }
.fflag-AM { background-position: center 31.045%; }
.fflag-AT { background-position: center 31.2667%; }
.fflag-BY { background-position: left 31.4884%; }
.fflag-BE { background-position: center 31.7101%; }
.fflag-BA { background-position: center 31.9318%; }
.fflag-BG { background-position: center 32.1535%; }
.fflag-HR { background-position: center 32.3752%; }
.fflag-CY { background-position: center 32.5969%; }
.fflag-CZ { background-position: left 32.8186%; }
.fflag-DK { background-position: center 33.0403%; }
.fflag-EE { background-position: center 33.258%; }
.fflag-FO { background-position: center 33.4837%; }
.fflag-FI { background-position: center 33.7054%; }
.fflag-FR { background-position: center 33.9271%; }
.fflag-DE { background-position: center 34.1488%; }
.fflag-GI { background-position: center 34.3705%; }
.fflag-GR { background-position: left 34.5922%; }
.fflag-HU { background-position: center 34.8139%; }
.fflag-IS { background-position: center 35.0356%; }
.fflag-IE { background-position: center 35.2555%; }
.fflag-IM { background-position: center 35.479%; }
.fflag-IT { background-position: center 35.7007%; }
.fflag-JE { background-position: center 35.9224%; }
.fflag-LV { background-position: center 36.1441%; }
.fflag-LI { background-position: left 36.3658%; }
.fflag-LT { background-position: center 36.5875%; }
.fflag-LU { background-position: center 36.8092%; }
.fflag-MT { background-position: left 37.0309%; }
.fflag-MD { background-position: center 37.2526%; }
.fflag-ME { background-position: center 37.696%; }
.fflag-NL { background-position: center 37.9177%; }
.fflag-MK { background-position: center 38.1394%; }
.fflag-NO { background-position: center 38.3611%; }
.fflag-PL { background-position: center 38.5828%; }
.fflag-PT { background-position: center 38.8045%; }
.fflag-RO { background-position: center 39.0262%; }
.fflag-RU { background-position: center 39.2479%; }
.fflag-SM { background-position: center 39.4696%; }
.fflag-RS { background-position: center 39.6913%; }
.fflag-SK { background-position: center 39.913%; }
.fflag-SI { background-position: center 40.1347%; }
.fflag-ES { background-position: left 40.3564%; }
.fflag-SE { background-position: center 40.5781%; }
.fflag-TR { background-position: center 41.0215%; }
.fflag-UA { background-position: center 41.2432%; }
.fflag-GB { background-position: center 41.4649%; }
.fflag-VA { background-position: right 41.6866%; }
.fflag-BH { background-position: center 41.9083%; }
.fflag-IL { background-position: center 42.13%; }
.fflag-KW { background-position: left 42.3517%; }
.fflag-JO { background-position: left 42.5734%; }
.fflag-KG { background-position: center 42.7951%; }
.fflag-LB { background-position: center 43.0168%; }
.fflag-OM { background-position: left 43.2385%; }
.fflag-PK { background-position: center 43.4602%; }
.fflag-QA { background-position: center 43.6819%; }
.fflag-SA { background-position: center 43.9036%; }
.fflag-UZ { background-position: left 44.1253%; }
.fflag-AU { background-position: center 44.347%; }
.fflag-CX { background-position: center 44.5687%; }
.fflag-CC { background-position: center 44.7904%; }
.fflag-CK { background-position: center 45.0121%; }
.fflag-FJ { background-position: center 45.2338%; }
.fflag-PF { background-position: center 45.4555%; }
.fflag-KI { background-position: center 45.6772%; }
.fflag-MH { background-position: left 45.897%; }
.fflag-FM { background-position: center 46.1206%; }
.fflag-NC { background-position: center 46.3423%; }
.fflag-NZ { background-position: center 46.561%; }
.fflag-NR { background-position: left 46.7857%; }
.fflag-NU { background-position: center 47.0074%; }
.fflag-WS { background-position: left 47.2291%; }
.fflag-SB { background-position: left 47.4508%; }
.fflag-TO { background-position: left 47.6725%; }
.fflag-TV { background-position: center 47.8942%; }
.fflag-VU { background-position: left 48.1159%; }
.fflag-WF { background-position: center 48.3376%; }

.fflag-MC {
    background-position: center 38.5828%;
    transform: scaleY(-1);
}

.fflag-NP {
    background-position: left 27.7195%;
    box-shadow: none !important;
}

.fflag-CH {
    background-position: center 40.7998%;
    box-shadow: none !important;
}

.fflag-AE {
    background-image: url(../images/Flag_UAE.png);
    background-size: 100%;
}

.fflag.ff-sm {
    width: 18px;
    height: 11px;
}

.fflag.ff-md {
    width: 27px;
    height: 17px;
}

.fflag.ff-lg {
    width: 42px;
    height: 27px;
}

.fflag.ff-xl {
    width: 60px;
    height: 37px;
}

.fflag.ff-lt { box-shadow: 0 0 0 1px rgb(220, 220, 220); }
.fflag.ff-dk { box-shadow: 0 0 0 1px rgb(90, 90, 90); }
