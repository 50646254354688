@mixin t-common_list_styles {
    margin: rem-calc(25) 0;
    list-style: none;

    li {
        @include t-text-2;

        position: relative;
        padding: 0 0 rem-calc(28) rem-calc(15);

        &::before {
            position: absolute;
        }
    }

    ul,
    ol {
        margin: 0 0 0 rem-calc(20);

        li {
            margin: rem-calc(10) 0 0;
        }
    }
}

@mixin t-twocol-list {
    @include respond-to(no-phones) {
        column-count: 2;
    }
}

@mixin t-unordered-list {
    @include t-common_list_styles;

    li {
        &::before {
            content: '';
            top: rem-calc(8);
            left: 0;
            width: rem-calc(4);
            height: rem-calc(2);
            background-color: $primary-color;
            opacity: 0.5;
        }
    }
}

@mixin t-ordered-list {
    @include t-common_list_styles;

    counter-reset: list1;

    li::before {
        content: counter(list1) '. ';
        top: 0;
        left: rem-calc(2);
        counter-increment: list1;
    }

    ol {
        counter-reset: list2;

        li::before {
            content: counter(list2) '. ';
            counter-increment: list2;
        }
    }
}
