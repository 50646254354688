.b-memberson-error {
    @include error_message;
}

.b-account_form-column-email-domain {
    position: relative;
    margin-bottom: 0;
}

// Memberson
#consentPopUpModal .rewards-modal {
    max-width: 22rem;

    div#mainBody {
        padding: 2.2rem;
        text-align: center;
        margin-top: 10%;
    }

    img.rewards {
        width: 184px;
    }

    p.rewards-title {
        font-weight: 600;
        font-size: 17px;
        margin: 10px auto 23px;
        padding: 0 11px;
    }

    p.joinnow-details {
        color: black;
        text-align: left;
        font-weight: 500;
        font-size: 13px;
        line-height: 1.3;
    }

    a.joinnow-btn {
        color: black;
    }

    .btn-group {
        justify-content: space-between;
        display: flex;
        margin-top: 15px;
    }

    .rewards-btn {
        padding: 0;
        max-width: 118px;
        min-height: 28px;
    }

    div#primaryBody {
        padding: 2.5rem;
    }

    h4.leaverewards-title {
        padding: 0 29px;
        font-size: 21px;
        font-weight: 700;
        line-height: 1.3;
    }

    p.declineinfo-ua {
        font-size: 12px;
        margin-top: 7px;
    }

    #primaryBody {
        .modal-footer {
            margin-top: rem-calc(16);

            button {
                margin-bottom: rem-calc(13);
            }
        }
    }

    .leaveuareward-text {
        font-weight: bold;
        font-size: 0.75rem;
        margin: 8px auto 13px;
        display: block;
        color: $black;
    }
}

.b-memberson-error {
    @include error_message;
}

.memberson-underarmour-employee {
    font-size: rem-calc(14);
    padding-top: rem-calc(12);
    padding-left: rem-calc(12);
    text-align: left;
}

// Ageverification popUp
#ageConfimationModal .ageConfimationModalBody {
    max-width: 22rem;
    text-align: center;
    margin: 0 auto;

    #ageConfirmationContent {
        font-weight: 500;
        font-size: 13px;
    }

    .modal-footer {
        justify-content: space-between;
        display: flex;
        margin-top: 15px;

        button.returnButton,
        a.cancelAgeConfirmation-text {
            padding: 0;
            max-width: 118px;
            min-height: 28px;
        }
    }
}

// profile validation font style popUp
#loginModal,
.js-login-panel {
    .validateMsg {
        font-weight: 500;
        font-size: 13px;

        a.login-link {
            color: black;
        }
    }

    .naverBtn {
        margin-bottom: rem-calc(0);
        display: block;
        width: fit-content;
    }

    .top-text {
        margin: 0 0 rem-calc(12);
    }
}

#login {
    .register-asset {
        display: none;
    }
}

#register {
    .login-asset {
        display: none;
    }
}

.b-login-forminfo {
    .b-registerlink-login {
        span {
            color: $black;
            font-size: rem-calc(14);
        }

        a {
            font-size: rem-calc(14);
        }
    }
}

.naver-oauth-error-login,
.naver-oauth-error-registration {
    p {
        @include font(main, rem-calc(14), 1.43);

        margin-bottom: rem-calc(16);
    }

    .naver-error-buttons {
        display: flex;
        justify-content: space-between;

        .naver-trigger-registration {
            flex-basis: 62%;
            max-width: 62%;
        }

        .close-btn {
            flex-basis: 35%;
            max-width: 35%;
            border-color: $black;
        }
    }
}

.naver-oauth-error-registration .naver-error-buttons .close-btn {
    border-color: $grey3;
}
