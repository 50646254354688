.b-product_info {
    .egiftcard-range {
        font-size: rem-calc(14);
    }
}

.b-modal {
    &_block {
        .l-style_name {
            font-size: rem-calc(14);
        }
    }
}
