.b-product_attribute {
    &.m-size {
        width: 100%;
    }
}

.b-size_pdp {
    &.b-size_outer {
        position: relative;
    }

    .b-select-size-outer {
        ul {
            display: flex;
            flex-wrap: wrap;
            align-items: flex-end;
            margin: 0 0.1875rem;
            margin-left: rem-calc(0);
            cursor: default;

            li {
                a {
                    @include button-to-link;
                    @include swatch-text;
                    @include swatch-text-type;

                    color: $black;
                    text-decoration: none;
                    margin: calc(spacing(xs) / 2);
                    text-align: center;
                    font-weight: $font-regular;
                    vertical-align: middle;
                    border-radius: rem-calc(4);
                    margin-left: rem-calc(0);
                    margin-right: rem-calc(16);
                    align-items: center;
                    line-height: 1;

                    &:hover {
                        border-color: $black;
                        font-size: rem-calc(12);
                        font-weight: $font-regular;
                    }

                    &.selected {
                        border: 2px solid $black;
                        line-height: 1;

                        &::after {
                            content: none;
                        }
                    }

                    &.disabled {
                        position: relative;
                        border-color: $grey3;
                        color: $grey5;
                        cursor: pointer;
                    }

                    &.selected.disabled {
                        border: 2px solid $black;

                        &::before {
                            background: linear-gradient(to top right, rgba(0, 0, 0, 0) 0%, rgba(0, 0, 0, 0) calc(50% - 0.8px), rgb(0, 0, 0) 50%, rgba(0, 0, 0, 0) calc(50% + 0.8px), rgba(0, 0, 0, 0) 100%);
                        }
                    }
                }
            }
        }
    }
}

.b-size,
.b-color {
    &-more_less {
        text-align: unset;
        display: none;

        .l-show-less {
            font-size: rem-calc(12);
            font-weight: $font-medium;
            display: none;
            cursor: pointer;
            color: $grey5;

            &.show {
                display: initial;
            }

            &.showMobile {
                display: block;
            }

            &:hover {
                text-decoration: underline;
            }

            &:focus {
                color: $black;
            }
            @include respond-to(mobile) {
                text-decoration: underline;
            }
        }

        .l-show-more {
            font-size: rem-calc(12);
            font-weight: $font-medium;
            cursor: pointer;
            color: $grey5;

            &.hide {
                display: none;
            }

            &.hideMobile {
                display: none;
            }

            &:hover {
                text-decoration: underline;
            }

            &:focus {
                color: $black;
            }

            @include respond-to(mobile) {
                text-decoration: underline;
            }
        }
    }
}

.b-swatches_circle,
.b-swatches_sizes {
    li {
        @include respond-to(desktop) {
            &.hideNow {
                display: none;

                &.showNow {
                    display: block;
                }
            }
        }

        @include respond-to(mobile) {
            &.hideNowMobile {
                display: none;

                &.showNowMobile {
                    display: block;
                }
            }
        }
    }
}

.b-size_text-callout {
    font-size: rem-calc(12);
    margin-bottom: 1.5rem;

    .content-asset {
        @include icon(alert);

        &::before {
            margin-right: rem-calc(5);
            vertical-align: top;
        }
    }

    &.b-promo {
        margin-bottom: 1rem;

        .content-asset {
            &::before {
                vertical-align: top;
            }

            a {
                color: $black;
                text-decoration: underline;
            }
        }
    }
}

.b-product_promoCallout {
    flex: 1 1 100%;

    &.b-is_egiftcard {
        flex: 1 1 84%;
        align-items: end;
        display: flex;

        .b-product-promo {
            @include respond-to(desktop) {
                margin-bottom: 0 !important;
            }
        }
    }

    .b-product_text-callout {
        font-size: rem-calc(12);
        margin-bottom: 1.5rem;
        color: $grey5;
        flex: 1 1 100%;
        text-align: left;

        .content-asset {
            @include icon(alert);

            &::before {
                margin-right: rem-calc(5);
                @include respond-to(desktop) {
                    vertical-align: top;
                }
            }
        }

        &.b-product-promo {
            @include respond-to(desktop) {
                margin-bottom: 1rem;
            }

            @include respond-to(mobile) {
                padding: 0;
                border: 0;
            }

            .content-asset {
                a {
                    color: $black;
                    text-decoration: underline;
                }
            }
        }
    }
}

.b-size_top {
    &-labels {
        @include respond-to(mobile) {
            display: inline-grid;
        }

        .l-select_label {
            font-size: rem-calc(12);
        }

        .l-size_selected-label {
            display: inline-block;
            font-size: rem-calc(12);
            font-weight: $font-regular;
            padding: 0 0 rem-calc(5) rem-calc(3);
        }
    }
}
