.b-country,
.b-address,
.b-gender,
.b-email,
.b-phone,
.b-month,
.b-day,
.b-year,
.b-state,
.b-store,
.b-colony,
.b-order-reason,
.b-phone1 {
    @include icon('caret-down', 'before', 16, rem-calc(16));

    position: relative;
    flex-grow: 1;

    &::before {
        position: absolute;
        top: rem-calc(22);
        right: rem-calc(22);
        transform: translateY(-50%);
        pointer-events: none;
    }

    &-label {
        position: absolute;
        top: 0;
        left: rem-calc(12);
        z-index: 4;
        padding: 0 rem-calc(4);
        background-color: $white;
        font-size: rem-calc(11);
        transform: translateY(-50%);
    }

    &-select {
        width: 100%;
        height: rem-calc(44);
        padding: 0 rem-calc(40) 0 rem-calc(16);
        box-sizing: border-box;
        border: 1px solid $grey3;
        border-radius: rem-calc(4);
        line-height: rem-calc(44);
        appearance: none;
        cursor: pointer;
        background-color: $white;
        color: inherit;
        @include font(main_med, rem-calc(12), 1.67);

        &.is-invalid {
            border-color: $red;
        }
    }
}

.b-country {
    margin-bottom: rem-calc(20);
}

.m-top {
    @include respond-to(mobile) {
        margin-top: 0.5rem;
    }
}
