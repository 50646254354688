.b-footer_signup {
    margin-bottom: rem-calc(25);
    @include respond-to(tablets-landscape) {
        padding-right: rem-calc(30);
    }

    .b-logo-image {
        width: auto;
        display: flex;
        align-items: center;

        @include icon(mail);

        h2 {
            display: inline-block;
            font-size: rem-calc(16);
            font-weight: 700;
        }

        &::before {
            font-size: rem-calc(40);
            margin-right: rem-calc(15);

            @include respond-to(mobile) {
                font-size: rem-calc(32);
                margin-right: rem-calc(10);
            }
        }
    }

    &-title {
        @include font(main_med, rem-calc(16));

        margin-bottom: rem-calc(15);
    }

    &-input {
        padding-right: rem-calc(72);
        background-color: $grey2;
        box-shadow: none;
        -webkit-appearance: none;
        -moz-appearance: none;
        appearance: none;
        border: 1px solid $boulder;
        border-radius: 2px;

        &.is-invalid {
            border: 1px solid $red;
        }
    }

    &-container {
        margin-bottom: rem-calc(25);
        position: relative;

        @include respond-to(mobile) {
            margin-bottom: rem-calc(7);
        }

        .b-input_row-label {
            background: $grey2;
        }

        &.data-success {
            margin-left: 0;
            font-size: 14px;
            font-weight: 400;

            @include respond-to(mobile) {
                margin-left: rem-calc(0);
            }
        }
    }

    &-button {
        @include font(main_med);

        background: none;
        border: none;
        height: rem-calc(44);
        padding-right: rem-calc(15);
        position: absolute;
        right: 0;
        top: 0;
        font-size: rem-calc(11);
        color: inherit;

        .ios & {
            line-height: 3rem;
        }
    }

    &-description {
        color: $grey5;
    }
}
