@mixin swatch-text {
    position: relative;
    display: inline-flex;
    align-items: center;
    justify-content: center;
    padding-right: rem-calc(8);
    padding-left: rem-calc(8);
    border: solid 1px $grey3;
    border-radius: rem-calc(3);
    overflow: hidden;

    &:hover,
    &:active,
    &.active,
    &.m-active {
        border-color: $black;
    }

    &:disabled,
    &.disabled,
    &.m-disabled {
        cursor: default;

        &::before {
            content: '';
            width: 100%;
            height: 100%;
            background: linear-gradient(to top right, rgba(0, 0, 0, 0) 0%, rgba(0, 0, 0, 0) calc(50% - 0.8px), rgb(208, 208, 208) 50%, rgba(0, 0, 0, 0) calc(50% + 0.8px), rgba(0, 0, 0, 0) 100%);
            display: block;
            position: absolute;
            top: 0;
            left: 0;
        }

        &:hover,
        &:active {
            border-color: $black;

            &::before {
                background: linear-gradient(to top right, rgba(0, 0, 0, 0) 0%, rgba(0, 0, 0, 0) calc(50% - 0.8px), rgb(0, 0, 0) 50%, rgba(0, 0, 0, 0) calc(50% + 0.8px), rgba(0, 0, 0, 0) 100%);
            }
        }
    }
}

@mixin swatch-text-type($size: lg, $type: text) {
    padding-top: rem-calc(6);
    padding-bottom: rem-calc(6);

    @if ($type == text) {
        @include t-text-2;

        height: rem-calc(32);
        min-width: rem-calc(72);

        @if ($size == sm) {
            width: rem-calc(40);
            max-width: rem-calc(40);
        }
    } @else {
        width: rem-calc(64);
        height: rem-calc(40);
        max-width: rem-calc(64);
    }
}
