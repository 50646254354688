.b-login-register {
    &_screen {
        width: 100%;
        max-width: rem-calc(448);
        margin: rem-calc(56) auto rem-calc(48);
        padding: 0 rem-calc(16);

        @include respond-to(mobile) {
            max-width: 100%;
            margin: rem-calc(24) auto rem-calc(42);
        }

        .register-container {
            .g-password-modal-close {
                display: none;
            }
        }

        .b-return-wrap {
            &.registration.account-form {
                .dialing-code {
                    @include respond-to(desktop) {
                        width: 30%;
                    }
                }

                .register-mobile-number {
                    @include respond-to(desktop) {
                        width: 64%;
                    }
                }

                .b-account_form-row-three {
                    .b-account_form-column {
                        @include respond-to(desktop) {
                            flex: 1;
                        }
                    }

                    .b-account_form-column:first-child {
                        @include respond-to(desktop) {
                            flex: 1.5;
                        }
                    }
                }
            }

            .password-display {
                font-size: rem-calc(12);
            }

            .form-group {
                input {
                    cursor: auto;
                }
            }
        }

        .b-info-container {
            @include font(main, rem-calc(12), 1.67);

            .register-policy {
                margin-top: rem-calc(12);
            }
        }
    }
}
