.b-cartlineitem {
    border-top: 1px solid $grey3;
    padding: rem-calc(32) 0;

    @include respond-to(mobile) {
        padding: rem-calc(16) 0 rem-calc(24);
    }

    &.cart-savelater-product {
        @include respond-to(mobile) {
            padding: rem-calc(16) 0 rem-calc(24);

            .btn-savedItems-section {
                margin-top: rem-calc(8);
            }
        }
    }

    .egiftcard-value {
        text-transform: none;
    }

    .error-handling {
        margin-top: rem-calc(8);
        background: $grey2;
        @include t-text-2;

        padding: rem-calc(16);
        border-radius: rem-calc(4);

        span {
            vertical-align: middle;
        }

        &-text {
            margin-left: rem-calc(5);
        }

        &-icon {
            @include icon(alert);
        }

        &-close {
            @include icon(exit);

            background: transparent;
            border: 0;
            float: right;
            margin-top: rem-calc(3);
            cursor: pointer;
        }
    }

    &.first {
        border-top: 0;
    }

    &_image {
        max-width: rem-calc(208);

        &-outofstock {
            position: relative;

            &::after {
                content: '';
                width: 100%;
                height: 100%;
                position: absolute;
                top: 0;
                left: 0;
                background: $athens-gray;
            }

            &:not(.product_tile-noimage)::after {
                background: $white;
                opacity: 0.5;
            }

            .outofstock-message {
                @include t-text-oos;

                width: calc(100% - 40px);

                @include respond-to(mobile) {
                    width: calc(100% - 20px);
                }
            }
        }

        a {
            @include respond-to(mobile) {
                display: inline-block;
            }
        }

        @include respond-to(mobile) {
            max-width: 100%;
            width: rem-calc(104);
            display: inline-block;
            vertical-align: top;
        }
    }

    &_details {
        max-width: rem-calc(448);
        width: 100%;
        margin-left: rem-calc(16);

        @include respond-to(desktop) {
            max-width: 63%;
            flex: 1 1 70%;
        }

        @include respond-to(mobile) {
            max-width: 100%;
            width: calc(100% - 124px);
            display: inline-block;
        }

        .line-item-promo {
            color: $green;
        }
    }

    &_quantity-update {
        color: $grey5;
        margin-right: rem-calc(16);
        text-transform: lowercase;
        @include font(main_med, rem-calc(12), 1.67);

        @include respond-to(desktop) {
            flex-basis: 40%;
        }
    }

    &_price {
        .b-productcard_total_price {
            .b-promo-product-level {
                color: $green;
                font-size: rem-calc(12);
            }
        }

        @include respond-to(desktop) {
            width: 30%;
            text-align: right;
            justify-content: flex-end;

            .b-productcard_total_price {
                flex-basis: 60%;
            }
        }

        @include respond-to(mobile) {
            display: none;
        }

        &.price-mobile {
            @include respond-to(desktop) {
                display: none;
            }
            @include respond-to(mobile) {
                display: flex;
                margin: rem-calc(16) 0 rem-calc(10);

                .b-price {
                    display: flex;
                    margin: rem-calc(5) 0;

                    .b-price-value.m-strikethrough {
                        margin-right: rem-calc(5);
                    }
                }

                .price {
                    display: flex;
                }
            }
        }

        &.gcpricemobile {
            margin: 0;
        }
    }

    &_remove_edit {
        @include respond-to(desktop) {
            text-align: right;
            display: inline-block;
            width: 100%;
            position: absolute;
            bottom: 0;
            right: 0;
        }
    }

    &_modelname {
        @include font(main, rem-calc(10), 1.6);
    }

    &_attributes {
        @include font(main, rem-calc(10), 1.6);

        color: $grey5;
    }

    .b-quantity {
        &::before {
            font-size: rem-calc(11.2);
        }
    }

    .b-price {
        margin-right: 0;
        margin-top: 0;
        display: inline-block;

        &-value {
            @include font(main, rem-calc(12), 1.67);

            color: $grey5;
            display: block;

            &.m-strikethrough {
                margin-right: 0;
            }
        }
    }

    &_savedItem_price {
        .b-price-value {
            color: $black;
        }
    }

    &_togetherbutton,
    &_savedItems_togetherbutton {
        width: auto;
        float: right;
        display: flex;
        justify-content: space-between;
        align-items: end;

        @include respond-to(mobile) {
            float: right;
            width: auto;
        }

        .b-product_edit,
        .b-product_savelater {
            float: left;
            padding-right: rem-calc(32);
        }

        .b-product_edit {
            float: left;
        }

        .remove-product-button {
            @include font(main_med, rem-calc(12), 1.67);

            display: block;
            border: 0;
            background: transparent;
            color: $grey5;
            cursor: pointer;

            @include respond-to(mobile) {
                line-height: 1.2;

                span {
                    line-height: 1.2;
                }
            }
        }

        .edit-link,
        .save-later {
            @include font(main_med, rem-calc(12), 1.67);

            display: block;
            color: $grey5;
            text-decoration: none;

            @include respond-to(mobile) {
                line-height: 1.2;
            }
        }
    }

    &_shipments {
        @include respond-to(desktop) {
            flex: 1 1 60%;
            max-width: 60%;
        }

        @include respond-to(mobile) {
            margin-bottom: rem-calc(24);
        }

        .b-store-choose-link {
            margin: rem-calc(12) 0 0;

            @include respond-to(mobile) {
                margin: rem-calc(28) 0 0;
            }

            .b-store-choose-content {
                padding: 0;
                border: 0;
                line-height: inherit;
            }

            .b-choose-store {
                text-decoration: underline;
                margin-left: rem-calc(8);
            }
        }

        .b-product_listcard {
            display: flex;
        }

        .b-product_list-input {
            position: relative;
            z-index: -1;
            width: rem-calc(16);
            height: rem-calc(16);

            .g-radio-label {
                position: initial;
                padding-left: 0;

                &::before {
                    top: 50%;
                    left: 50%;
                    transform: translate(-50%, -50%);
                }
            }
        }

        .b-product_list-label {
            display: flex;
            padding-left: rem-calc(16);

            .label-text {
                margin-left: rem-calc(8);
            }
        }

        img {
            width: rem-calc(16);
            height: rem-calc(16);
        }

        a,
        .label-text {
            color: inherit;
            text-decoration: none;
            display: inline-block;
            width: 100%;
            text-align: left;
            position: relative;
            @include font(main, rem-calc(14), rem-calc(22));

            @media not all and (min-resolution: 0.001dpcm) {
                @supports (-webkit-appearance: none) {
                    line-height: rem-calc(16);
                }
            }

            @include respond-to(mobile) {
                margin-right: rem-calc(8);
            }

            &.disabled {
                pointer-events: none;

                .b-product_list-input,
                img {
                    opacity: 0.6;
                }

                .store-label-text {
                    color: $grey4;
                }

                .btn-get-in-store-inventory {
                    pointer-events: auto;
                    color: $grey5;
                }
            }

            &.b-store-heading {
                width: auto;
                color: $black;
            }
        }

        .anchor-link {
            &.selected {
                pointer-events: none;
            }
        }

        .store-label-text {
            color: $grey5;

            .underline {
                text-decoration: underline;
            }

            .highlight {
                font-weight: $font-semi-bold;
                color: $green;
            }

            .store-availability-message {
                @include font(main, rem-calc(12), 1.67);
            }
        }
    }

    .line-item-promo {
        @include font(main, rem-calc(11), 1.6);

        max-width: rem-calc(302);
        bottom: 0;
        color: $green;

        @include respond-to(mobile) {
            display: none;
        }

        &.not-applied {
            @include font(main, rem-calc(12), 1.6);

            color: $black;

            .line-item-no-promo {
                @include icon(alert);

                &::before {
                    margin-right: rem-calc(5);
                    vertical-align: middle;
                }
            }
        }
    }

    .b-header_minicart {
        &-check-icon {
            display: inline-block;
            margin-right: rem-calc(18);
            vertical-align: middle;
            float: left;

            @include respond-to(mobile) {
                margin-bottom: rem-calc(8);
            }
        }

        &-item-check {
            padding: rem-calc(22) rem-calc(16);

            @include respond-to(mobile) {
                padding: rem-calc(16);
            }

            .line-item-attributes {
                display: inline;
                vertical-align: middle;
            }
        }

        &_content {
            display: flex;
            justify-content: space-between;
            align-items: center;

            @include respond-to(mobile) {
                flex-wrap: wrap;
            }

            &_message {
                @include respond-to(mobile) {
                    flex-basis: 100%;
                    display: flex;
                }

                .b-header_minicart-check-icon {
                    margin-right: rem-calc(8);
                }
            }

            &_togetherbutton {
                display: flex;
                align-items: center;

                @include respond-to(desktop) {
                    flex-basis: 70%;
                    max-width: rem-calc(250);
                }

                @include respond-to(phones) {
                    flex-basis: 100%;
                }

                @include respond-to(mobile) {
                    justify-content: inherit;
                    margin-top: rem-calc(14);
                }

                .remove-product-item {
                    @include font(main, rem-calc(12), 1.67);

                    font-weight: 600;
                    color: $grey5;
                    border: none;
                    margin-right: rem-calc(16);
                    cursor: pointer;
                }

                .b-product_savelater {
                    a {
                        padding: rem-calc(10) rem-calc(15);
                        display: inline-block;
                    }
                }
            }
        }
    }

    .b-header_minicart-item-check {
        display: inline-block;
        width: 100%;

        @include respond-to(mobile) {
            margin-top: rem-calc(10);
        }
    }

    &_outofstock {
        .b-cartlineitem_row {
            opacity: 0.5;
            pointer-events: none;
            cursor: none;

            .b-cartlineitem_details {
                @include respond-to(mobile) {
                    margin-bottom: rem-calc(10);
                }
            }
        }
    }

    &_row {
        width: 100%;
        position: relative;
        display: inline-block;

        @include respond-to(desktop) {
            display: flex;
        }

        .b-urgent-message {
            @include font(main_med, rem-calc(12), 1.5);

            color: $red;
            margin-bottom: -12px;
            display: block;
        }

        .line-item-availability {
            display: none;
        }
    }
}

.b-lineitem {
    &_itemname {
        @include font(main_med, rem-calc(14), 1.67);

        margin-bottom: rem-calc(8);
        text-transform: capitalize;
        text-decoration: none;
        color: $black;
    }
}

.t-lineitem {
    &_attributes {
        @include font(main, rem-calc(12), 1.6);

        color: $grey5;
        text-transform: capitalize;
    }
}

.b-cart-content {
    .b-productcard_footer {
        margin-top: rem-calc(16);
        width: rem-calc(152);
        margin-bottom: rem-calc(16);

        .b-productcard_item-preorder {
            margin: rem-calc(16) 0 0 0;

            &-mask {
                @include font(main_med, rem-calc(10), rem-calc(14));

                color: $green;
            }
        }
    }

    .b-productcard_item_price {
        display: inline-block;
        color: $grey5;

        .line-item-price-info {
            text-transform: lowercase;
            margin-right: rem-calc(16);
            display: inline-block;
            margin-bottom: 0;
        }
    }

    .b-productcard_total_price {
        display: inline-block;

        .bfx-price {
            margin-left: 5px;
            @include respond-to(mobile) {
                margin-left: 0;
            }
        }

        span:not(.hide-desktop):not(.hide-mobile):not(.b-promo-tooltip-content-text) {
            display: inline-block;
            @include font(main_med, rem-calc(12), 1.67);

            @include respond-to(mobile) {
                display: block;
            }

            &.highlighted {
                color: $red !important;
            }

            &.strike-through,
            &.m-strikethrough {
                text-decoration: line-through;
                margin-right: rem-calc(5);
                @-moz-document url-prefix('') {
                    position: relative;
                    white-space: nowrap;
                    text-decoration: none;

                    &::after {
                        border-top: 1px solid $black;
                        position: absolute;
                        content: '';
                        right: 0;
                        top: 40%;
                        left: 0;
                    }

                    &.mac-strikethrough {
                        &::after {
                            top: 50%;
                        }
                    }
                }

                @supports (-ms-ime-align:auto) {
                    position: relative;
                    white-space: nowrap;
                    text-decoration: none;

                    &::after {
                        border-top: 1px solid $black;
                        position: absolute;
                        content: '';
                        right: 0;
                        top: 40%;
                        left: 0;
                    }

                    &.mac-strikethrough {
                        &::after {
                            top: 50%;
                        }
                    }
                }
            }

            @media screen and (-ms-high-contrast: active), (-ms-high-contrast: none) {
                &.strike-through {
                    position: relative;
                    white-space: nowrap;
                    text-decoration: none !important;

                    &::after {
                        border-top: 1px solid $black;
                        position: absolute;
                        content: '';
                        right: 0;
                        top: 40%;
                        left: 0;
                    }
                }
            }

            @supports (-ms-ime-align:auto) {
                &.strike-through {
                    position: relative;
                    white-space: nowrap;
                    text-decoration: none !important;

                    &::after {
                        border-top: 1px solid $black;
                        position: absolute;
                        content: '';
                        right: 0;
                        top: 40%;
                        left: 0;
                    }
                }
            }
        }

        &.gcprice {
            @include respond-to(mobile) {
                margin-bottom: 1.5rem;
            }
        }
    }
}

.b-promoline {
    &_mobile {
        display: none;

        @include respond-to(mobile) {
            @include font(main, rem-calc(10), 1.6);

            color: $green;
            display: block;
            width: 100%;

            .b-promo-product-level,
            .line-item-no-promo {
                margin-top: rem-calc(8);
                margin-bottom: rem-calc(16);
            }
        }

        &.not-applied {
            @include font(main, rem-calc(12), 1.6);

            color: $black;

            .line-item-no-promo {
                @include icon(alert);

                &::before {
                    margin-right: rem-calc(5);
                    vertical-align: middle;
                }
            }
        }
    }
}

.line {
    &-item-price-info {
        width: auto;
        display: inline-block;
    }
}
