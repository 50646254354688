.b-refinements_price {
    &-btn {
        @include button-to-link;
        @include link;

        width: 100%;
        text-align: left;
        margin-bottom: spacing(xs2);

        &:hover {
            font-weight: 500;
        }

        &.m-selected {
            @include font(main_med);

            color: $black;
        }
    }
}
