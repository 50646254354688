#gcApplyAction {
    &:disabled {
        cursor: not-allowed;
        pointer-events: auto;
        background-color: $black;
        border-color: $black;
    }
}

#gcNumber,
#gcPin {
    &:focus {
        border-color: #1d1d1d;
    }

    &.is-invalid {
        &:focus {
            border-color: #dc0019;
        }
    }
}

#gcPinWrapper {
    &.b-account_form-column {
        .b-input_row {
            @include respond-to(mobile) {
                margin-bottom: rem-calc(16);
            }
        }
    }
}

.gift-card-payment {
    padding-bottom: 1.5rem;
    border-bottom: 1px solid #d0d0d0;
    margin-bottom: 2rem;
}

.s-giftcard {
    .s-giftcard__heading {
        flex-basis: 23%;
        @include respond-to(mobile) {
            margin-bottom: 1.4375rem;
            height: 40px;
        }
    }

    .s-giftcard__logo-apac {
        img {
            height: rem-calc(22);
            margin-left: 1rem;
            border-color: #190c0c;
            cursor: pointer;
            padding-bottom: rem-calc(0.5);

            @include respond-to(mobile) {
                height: rem-calc(22);
                padding-bottom: rem-calc(2.5);
            }

            &::before {
                color: #5f5f5f;
            }

            &:hover {
                opacity: unset;
            }
        }
    }

    .g-checkbox {
        @include respond-to(desktop) {
            &:hover {
                .g-checkbox-label,
                .s-giftcard__logo,
                .g-checkbox-input {
                    opacity: unset;
                    color: #1d1d1d;
                }

                .g-checkbox-label {
                    &::after {
                        border: 1px solid #1d1d1d;
                    }
                }

                .s-giftcard__logo {
                    &::before {
                        opacity: unset;
                        color: #1d1d1d;
                    }
                }
            }
        }
    }

    .g-checkbox-label {
        padding-left: 1.938rem;
        line-height: 1rem;
        color: #5f5f5f;

        @include respond-to(desktop) {
            padding-top: rem-calc(0.5);
        }

        &::after {
            border: 1px solid #5f5f5f;
            @include respond-to(desktop) {
                padding-top: rem-calc(8);
            }
        }

        &:hover {
            @include respond-to(mobile) {
                opacity: unset;
            }
        }
    }

    .s-giftcard__checkbox {
        max-width: max-content;
        @include respond-to(desktop) {
            display: flex;
            align-items: center;
        }

        input[type=checkbox]:checked ~ label {
            color: #1d1d1d;

            &::after {
                border: 1px solid #1d1d1d;
            }
        }

        input[type=checkbox]:checked ~ .s-giftcard__logo::before {
            color: #1d1d1d;
        }
    }

    .s-giftcard-rfholder {
        @include respond-to(mobile) {
            margin-bottom: 12px;
        }
    }

    .error-info {
        @include respond-to(mobile) {
            line-height: 2.8rem;
        }
    }

    .gcButton {
        @include respond-to(mobile) {
            margin-top: rem-calc(8);
        }
    }
}

.b-payment-tab {
    background-color: $grey1;
    padding: 1rem 2rem;
    margin-bottom: rem-calc(22);

    .applepay-tab-wrapper {
        .b-payment-accordion-head {
            &.g-accordion-header {
                &.collapsed {
                    @include respond-to(mobile) {
                        display: none;

                        &::after {
                            display: none;
                        }
                    }
                }
            }
        }
    }

    .tab-pane {
        &#applepay-content {
            &.mac-only {
                @include respond-to(mobile) {
                    border-bottom: 1px solid $grey3;
                }

                .b-payment-accordion-head {
                    &.g-accordion-header {
                        @include respond-to(mobile) {
                            display: block;
                        }
                    }
                }
            }
        }
    }

    .paymentMethod {
        display: inline-block;
        width: 100%;
        margin: rem-calc(16) 0 rem-calc(16) 0;

        .additionalFields {
            margin: 0;
        }

        input[type='radio'] {
            margin-right: rem-calc(10);
        }

        .paymentMethod_img {
            display: none;
        }

        &.klarna,
        &.klarna_account,
        &.directEbanking {
            .pay-image-wrapper {
                background: url('../images/cards/klarna.svg') no-repeat;
                width: rem-calc(46);
                height: rem-calc(11);
            }
        }

        &.dotpay {
            .pay-image-wrapper {
                background: url('../images/cards/dotpay.png') no-repeat;
                width: rem-calc(77);
                height: rem-calc(27);
                margin-bottom: rem-calc(2);
            }
        }

        &.bcmc {
            .pay-image-wrapper {
                background: url('../images/cards/bancontact.svg') no-repeat;
                width: rem-calc(46);
                height: rem-calc(33);
            }
        }

        &.giropay {
            .pay-image-wrapper {
                background: url('../images/cards/giropay.svg') no-repeat;
                width: rem-calc(43);
                height: rem-calc(21);
            }
        }

        &.paypal {
            .pay-image-wrapper {
                background: url('../images/cards/payPal.svg') no-repeat;
                width: rem-calc(53);
                height: rem-calc(15);
            }

            .additionalFields {
                margin-top: rem-calc(15);
            }
        }

        &.twint {
            .pay-image-wrapper {
                background: url('../images/cards/twint.svg') no-repeat;
                width: rem-calc(54);
                height: rem-calc(18);
            }
        }

        &.mbway {
            .pay-image-wrapper {
                background: url('../images/cards/mbway.svg') no-repeat;
                width: rem-calc(43);
                height: rem-calc(21);
            }
        }

        &.mobilepay {
            .pay-image-wrapper {
                background: url('../images/cards/mobilepay.svg') no-repeat;
                width: rem-calc(55);
                height: rem-calc(18);
            }
        }

        &.eps {
            .pay-image-wrapper {
                background: url('../images/cards/eps.svg') no-repeat;
                width: rem-calc(40);
                height: rem-calc(25);
            }
        }

        &.ratepay {
            .pay-image-wrapper {
                background: url('../images/cards/ratepay.svg') no-repeat;
                width: rem-calc(60);
                height: rem-calc(23);
            }
        }

        .pay-image-wrapper {
            float: right;
            display: block;
        }
    }
}

.b-cart-content {
    .b-productcard_total_price {
        span.bfx-original-price.non-adjusted-price {
            @include respond-to(mobile) {
                display: block;
                margin: 0;
            }
        }
    }
}

.b-cartlineitem_right-content {
    @include respond-to(desktop) {
        display: flex;
        flex-wrap: wrap;
        flex: 1 1 calc(100% - 13rem);
        max-width: calc(100% - 13rem);
        margin-left: rem-calc(16);
    }

    .line-item-no-promo {
        @include respond-to(desktop) {
            margin-top: 1rem;
        }
    }
}

.b-cartlineitem {
    .line-item-promo {
        max-width: rem-calc(265);
    }
}

ul {
    .m-show {
        li {
            &.h-hide-only-mobile {
                @include respond-to(mobile) {
                    display: none;
                }
            }
        }
    }
}
