/* md

# Pagination
If you need it could be added to different types of HTML-elements

```html
    <nav aria-label="pagination" role="navigation">
        <ul class="g-pagination">
        <li class="g-pagination-item g-pagination-item--previous-page">
            <a class="g-pagination-link g-pagination-link m-disabled" href="#" aria-disabled="true" aria-label="Go to Previous Page">
            < <span class="visuallyhidden">
                Previous
            </span>
            </a>
        </li>
        <li class="g-pagination-item">
            <a class="g-pagination-link" href="#" aria-label="Go to Page 1">1</a>
        </li>
        <li class="g-pagination-item">
            <a class="g-pagination-link" href="#" aria-label="Go to Page 2">2</a>
        </li>
        <li class="g-pagination-item">
            <a class="g-pagination-link" href="#" aria-label="Go to Page 3">3</a>
        </li>
        <li class="g-pagination-item">
            <a class="g-pagination-link" href="#" aria-label="Go to Page 4">4</a>
        </li>
        <li class="g-pagination-item g-pagination-item">
            <a class="g-pagination-link" href="#" aria-current="page" aria-label="Go to Page 5">5</a>
        </li>
        <li class="g-pagination-item g-pagination-item--next-page g-pagination-item m-disabled">
            <a class="g-pagination-link" href="#" aria-disabled="true" aria-label="Go to Next Page"> >
            <span class="visuallyhidden">
                Next
            </span>
            </a>
        </li>
        </ul>
    </nav>
```
# Pagination
```html_example
    <nav aria-label="pagination" role="navigation">
        <ul class="g-pagination">
        <li class="g-pagination-item g-pagination-item--previous-page">
            <a class="g-pagination-link g-pagination-link m-disabled" href="#" aria-disabled="true" aria-label="Go to Previous Page">
            < <span class="visuallyhidden">
                Previous
            </span>
            </a>
        </li>
        <li class="g-pagination-item">
            <a class="g-pagination-link" href="#" aria-label="Go to Page 1">1</a>
        </li>
        <li class="g-pagination-item">
            <a class="g-pagination-link" href="#" aria-label="Go to Page 2">2</a>
        </li>
        <li class="g-pagination-item">
            <a class="g-pagination-link" href="#" aria-label="Go to Page 3">3</a>
        </li>
        <li class="g-pagination-item">
            <a class="g-pagination-link" href="#" aria-label="Go to Page 4">4</a>
        </li>
        <li class="g-pagination-item">
            <a class="g-pagination-link m-active" href="#" aria-current="page" aria-label="Go to Page 5">5</a>
        </li>
        <li class="g-pagination-item g-pagination_item--next-page">
            <a class="g-pagination-link" href="#" aria-disabled="true" aria-label="Go to Next Page"> >
            <span class="visuallyhidden">
                Next
            </span>
            </a>
        </li>
        </ul>
    </nav>
```
*/

.g-pagination {
    display: inline-flex;

    &-item {
        height: rem-calc(24);
        min-width: rem-calc(40);
        padding-right: rem-calc(8);
        padding-left: rem-calc(8);
        text-align: center;

        &.m-disabled {
            display: none;
        }
    }

    &-link {
        @include font(main, rem-calc(14), rem-calc(24));

        position: relative;
        display: block;
        text-decoration: none;
        color: $grey5;

        &::before {
            content: '';
            position: absolute;
            right: 0;
            bottom: 0;
            left: 0;
            width: 100%;
            height: 1px;
        }

        &:hover,
        &:active,
        &.m-active {
            font-weight: $font-medium;
            color: $black;
        }

        &:active,
        &.m-active {
            &::before {
                background-color: $black;
            }
        }

        span {
            @include hide(text);
        }
    }
}

/* Dynamic Swiper Pagination */
.b-product_carousel-pdp {
    @include respond-to(mobile) {
        .swiper {
            margin-left: auto;
            margin-right: auto;
            position: relative;
            overflow: hidden;
            list-style: none;
            padding: 0;
            z-index: 1;
        }

        .swiper-vertical > .swiper-wrapper {
            flex-direction: column;
        }

        .swiper-wrapper {
            position: relative;
            width: 100%;
            height: 100%;
            z-index: 1;
            display: flex;
            transition-property: transform;
            box-sizing: content-box;
        }

        .swiper-android .swiper-slide,
        .swiper-wrapper {
            transform: translate3d(0, 0, 0);
        }

        .swiper-pointer-events {
            touch-action: pan-y;
        }

        .swiper-pointer-events.swiper-vertical {
            touch-action: pan-x;
        }

        .swiper-slide {
            flex-shrink: 0;
            width: 100%;
            height: 100%;
            position: relative;
            transition-property: transform;
        }

        .swiper-slide-invisible-blank {
            visibility: hidden;
        }

        .swiper-autoheight,
        .swiper-autoheight .swiper-slide {
            height: auto;
        }

        .swiper-autoheight .swiper-wrapper {
            align-items: flex-start;
            transition-property: transform, height;
        }

        .swiper-backface-hidden .swiper-slide {
            transform: translateZ(0);
            -webkit-backface-visibility: hidden;
            backface-visibility: hidden;
        }

        .swiper-horizontal.swiper-css-mode > .swiper-wrapper {
            scroll-snap-type: x mandatory;
        }

        .swiper-vertical.swiper-css-mode > .swiper-wrapper {
            scroll-snap-type: y mandatory;
        }

        :root {
            --swiper-navigation-size: rem-calc(44);
        }

        .swiper-button-next,
        .swiper-button-prev {
            position: absolute;
            top: 50%;
            width: calc(var(--swiper-navigation-size) / 44 * 27);
            height: var(--swiper-navigation-size);
            margin-top: calc(0 - (var(--swiper-navigation-size) / 2));
            z-index: 10;
            cursor: pointer;
            display: flex;
            align-items: center;
            justify-content: center;
            color: var(--swiper-navigation-color, var(--swiper-theme-color));
        }

        .swiper-button-next.swiper-button-disabled,
        .swiper-button-prev.swiper-button-disabled {
            opacity: 0.35;
            cursor: auto;
            pointer-events: none;
        }

        .swiper-button-next::after,
        .swiper-button-prev::after {
            font-family: swiper-icons;
            font-size: var(--swiper-navigation-size);
            text-transform: none !important;
            letter-spacing: 0;
            font-variant: initial;
            line-height: 1;
        }

        .swiper-button-prev,
        .swiper-rtl .swiper-button-next {
            left: 10px;
            right: auto;
        }

        .swiper-button-prev::after,
        .swiper-rtl .swiper-button-next::after {
            content: 'prev';
        }

        .swiper-button-next,
        .swiper-rtl .swiper-button-prev {
            right: 10px;
            left: auto;
        }

        .swiper-button-next::after,
        .swiper-rtl .swiper-button-prev::after {
            content: 'next';
        }

        .swiper-button-lock {
            display: none;
        }

        .swiper-pagination {
            position: absolute;
            text-align: center;
            transition: 0.3s opacity;
            transform: translate3d(0, 0, 0);
            z-index: 10;
        }

        .swiper-pagination.swiper-pagination-hidden {
            opacity: 0;
        }

        .swiper-horizontal > .swiper-pagination-bullets,
        .swiper-pagination-bullets.swiper-pagination-horizontal,
        .swiper-pagination-custom,
        .swiper-pagination-fraction {
            bottom: 10px;
            left: 0;
            width: 100%;
        }

        .swiper-pagination-bullets-dynamic {
            overflow: hidden;
            font-size: 0;
        }

        .swiper-pagination-bullets-dynamic .swiper-pagination-bullet {
            transform: scale(0.33);
            position: relative;
        }

        .swiper-pagination-bullets-dynamic .swiper-pagination-bullet-active-prev {
            transform: scale(0.66);
        }

        .swiper-pagination-bullets-dynamic .swiper-pagination-bullet-active-prev-prev {
            transform: scale(0.33);
        }

        .swiper-pagination-bullets-dynamic .swiper-pagination-bullet-active-next {
            transform: scale(0.66);
        }

        .swiper-pagination-bullets-dynamic .swiper-pagination-bullet-active-next-next {
            transform: scale(0.33);
        }

        .swiper-pagination-bullets-dynamic .swiper-pagination-bullet-active {
            transform: scale(1);
        }

        .swiper-pagination-bullets-dynamic .swiper-pagination-bullet-active-main {
            transform: scale(1);
        }

        .swiper-pagination-bullet {
            width: var(--swiper-pagination-bullet-width, var(--swiper-pagination-bullet-size, 6px));
            height: var(--swiper-pagination-bullet-height, var(--swiper-pagination-bullet-size, 6px));
            display: inline-block;
            border-radius: 50%;
            background: var(--swiper-pagination-bullet-inactive-color, #000);
            opacity: var(--swiper-pagination-bullet-inactive-opacity, 0.7);
        }

        button.swiper-pagination-bullet {
            border: none;
            margin: 0;
            padding: 0;
            box-shadow: none;
            -webkit-appearance: none;
            appearance: none;
        }

        .swiper-pagination-clickable .swiper-pagination-bullet {
            cursor: pointer;
        }

        .swiper-pagination-bullet:only-child {
            display: none !important;
        }

        .swiper-pagination-bullet-active {
            opacity: var(--swiper-pagination-bullet-opacity, 1);
            background: var(--swiper-pagination-color, var(--swiper-theme-color));
        }

        .swiper-pagination-vertical.swiper-pagination-bullets,
        .swiper-vertical > .swiper-pagination-bullets {
            right: 10px;
            top: 50%;
            transform: translate3d(0, -50%, 0);
        }

        .swiper-pagination-vertical.swiper-pagination-bullets .swiper-pagination-bullet,
        .swiper-vertical > .swiper-pagination-bullets .swiper-pagination-bullet {
            margin: var(--swiper-pagination-bullet-vertical-gap, 6px) 0;
            display: block;
        }

        .swiper-pagination-vertical.swiper-pagination-bullets.swiper-pagination-bullets-dynamic,
        .swiper-vertical > .swiper-pagination-bullets.swiper-pagination-bullets-dynamic {
            top: 50%;
            transform: translateY(-50%);
            width: 8px;
        }

        .swiper-pagination-vertical.swiper-pagination-bullets.swiper-pagination-bullets-dynamic .swiper-pagination-bullet,
        .swiper-vertical > .swiper-pagination-bullets.swiper-pagination-bullets-dynamic .swiper-pagination-bullet {
            display: inline-block;
            transition: 0.2s transform, 0.2s top;
        }

        .swiper-horizontal > .swiper-pagination-bullets .swiper-pagination-bullet,
        .swiper-pagination-horizontal.swiper-pagination-bullets .swiper-pagination-bullet {
            margin: 0 var(--swiper-pagination-bullet-horizontal-gap, 4px);
        }

        .swiper-horizontal > .swiper-pagination-bullets.swiper-pagination-bullets-dynamic,
        .swiper-pagination-horizontal.swiper-pagination-bullets.swiper-pagination-bullets-dynamic {
            display: block;
            margin: 0 auto;
            text-align: center;
            position: static;
            padding-top: 10px;
            transform: none;
            white-space: nowrap;
        }

        .swiper-horizontal > .swiper-pagination-bullets.swiper-pagination-bullets-dynamic .swiper-pagination-bullet,
        .swiper-pagination-horizontal.swiper-pagination-bullets.swiper-pagination-bullets-dynamic .swiper-pagination-bullet {
            transition: 0.2s transform, 0.2s left;
        }

        .swiper-horizontal.swiper-rtl > .swiper-pagination-bullets-dynamic .swiper-pagination-bullet {
            transition: 0.2s transform, 0.2s right;
        }

        .swiper-pagination-progressbar {
            background: rgba(0, 0, 0, 0.25);
            position: absolute;
        }

        .swiper-pagination-progressbar .swiper-pagination-progressbar-fill {
            background: var(--swiper-pagination-color, var(--swiper-theme-color));
            position: absolute;
            left: 0;
            top: 0;
            width: 100%;
            height: 100%;
            transform: scale(0);
            transform-origin: left top;
        }

        .swiper-rtl .swiper-pagination-progressbar .swiper-pagination-progressbar-fill {
            transform-origin: right top;
        }

        .swiper-horizontal > .swiper-pagination-progressbar,
        .swiper-pagination-progressbar.swiper-pagination-horizontal,
        .swiper-pagination-progressbar.swiper-pagination-vertical.swiper-pagination-progressbar-opposite,
        .swiper-vertical > .swiper-pagination-progressbar.swiper-pagination-progressbar-opposite {
            width: 100%;
            height: 4px;
            left: 0;
            top: 0;
        }

        .swiper-horizontal > .swiper-pagination-progressbar.swiper-pagination-progressbar-opposite,
        .swiper-pagination-progressbar.swiper-pagination-horizontal.swiper-pagination-progressbar-opposite,
        .swiper-pagination-progressbar.swiper-pagination-vertical,
        .swiper-vertical > .swiper-pagination-progressbar {
            width: 4px;
            height: 100%;
            left: 0;
            top: 0;
        }

        .swiper-pagination-lock {
            display: none;
        }

        .swiper-lazy-preloader {
            width: 42px;
            height: 42px;
            position: absolute;
            left: 50%;
            top: 50%;
            margin-left: -21px;
            margin-top: -21px;
            z-index: 10;
            transform-origin: 50%;
            box-sizing: border-box;
            border: 4px solid var(--swiper-preloader-color, var(--swiper-theme-color));
            border-radius: 50%;
            border-top-color: transparent;
        }
    }

    &.pdpMainFiveImage {
        .swiper-pagination-bullets-dynamic .swiper-pagination-bullet {
            transform: scale(1);
            position: relative;
        }

        .swiper-pagination-bullets-dynamic .swiper-pagination-bullet-active-prev {
            transform: scale(1);
        }

        .swiper-pagination-bullets-dynamic .swiper-pagination-bullet-active-prev-prev {
            transform: scale(1);
        }

        .swiper-pagination-bullets-dynamic .swiper-pagination-bullet-active-next {
            transform: scale(1);
        }

        .swiper-pagination-bullets-dynamic .swiper-pagination-bullet-active-next-next {
            transform: scale(1);
        }

        .swiper-pagination-bullets-dynamic .swiper-pagination-bullet-active {
            transform: scale(1);
        }
    }
}
