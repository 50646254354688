.b-physicalgiftcard_outer {
    .b-giftcards-amount-outer {
        display: flex;
    }

    .b-egiftcard-amount {
        width: 100%;
        margin-right: 1rem;
    }

    .b-egiftcard-deliverydate {
        width: 100%;
        margin-right: 0;
    }

    form.e-giftcard {
        padding-top: 2rem;
        margin-right: rem-calc(2);
    }

    .b-callout-msg {
        padding-top: 1rem;
    }

    .b-egiftcard-button {
        max-width: 100%;
        width: 100%;
    }

    #gc-amount {
        padding-left: rem-calc(25);
    }

    .dollarSymbol {
        font-size: rem-calc(14);
        font-weight: 500;
        position: absolute;
        top: rem-calc(12);
        left: rem-calc(13);
        @include respond-to(phones) {
            font-size: rem-calc(16);
        }
    }
}

.b-egiftcard-deliverydate {
    .b-input_row-input {
        padding-top: rem-calc(12);
        padding-bottom: rem-calc(13);
        line-height: inherit;
    }
}
