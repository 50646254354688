@media print {
    header,
    footer,
    .b-order-confirmation_print,
    #consent-tracking,
    .b-order-confirmation_account,
    .leading-lines .start-lines::before,
    #_im_feedbackTab__301,
    .b-cart-content-recommendation,
    .checkout-privacy-links {
        display: none !important;
    }

    .b-order-confirmation,
    .b-header_minicart-item-row,
    .b-shipping-summary_single-shipping {
        display: block;
    }

    .b-order-confirmation_left,
    .b-order-confirmation_right {
        max-width: 100%;
    }

    .b-order-confirmation_right {
        page-break-before: always;

        .print-break-section {
            page-break-after: always;
        }

        .order-product-summary {
            margin: 0;
        }
    }

    .b-header_minicart-item-image {
        width: 30%;
        float: left;
    }

    .b-header_minicart-item-attributes {
        width: 70%;
        float: right;
    }

    .b-checkout_product-summary {
        width: 100%;
        margin-top: 10px;
    }

    .b-shipping-summary_shipping {
        width: 100%;
        margin-right: 0;
    }

    .b-shipping-summary_summary-details.summary-details {
        margin-bottom: 32px !important;
    }
}
