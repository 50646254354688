.return-label--header {
    margin-bottom: rem-calc(50);
    padding: rem-calc(40) 0;

    &.header-emea {
        margin-bottom: 1rem;
        padding: rem-calc(40) 0;
    }
}

.return-label--header-body {
    margin: 0 auto;
    display: flex;
    justify-content: space-between;
    @include respond-to(mobile) {
        display: block;
        max-width: 100%;
        padding: 0 20px;

        &.kr-return-label-header-body {
            padding: 0;
        }
    }
}

.b-account {
    .g-modal-top {
        top: 30%;

        .g-afterpay-modal-content {
            width: 81%;
            bottom: rem-calc(10);
            top: 0;
            left: 10%;
            padding-top: rem-calc(20);
            padding-bottom: rem-calc(20);
            @include respond-to(mobile) {
                left: 0;
                width: 100%;
            }
        }

        .b-checkout-continue-sec {
            display: flex;
            justify-content: space-between;
            padding-top: rem-calc(15);

            button.g-button_base {
                max-width: 45%;
            }
        }
    }
}

.return-label--head-column {
    vertical-align: middle;
    z-index: 2;
    width: rem-calc(340);
    @include respond-to(mobile) {
        display: inline;
    }
}

.head-col-btn {
    float: right;
    margin-top: rem-calc(60);
}

.return-label--circle {
    float: left;
    vertical-align: middle;
    width: rem-calc(24);
    height: rem-calc(24);
    border-radius: rem-calc(12);
    margin-right: rem-calc(10);
    padding-top: rem-calc(4);
    background-color: #1d1d1d;
    color: #fff;
    font-size: rem-calc(12);
    text-align: center;
}

.return-label--instructions {
    margin-left: rem-calc(34);
}

.return-label--head-line + .return-label--head-line {
    margin-top: rem-calc(20);
}

.return-label--instructions-label {
    font-weight: 500;
    font-size: rem-calc(12);
    text-transform: uppercase;
}

.return-label--instructions-text {
    font-size: rem-calc(11);
}

.return-label--btn {
    max-width: rem-calc(225);
    margin-left: auto;
    margin-top: rem-calc(60);

    .js-orderLabel-printPage {
        z-index: 2;
    }

    .js-orderLabel-printPage-emea {
        z-index: 2;
    }

    @include respond-to(mobile) {
        max-width: 100%;
    }

    .g-button_primary--black {
        @include respond-to(mobile) {
            max-width: 100%;
        }
    }
}

.return-label-img {
    max-width: 100%;
    transform: rotate(270deg);
    vertical-align: middle;
    display: inline-block;
    margin-top: rem-calc(50);
}

.return-label-CAimg {
    position: relative;
    width: 100%;
}

.return-label-EUimg {
    position: relative;
    width: 80%;
}

.return-label-UACAPIimg {
    position: relative;
    width: 100%;
    padding: 0;
}

.return-label--slip {
    font-weight: 100;
    max-width: 100%;
    margin: 0 auto rem-calc(32);
    font-size: rem-calc(8);
    line-height: 1.3;

    .return-label--product-field {
        border-top: 1px solid #ccc;
        padding-top: rem-calc(20);

        .product-details {
            width: rem-calc(150);
            display: inline-block;

            &.qty {
                text-align: center;
            }
        }

        .return-label--minor-label {
            padding-top: rem-calc(20);
        }
    }
}

.return-label--slip-emea {
    font-weight: 100;
    max-width: 100%;
    margin: 0 auto rem-calc(32);
    font-size: rem-calc(8);
    line-height: 1.3;

    .return-label--product-field {
        border-top: 1px solid #ccc;
        padding-top: rem-calc(20);

        .product-details {
            width: rem-calc(150);
            display: inline-block;

            &.qty {
                text-align: center;
            }
        }

        .return-label--minor-label {
            padding-top: rem-calc(20);
        }
    }

    > div {
        margin-bottom: rem-calc(30);
        padding: 0 rem-calc(20);
    }
}

.label-printarea-emea {
    display: flex;
    flex-direction: column;
    align-items: center;
}

.return-label--slip > div {
    margin-bottom: rem-calc(40);
    padding: 0 rem-calc(20);
}

.return-label--cut-line {
    padding: rem-calc(80) 0 rem-calc(20);
    border-bottom: rem-calc(2) dashed #b4b4b4;
    color: #b4b4b4;
    position: relative;
    z-index: 1;
}

.return-label--sub-section {
    margin-bottom: rem-calc(30);
}

.return-label--section-title {
    padding-bottom: rem-calc(4);
    font-size: rem-calc(12);
    text-transform: uppercase;
    position: relative;
    z-index: 1;
}

.return-label--columns-3 {
    display: inline-block;
    vertical-align: top;
    width: rem-calc(158);
    margin-right: rem-calc(26);
    margin-bottom: rem-calc(10);
}

.return-label--minor-label {
    font-weight: 500;
    text-transform: uppercase;
}

.return-label--minor-heading {
    margin-bottom: rem-calc(10);
    font-size: rem-calc(10);
}

.return-label--help-info {
    margin-bottom: rem-calc(10);
}

.return-label--page-2 {
    border-top: rem-calc(2) solid #b4b4b4;
    padding-top: rem-calc(30);
}

.return-label--column-2 {
    display: inline-block;
    vertical-align: top;
    width: rem-calc(253);
    margin-right: rem-calc(26);
}

.return-label--address-field {
    margin-top: rem-calc(10);
}

.return-label--column-2 .address-line3 {
    width: rem-calc(82);
    display: inline-block;
}

.CA-pdfImg-value,
.DHL-pdfImg-value,
.SEA-pdfImg-value {
    display: none;
}

.return-error-message {
    font-size: 1em;
    line-height: 1.33;
    color: $red;
    font-weight: $font-regular;
    margin-top: rem-calc(8);
}

.img-heading-hide {
    &.us-label {
        visibility: hidden;
    }

    &.ca-label {
        display: none;
    }

    &.emea-label {
        display: none;
    }
}

@media print {
    .return-label--header,
    .b-account-container-top,
    .b-account-left,
    .b-global-message-banner__wrapper,
    .QSIFeedbackButton {
        display: none !important;
    }

    .b-heading-print {
        &.us-label {
            visibility: visible !important;
        }

        &.ca-label {
            display: block !important;
        }

        &.emea-label {
            display: block !important;
            visibility: visible !important;
        }

        padding: 0;
    }

    .return-label-img {
        width: 100%;
        height: 597px;
    }

    .us-cut-line {
        margin: 0 0 1.25rem;
    }

    .b-order_track-details {
        border: 0;
    }

    .return-label--page-2 {
        page-break-before: always;
        border-top: 0;
    }

    .b-order_track-details,
    .return-label--slip,
    .b-account-right,
    .b-account-container,
    .account-order-details-ajax {
        width: 100% !important;
        max-width: 100% !important;
        flex: 1 1 100% !important;
    }

    .return-label--column-2 {
        width: 100%;
        margin-right: 0;
    }

    .return-label-CAimg {
        padding: 0;
    }

    .email-print-label {
        margin-top: 0;
    }
}

// Css for SEA and TH site
#multi-page-print-label-pdf {
    .email-print-label {
        margin: 0;
    }
}

// CCS for print label
.print-summary {
    .summary-print-table,
    .return-info {
        table tr {
            th {
                font-size: rem-calc(15);
                padding: rem-calc(15) 0;
                font-weight: bold;
                border-top: rem-calc(1) solid $grey4;
                border-bottom: rem-calc(1) solid $grey4;
            }

            td {
                font-size: rem-calc(13);
                padding-top: rem-calc(13);
            }
        }
    }

    .return-info {
        table tr {
            td {
                border-right: rem-calc(1) solid $grey4;
                border-bottom: rem-calc(1) solid $grey4;
            }

            .text-center {
                text-align: center;
            }

            .no-border {
                border-right: none;
            }
        }

        h2 {
            font-size: rem-calc(20);
            font-weight: bold;
        }
    }

    .summary-print-heading {
        display: flex;

        .header-text,
        .brand-logo {
            flex: 0 0 50%;
        }

        .brand-logo {
            text-align: center;
        }

        .header-text {
            span {
                font-size: rem-calc(15);
                font-weight: bold;
            }
        }
    }
}
