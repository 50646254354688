.b-pdp {
    &__review-setion {
        margin: rem-calc(30) 0 rem-calc(60);

        @include respond-to(mobile) {
            margin: rem-calc(25) 0 rem-calc(25);

            .bv-content-placeholder {
                padding-left: rem-calc(16) !important;
                padding-right: rem-calc(16) !important;
            }
        }
    }
}
