.b-header_account {
    &-link {
        @include font(main_med);

        display: inline-block;
        margin-left: rem-calc(8);
        text-decoration: none;
        color: $white;
        text-overflow: ellipsis;
        overflow: hidden;
        white-space: nowrap;

        &.js-register {
            margin-right: rem-calc(8);
        }

        &:hover {
            text-decoration: underline;
        }
    }

    span {
        font-size: rem-calc(8);
        color: $white;
    }
}
