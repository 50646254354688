.b-grid_footer {
    display: flex;
    justify-content: center;
    position: relative;

    .b-loader {
        position: absolute;
    }

    &-more_button {
        opacity: 0;
        @include button(secondary-black);

        max-width: 256px;
        margin: calc((spacing(xs2)) / 2) 0;

        &.more-wl-items {
            cursor: auto;
        }
    }
}

.updateBrowserUrl {
    display: flex;
    flex: 100%;
}
