/* size and fit guide STANDALONE PAGE  */
.sf-page__wrapper {
    overflow: hidden;
    width: 100%;
    border-top: 2px solid #f0f0f0;
    margin-top: 10px;

    sub,
    sup {
        font-size: 75%;
        line-height: 0;
        position: relative;
        vertical-align: baseline;
        top: -0.5em;
    }
}

.sf-page__wrapper p {
    margin: 1rem 0;
}

.sf-page__wrapper img {
    height: auto;
    max-width: 100%;
    vertical-align: bottom;
}

.sf-page__wrapper strong {
    font-weight: $font-bold;
}

.sf-page__wrapper li h2 {
    text-transform: none;
    font-family: Armour_W_Bd, arial, sans-serif;
    padding: 8.5px;
    margin: 0;
    font-size: 18px;
    font-weight: 700;
}

.sf-page__silho .g-carousel {
    @media screen and (max-width: 767px) {
        padding: 0;
    }
}

.sf-page__silho .swiper-container {
    max-width: 930px;
    margin: 0 auto;

    @media screen and (max-width: 767px) {
        width: 28%;
        max-width: 100%;
    }
}

.sf-page__silho .swiper-wrapper {
    @media screen and (min-width: 768px) {
        justify-content: center;
    }
}

.sf-page__silho .swiper-container .swiper-slide {
    cursor: pointer;

    @media screen and (min-width: 768px) {
        width: 14%;
    }
}

.sf-page__container {
    width: 300%;
    text-align: left;
    font-size: 0;
    transition: all 0.3s ease-in-out;
}

.fitguide-container .g-carousel-slide {
    align-items: flex-start;
}

.sf-page__display-panel2 {
    transform: translate(-33.33%);
    transition: all 0.3s ease-in-out;
}

.sf-page__display-panel3 {
    transform: translate(-66.66%);
    transition: all 0.3s ease-in-out;
}

.sf-page__gen {
    width: 100%;
    position: relative;
    display: inline-block;
    vertical-align: top;
    font-size: 0;
}

.sf-page__nav-menu {
    width: 33.33%;
    position: relative;
    vertical-align: top;
}

.sf-page__nav-menu svg {
    position: absolute;
    left: 50px;
    top: 10px;
    height: 20px;
    width: 20px;
    float: left;
    margin: 10px;
    background-color: #00f;
}

.sf-page__gen li {
    display: block;
    color: #000;
    text-align: center;
    position: relative;
    font-size: 16px;
    font-family: Armour_W_Rg, arial, sans-serif;
    border-bottom: 2px solid #f0f0f0;
    margin: 0 auto;
}

.sf-page__submenu li {
    display: block;
    color: #000;
    text-align: left;
    position: relative;
    font-size: 16px;
    padding-left: 36%;
    border-bottom: 2px solid #f0f0f0;
}

.sf-guide__wrapper .modal-subtitle {
    text-align: center;
    text-transform: uppercase;
    font-weight: 600;
}

.sf-page__content a,
.sf-page__gen li:first-child,
.sf-page__gen span,
.sf-page__gen a {
    display: block;
    position: relative;
    width: 100%;
    padding: 20px 0;
    text-align: center;
    color: #000;

    @media screen and (max-width: 767px) {
        text-decoration: none;
    }
}

.sf-page__gen span {
    padding: 0;
}

.sf-page__gen a {
    text-decoration: none;
}

.mobile-gender-select span {
    display: inline-block;
    position: relative;
    padding: 20px 3px;
    text-align: center;
    color: #000;
}

.sf-page__gen svg {
    position: absolute;
    left: 10px;
    top: 10px;
    height: 20px;
    width: 20px;
    float: left;
    margin: 10px;
    background-color: #00f;
}

.sf-page__select {
    width: 33.33%;
    position: relative;
    display: inline-block;
    font-size: 16px;
}

.sf-page__mobile-subcategory {
    background: #f0f0f0;
    font-size: 24px;
}

.sf-page__selection-link {
    font-size: 24px;
}

.sf-page__mobile-subcategory span {
    padding: 30px 0;
    font-family: Armour_W_Bd, arial, sans-serif;
}

.sf-page__backbutton {
    position: relative;
    font-family: Armour_W_Bd, arial, sans-serif;
}

.mobile-gender-select {
    width: 33.33%;
    position: relative;
    display: inline-block;
    margin-bottom: 50px;
    vertical-align: top;
    text-align: center;
    font-size: 16px;
}

.sf-page__backbutton svg {
    position: absolute;
    left: 25px;
    height: 30px;
    width: 15px;
    top: 16px;
    fill: transparent;
    stroke: #000;
}

.icon__angle {
    fill: transparent;
    stroke-width: 7;
}

.sf-page__submenu svg {
    display: inline-block;
    vertical-align: middle;
    height: 40px;
    width: 40px;
    fill: #8c8c8c;
    margin-right: 5px;
}

.sf-page__content {
    width: 33.333%;
    display: inline-block;
    vertical-align: top;
    text-align: center;
    overflow: hidden;
}

.sf-page__silho {
    display: block;
    text-align: center;
    background-color: #f0f0f0;
    margin-bottom: 40px;
    height: 100px;
    max-height: 100px;
    min-height: 100px;
    overflow: hidden;
}

.sf-page__silho-owlwrapper {
    width: 25%;
    margin: 0 auto;
}

.sf-page__silho .owl-wrapper-outer {
    overflow: visible;
    position: relative;
    margin: 0 auto;
    max-width: 1024px;
}

.sf-page__silho li {
    display: inline-block;
    text-align: center;
    vertical-align: top;
    width: 100px;
    height: 80px;
    margin: 7px 0 0;
}

.sf-page__silho li a {
    display: contents;
}

.sf-page__silho span {
    display: block;
    font-size: 12px;
    color: #8c8c8c;
    font-family: arial, sans-serif;
}

.sf-page__silho .swiper-slide-active span {
    color: #000;
    font-family: arial, sans-serif;
    font-weight: bold;
}

.sf-page__silho svg {
    height: 50px;
    width: 50px;
    fill: #8c8c8c;
    margin: 5px auto;
    display: block;
}

.sf-page__silho .swiper-slide-active svg {
    fill: #000;
}

.sf-page__wrapper .infinite-scroll__load {
    width: 54px;
    margin: 20px auto;
    display: inline-block;
}

.sf-page__wrapper .sf-guide__shell {
    display: block !important;
}

.sf-page__wrapper .sf-guide__header,
.sf-page__wrapper .sf-guide__close-modal {
    display: none !important;
}

.sf-page__wrapper .sf-guide-page__header {
    display: block;
    margin-top: 0;
    margin-bottom: 20px;
    text-align: center;
    font-size: 24px !important;
    font-weight: bold;
    font-family: Arial, sans-serif;
    text-transform: uppercase;
}

.sf-guide-page__header {
    display: none;
}

.sf-page__wrapper .sf-guide__wrapper {
    position: relative;
    width: 100%;
    height: 100%;
    top: initial;
    left: initial;
    bottom: initial;
    z-index: initial;
    background-color: #fff;
    overflow-y: initial;
}

.sf-page__wrapper .detail-container .detail-container {
    width: 100% !important;
}

.sf-page__content {
    height: 160px;
}

.sf-page__display-panel3 .sf-page__content {
    height: auto;
}

@media screen and (min-width: 768px) {
    .sf-page__content {
        width: 100%;
        position: relative;
        display: none;
        opacity: 0;
    }

    .sf-page__wrapper .sf-guide-page__header {
        font-size: 20px !important;
        margin-bottom: 20px;
        text-align: left;
        font-family: Armour_W_Bd, Arial, sans-serif;
        text-transform: uppercase;
    }

    .sf-guide__wrapper .modal-subtitle {
        text-align: left;
        text-transform: uppercase;
        font-weight: 600;
    }

    .sf-page__gen li {
        width: 33%;
    }

    .sf-page__gen li:first-child {
        width: 33%;
    }

    .mobile-gender-select {
        width: 33.333%;
        margin-top: 60px;
        overflow: hidden;
    }

    .sf-page__display-panel2 .mobile-gender-select {
        margin-left: -33.333%;
        margin-top: 60px;
        opacity: 1;
    }

    .sf-page__display-panel2 {
        transform: translate(0);
    }

    .sf-page__display-panel2 .sf-page__select,
    .sf-page__silho,
    .sf-page__result {
        width: 33.333%;
    }

    .sf-page__display-panel2 .sf-page__select {
        padding: 10px 0;
    }

    .sf-page__display-panel2 .sf-page__container {
        width: 100%;
    }

    .sf-page__display-panel2 .sf-page__gen {
        text-align: center;
    }

    .sf-page__display-panel2 .sf-page__gen li:nth-child(2) {
        border-left: none;
    }

    .sf-page__display-panel2 .sf-page__gen li {
        display: inline-block;
        text-align: center;
        width: 24%;
        border-bottom: none;
        border-left: 2px solid #d2d2d2;
    }

    .sf-page__display-panel2 .sf-page__gen a {
        padding: 8px 0;
        font-size: 18px;
    }

    .sf-page__display-panel2 .sf-page__gen span {
        display: inline;
        width: initial;
        padding: 3px 10px;
        margin: 0 16%;
    }

    .sf-page__display-panel2 .sf-page__content {
        margin-top: 0;
    }

    .sf-page__display-panel2 .sf-page__gen .is-set span {
        border-bottom: 2px solid #000;
        font-family: Armour_W_Bd, arial, sans-serif;
        font-weight: 700;
    }

    .sf-page__display-panel3 .sf-page__submenu {
        height: 0;
    }

    .sf-page__display-panel3 .sf-page__content {
        background-color: #fff;
        display: block;
        opacity: 1;
        margin-top: 0;
        transition: all 0.3s ease-in-out;
    }

    .sf-page__display-panel3 .sf-page__mobile-subcategory,
    .sf-page__backbutton,
    .sf-page__display-panel2 .sf-page__gen svg,
    .sf-page__display-panel2 .sf-page__gen li:first-child {
        display: none;
    }

    .sf-page__display-panel3 .mobile-gender-select li {
        z-index: -1;
        opacity: 0;
        transition: all 0.3s ease-in-out;
    }

    .sf-page__display-panel3 .mobile-gender-select {
        margin-top: 10px;
    }

    .sf-page__submenu li {
        padding-left: 13%;
        border-bottom: 2px solid #f0f0f0;
        width: 33%;
        min-width: 360px;
        margin: 0 auto;
        cursor: pointer;
    }

    .sf-page__silho .owl-wrapper {
        width: 100% !important;
        text-align: center;
    }

    .sf-page__silho-owlwrapper {
        width: 100%;
    }

    .sf-page__silho-owlwrapper .owl-item {
        float: none;
        display: inline-block;
        width: 13% !important;
        cursor: pointer;
    }
}

@media screen and (min-width: 1024px) {
    .sf-page__display-panel2 .sf-page__gen li {
        width: 17%;
    }
}

/* size and fit guide PDP  */
.size-chart__open-modal {
    font-size: 13px;
    color: #1d1d1d;
    text-decoration: underline;
    display: inline-block;
    margin: 5px 0 20px;
}

.product-set-container .size-chart__open-modal {
    display: none;
}

.size-chart__open-modal:hover {
    color: #b4b4b4;
}

.ui-dialog-content .size-chart__open-modal {
    display: none;
}

#SizeChartDialog {
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    width: 100%;
    z-index: 5000;
    opacity: 0;
}

.sf-guide__wrapper {
    position: fixed;
    width: 100%;
    height: 100%;
    top: auto;
    left: 0;
    bottom: 0;
    z-index: 5000;
    background-color: #fff;
    overflow-y: auto;
}

.sf-guide__close-modal {
    width: 25px;
    height: 25px;
    stroke: #000;
    position: absolute;
    right: 30px;
    top: 12px;
    cursor: pointer;
}

.sf-guide__switch {
    position: relative;
    background-color: #fff;
    width: 300px;
    height: 30px;
    padding: 2px 0;
    margin: -25px auto 0;
    font-size: 0;
    line-height: 2.4;
    font-family: Armour_W_Md, arial, sans-serif;
    text-transform: uppercase;
    color: #8c8c8c;
    border-radius: 18px;
    border: 1px solid #ccc;
    cursor: pointer;

    @include respond-to(mobile) {
        width: 100%;
    }
}

.sf-guide__switch span {
    width: 50%;
    font-size: 13px;
    line-height: 2;
    text-align: center;
    display: inline-block;
    position: absolute;
    z-index: 1;
    left: 0;
}

.sf-guide__switch span:nth-child(2) {
    left: 50%;
}

.sf-guide__switch div {
    width: 50%;
    height: 28px;
    line-height: 2;
    background-color: #1d1d1d;
    border-radius: 18px;
    display: inline-block;
    position: absolute;
    top: 0;
    z-index: 0;
    margin-top: 0;
    transition: right 0.5s ease;
}

.centimeters .sf-guide__switch .centimeters,
.inches .sf-guide__switch .inches {
    color: #fff;
    transition: color 0.5s ease;
}

.centimeters .sf-guide__switch div {
    right: 0;
    transition: right 0.5s ease;
}

.inches .sf-guide__switch div {
    right: 50%;
    transition: right 0.5s ease;
}

.sf-fitguide__wrapper {
    margin: 0 auto;
    max-width: 990px;
    text-align: left;
}

.sf-fitguide__wrapper .g-carousel {
    padding: 0;
}

.sf-guide__custom-content {
    text-align: left;
    margin: 0 auto;
    padding-bottom: 20px;
    border-bottom: 1px solid #ccc;
}

.sf-guide__header {
    font-size: 24px;
    margin: 40px auto;
    padding: 0 10px;
    color: #000;
    text-align: center;
    text-transform: uppercase;
    font-weight: bold;
}

.sf-guide__content {
    text-align: center;
}

.sf-guide__wrapper .detail-section {
    display: block;
    margin: 0 0 40px;
    text-align: left;
    max-width: 450px;
}

.sf-guide__wrapper .detail-image {
    display: inline-block;
    margin: 0 0 0 50px;
    width: 320px;
}

.sf-guide__wrapper .detail-container {
    font-family: Armour_W_Rg, arial, sans-serif;
    font-size: 13px;
    margin: 0 0 40px;
    width: 500px !important;
    display: inline-block;
    vertical-align: top;
    text-align: left;
    float: none;
}

.fitguide-container-kids {
    text-align: left;
}

.fitguide-container-kids .fitguide__item {
    margin: 0 15px;
}

@media screen and (min-width: 768px) and (max-width: 1024px) {
    .sf-guide__wrapper .detail-image {
        margin: 0 0 0 20px;
        width: 300px;
    }

    .sf-guide__wrapper .detail-container {
        margin: 0 0 40px;
        width: 420px !important;
    }

    .sf-guide__wrapper .fitguide-container {
        max-width: 100%;
    }

    .fitguide-container-kids {
        text-align: center;
        margin-right: 35.5%;
        width: 550px;
    }

    .fitguide-container-kids .fitguide__item:first-child {
        margin: 0;
    }
}

.modal-body-fixed {
    overflow-y: hidden;
    position: fixed;
}

@media screen and (max-width: 686px) {
    .sf-guide__wrapper .detail-image {
        width: 100%;
    }
}

.sf-guide__wrapper .detail-image img {
    height: auto !important;
}

.sf-fitguide__wrapper .modal-subtitle,
.sf-guide__wrapper .modal-subtitle {
    margin: 0 0 10px;
    font-family: Armour_W_Bd, Arial, sans-serif;
    font-size: 15px;
    color: #000;
}

.sf-fitguide__wrapper .modal-subtitle {
    width: 950px;
    margin: 0 auto 24px;
    padding: 0 40px;
}

.sf-guide__extra-content {
    width: 950px;
    margin: 40px auto 24px;
    text-align: left;
}

.fitguide-overflow {
    width: 100%;
    overflow: hidden;
}

.sf-guide__wrapper .owl-pagination {
    bottom: -20px;
}

.sf-guide__extra-content .detail-container {
    display: block !important;
}

.sf-guide__extra-content-hr {
    width: 100%;
    border: 0;
    height: 0;
    border-top: 1px solid #ccc;
    margin-bottom: 40px;
}

@media screen and (min-width: 768px) and (max-width: 1024px) {
    .sf-guide__extra-content {
        width: 750px;
    }

    .sf-fitguide__wrapper .modal-subtitle,
    .sf-guide__wrapper .fitguide-container {
        width: 100%;
    }

    .sf-fitguide__wrapper {
        width: 740px;
    }
}

@media screen and (min-width: 768px) {
    .sizingTable .mb {
        display: none;
    }
}

@media screen and (max-width: 767px) {
    .sf-fitguide__wrapper,
    .sf-fitguide__wrapper .modal-subtitle,
    .sf-guide__wrapper .modal-subtitle,
    .size-chart__extra-content {
        width: 100%;
    }

    .sizingTable .dt {
        display: none;
    }

    .sf-guide__wrapper .fitguide-container {
        width: 100%;
    }

    .size-chart__open-modal {
        margin: 5px 0 20px 15px;
    }

    .fitguide__center-image {
        margin: 0;
    }

    .fitguide__list-tops .fitguide__image-wrapper {
        height: 350px;
    }

    .sf-guide__wrapper .detail-section {
        width: auto;
    }

    .sf-guide__wrapper .detail-container {
        width: 90% !important;
        margin: 0 auto 40px;
        text-align: center;
    }

    .sf-guide__wrapper .detail-image {
        margin: 0 auto 40px;
        text-align: center;
    }

    .sizingTable {
        width: 100%;
        margin: 0 auto;
    }

    .sf-guide__extra-content {
        width: 100%;
        margin: 40px auto;
    }

    .sf-guide__extra-content-hr {
        width: 90%;
    }

    .sf-fitguide__wrapper .fitguide__image {
        opacity: 1;
        transition: opacity 0.3s ease-in-out;
    }

    .sf-fitguide__wrapper .fitguide__description,
    .sf-fitguide__wrapper .fitguide__title {
        width: 100%;
        margin-left: 50%;
        left: -60%;
        transform: translate(-50%, 0);
        opacity: 1;
        text-align: center;
        transition: all 0.3s ease-in-out;
        padding: 0 20px;
    }

    .sf-fitguide__wrapper .fitguide__title {
        padding-top: 0;
        height: auto;
    }

    .fitguide-container-kids {
        text-align: center;
    }

    .fitguide-container-kids:not(.g-carousel-wrapper) .fitguide__image {
        opacity: 1;
    }

    .fitguide-container-kids:not(.g-carousel-wrapper) .fitguide__item {
        margin: 0 10px;
    }

    .fitguide-container-kids:not(.g-carousel-wrapper) .fitguide__description {
        width: 100%;
        opacity: 1;
        margin: 0;
        left: auto;
        transform: none;
        padding: 0;
    }

    .fitguide-container-kids .fitguide__item {
        width: 160px;

        &:not(.g-carousel-slide) {
            display: block;
            margin: 0 auto;
        }
    }
}

/* Fit Guide */
.fit-guide {
    font-size: 13px;
    margin: 0 20px;
}

.fitguide__tagline {
    margin: 0;
}

.fitguide__list {
    width: 100%;
    text-align: center;
}

.fitguide__item {
    width: 230px;
    font-size: 13px;
    text-align: center;
    display: inline-block;
    vertical-align: top;
}

@media screen and (max-width: 767px) {
    .fitguide__item {
        width: 200px;
    }
}

.fitguide__title {
    margin-bottom: 14px;
}

.detail-container strong {
    font-family: Armour_W_Bd, Arial, sans-serif;
}

.fitguide__image-wrapper {
    position: relative;
    overflow: hidden;
}

.fitguide__image {
    position: relative;
}

.fitguide__list-tops .fitguide__image-wrapper {
    height: 390px;
}

.fitguide__list-bottoms .fitguide__image-wrapper {
    height: 390px;
}

.fitguide__list-bottoms .fitguide__image {
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
}

.fitguide__item {
    color: #3d3d3d !important;
}

.fitguide__item .fitguide__title {
    color: #1d1d1d;
    font-weight: bold;
}

.fitguide__description {
    color: #3d3d3d;
    font-family: Armour_W_Rg, arial, sans-serif;
}

.fitguide__title,
.fitguide__description strong {
    font-family: Armour_W_Bd, arial, sans-serif;
}

.sf-guide__wrapper.inches .sizingTable.inches,
.sf-guide__wrapper.centimeters .sizingTable.centimeters {
    display: table;

    @include respond-to(phones) {
        display: block;
    }
}

.sf-guide__wrapper.centimeters .sizingTable.inches,
.sf-guide__wrapper.inches .sizingTable.centimeters {
    display: none;
}

.sf-guide__wrapper td,
.sf-guide__wrapper th {
    padding: 0 5px 5px 0;
}

.sf-guide__wrapper td:last-child,
.sf-guide__wrapper th:last-child {
    padding-right: 0;
}

.sf-guide__wrapper .sizingTable {
    min-width: 165px;
    border-width: 0;
    border-spacing: 0;
    caption-side: top;
}

.sf-guide__wrapper .sizingTable,
.sf-guide__wrapper .table-condensed {
    width: 100%;
}

.sf-guide__wrapper .sizingTable thead {
    background-color: #fff;
    border-bottom: 2px solid #f0f0f0;
}

.sf-guide__wrapper .sizingTable {
    margin-bottom: 40px;
    border-bottom: 2px solid #f0f0f0;
}

.sf-guide__wrapper .sizingTable tr,
.sizingTable tr {
    vertical-align: middle;
}

.sf-guide__wrapper tr:nth-child(even) {
    background-color: $grey2;
}

.sf-guide__wrapper {
    .sizingTable {

        @include respond-to(phones) {
            display: block;
            overflow-x: auto;
        }
    }

    tr {
        &:nth-child(even) {
            th {
                background-color: $grey2;
            }
        }

        &:nth-child(odd) {
            th {
                background-color: $white;
            }
        }

        th {
            &:first-child {
                @include respond-to(mobile) {
                    position: sticky;
                    left: 0;
                }
            }
        }
    }
}

.sf-guide__wrapper thead th {
    background-color: #fff;
}

@media screen and (max-width: 767px) {
    .sf-guide__wrapper .sizingTable td,
    .sf-guide__wrapper .sizingTable th,
    .sf-guide__wrapper .sizingTable td:first-child,
    .sf-guide__wrapper .sizingTable th:first-child {
        white-space: normal;
    }
}

.sf-guide__wrapper .sizingTable thead td,
.sf-guide__wrapper .sizingTable thead th {
    color: #1d1d1d;
}

.sf-guide__wrapper .sizingTable .header {
    padding-top: 25px;
    text-align: left;
    border: none;
}

.sf-guide__wrapper .sizingTable .header.first {
    padding-top: 10px;
}

.sf-guide__wrapper .sizingTable td.last,
.sf-guide__wrapper .sizingTable th.last {
    border-bottom: 0;
}

.detail-container {
    margin: 20px 0 18px;
    width: 380px;
    float: left;
}

.pt_product-details .detail-container {
    width: 100%;
    margin-bottom: 2px;
}

@media screen and (max-width: 686px) {
    .sf-guide__wrapper .detail-image {
        width: 100%;
    }
}

.size-chart__header {
    font-size: 24px;
    margin-top: 0;
    text-align: left;
}

.sf-guide__wrapper .detail-image img {
    height: auto !important;
}

.sizingTable-container span {
    font-size: 10px;
    color: #767676;
    text-align: center;
    width: 100%;
    margin-top: -30px;
    display: block;
    @media screen and (max-width: 375px) and (min-width: 320px) {
        padding: 0 7px;
    }
}

.sizingTable caption.nolink {
    color: #000;
    cursor: text;
    text-decoration: none;
}

.sizingTable td,
.sizingTable th {
    font-size: 13px;
    margin: 0 2px;
    padding: 8px 3px;
    text-align: left;
    white-space: nowrap;
}

.sizingTable tr,
.sizingTable tbody td,
.sizingTable tbody th {
    vertical-align: middle;
    font-weight: normal;
}

.sizingTable td:first-child,
.sizingTable th:first-child {
    width: 35px;
    white-space: nowrap;
}

.sizingTable-header {
    font-family: Armour_W_Bd, Arial, sans-serif;
}

.sizingTable-extended {
    width: 800px;
    margin-left: -30%;
}

.sizingTable-extended tbody td {
    vertical-align: top;
}

.sizingTable-extended p {
    margin: 0;
}

.sf-guide__wrapper .modal-subtitle-extended {
    margin-left: -30%;
}

.visible-mobile {
    display: none !important;
}

@media screen and (max-width: 1024px) and (min-width: 768px) {
    .sizingTable-extended {
        width: 700px;
        margin-left: -33%;
    }

    .sf-guide__wrapper .modal-subtitle-extended {
        margin-left: -33%;
    }
}

@media screen and (max-width: 767px) {
    .sizingTable-extended {
        width: 100%;
        margin-left: 0;
    }

    .sf-guide__wrapper .modal-subtitle-extended {
        margin-left: 0;
    }

    .sizingTable-extended tbody td {
        font-size: 9px;
    }

    .visible-mobile {
        display: block !important;
    }

    .hidden-mobile {
        display: none !important;
    }
}

.sf-guide__wrapper .sizingTable thead {
    background-color: #fff;
    border-bottom: 2px solid #f0f0f0;
}

.brasTable th:first-child {
    border-right: 1px solid #ddd;
}

.sizingTable.bras th {
    text-align: center;
}

.sizingTable.bras.brasTable td {
    font-size: 13px;
}

.sizingTable.bras td {
    text-align: center;
    font-size: 11px;
}

.sf-guide__content {
    text-align: center;
    padding: 0 40px;

    @media screen and (max-width: 767px) {
        padding: 0 35px;
    }

    @media screen and (max-width: 1024px) and (min-width: 768px) {
        padding: 0 10px;
    }
}

.sf-guide__extra-content {
    display: none;
}
