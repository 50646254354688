.b-giftcard {
    &__balance-title {
        font-size: rem-calc(32);
        font-weight: 600;
        line-height: 1.25;

        @include respond-to(mobile) {
            padding-left: 0.5rem;
        }
        @media (max-width: 700px) {
            text-align: left;
            padding-left: 0;
        }
    }

    &__balance-subtitle {
        font-size: rem-calc(14);
        font-weight: 400;
        line-height: rem-calc(20);
        padding-bottom: rem-calc(12);

        @media (max-width: 700px) {
            text-align: left;
        }
        @include respond-to(mobile) {
            padding-right: rem-calc(14);
        }

        &.caMessage {
            @include font(main, rem-calc(13), rem-calc(16));

            margin-bottom: rem-calc(10);
        }
    }

    &__balance-slot {
        display: block;
        padding: 0;

        @include respond-to(mobile) {
            display: flex;
            justify-content: center;
        }

        @media (max-width: 700px) {
            display: block;
        }
    }

    &__checkbalance {
        margin: 0 auto;
        padding: rem-calc(58) 0 0 0;
        max-width: rem-calc(735);
        border-bottom: 1px solid;

        @include respond-to(mobile) {
            margin: 0 rem-calc(8) 0 rem-calc(8);
            padding: rem-calc(64) 0 0 0 !important;
        }

        @media (max-width: 700px) {
            margin: 0 rem-calc(8) 0 rem-calc(8);
            padding: rem-calc(64) 0 0 0 !important;
        }
    }

    &__slot-half {
        @include grid-col(6);

        padding: 0 !important;

        @include respond-to(mobile) {
            display: none;
        }
    }

    &__checkbalance-form {
        padding: rem-calc(20) 0 0;

        .field-group {
            display: flex;
            justify-content: space-between;
            align-items: flex-start;

            @media (max-width: 700px) {
                display: block;
                padding-left: 0;
            }

            .b-input_row {
                &.number-field {
                    margin-right: 0;
                    flex: 0 0 40%;

                    @include respond-to(mobile) {
                        margin-right: rem-calc(36);
                    }
                }
            }

            .pin-field {
                flex: 0 0 53%;
                @include respond-to(phones) {
                    flex: 0 0 50%;
                    margin-bottom: rem-calc(8);
                }
            }

            .b-submit-result {
                flex: 0 0 47%;
                @include respond-to(phones) {
                    padding-bottom: rem-calc(32);
                }

                .form-row-button {
                    position: relative;

                    @include respond-to(mobile) {
                        max-width: rem-calc(150);
                    }

                    .b-loader {
                        position: absolute;
                        background: #1d1d1d;
                        opacity: 1;
                        border-radius: 0.25rem;

                        @include respond-to(mobile) {
                            border-radius: 0.25rem;
                        }

                        .b-loader-icon {
                            border-radius: 0.25rem;
                        }
                    }
                }

                .js-check-balance-form-submit {

                    @include respond-to(mobile) {
                        width: rem-calc(150);
                    }

                    font-size: rem-calc(14);
                    line-height: 0;

                    &.f-balance-checkmark {
                        @include icon(check);

                        &::before {
                            font-size: rem-calc(23);
                            margin-right: rem-calc(9);
                            line-height: 0;
                        }
                    }

                    &:focus {
                        border-color: $black;
                    }

                    &:not(:disabled) {
                        @include respond-to(hoverable) {
                            &:hover {
                                border-color: #1d1d1d;
                                background-color: #1d1d1d;
                            }
                        }
                    }
                }
            }
        }

        .check-balance-right {
            flex: 0 0 58%;
            @include respond-to(mobile) {
                display: flex;
                flex-direction: row;
                gap: rem-calc(24);
                padding: 0;
            }
            @media (max-width: 700px) {
                display: flex;
                flex-direction: column;
                justify-content: space-between;
                gap: rem-calc(24);
                align-items: flex-start;
            }
        }

        .b-balance-result-wrapper {
            display: none;
            text-align: center;
            @media (max-width: 700px) {
                text-align: left;
            }
        }

        .form-border-mobile {
            display: none;
            @include respond-to(phones) {
                display: block;
                border-bottom: 1px solid;
                width: rem-calc(350);
                margin-left: rem-calc(220);
            }
            @media (max-width: 700px) {
                margin-left: 0;
                width: 100%;
            }
        }
    }

    &__top-content {
        @include base-wrapper;

        text-align: center;
        padding: rem-calc(40) 0 rem-calc(20) 0;

        @include respond-to(mobile) {
            padding: rem-calc(31) 0 rem-calc(32);
        }
    }

    &__landing-wrapper {
        max-width: unset;
        width: 100%;
        margin: 0 auto;

        @include respond-to(mobile) {
            margin: 0;
        }
    }

    &__topbanner-content {
        width: auto;
        margin: 0 auto;
        display: inline-block;

        @include respond-to(mobile) {
            max-width: unset;
        }

        h5 {
            @include font(main_bold, rem-calc(16), 1.5rem);

            font-weight: 600;
        }

        h2 {
            @include font(main_bold, rem-calc(32), 1);

            font-weight: 600;
            padding-bottom: rem-calc(12);

            @include respond-to(mobile) {
                font-size: rem-calc(24);
                line-height: 1.2;
                padding-top: rem-calc(12);
            }
        }

        span {
            font-size: rem-calc(14);
            line-height: rem-calc(20);

            @include respond-to(mobile) {
                font-size: rem-calc(14);
                max-width: unset;
                width: 100%;
                display: block;
                margin: rem-calc(6) auto 0;
            }
        }
    }

    &__banner-top {
        width: 100%;
        max-width: unset;
        margin: 0 auto;
        padding-right: 0;
        padding-left: 0;
        padding-bottom: rem-calc(56);
        display: flex;

        @include respond-to(mobile) {
            display: flex;
        }

        @include respond-to(phones) {
            display: block;
        }
    }

    &__banner-top-left {
        margin-right: rem-calc(16);
        @include respond-to(phones) {
            margin-right: 0;
        }
    }

    &__banner-top-left,
    &__banner-top-right {
        // @include grid-col(6);
        padding-right: 0;
        padding-left: 0;

        @include respond-to(mobile) {
            padding-right: 0;
            padding-left: 0;
            // @include grid-col(12);
        }
        @include respond-to(phones) {
            @include grid-col(12);
        }
    }

    &__banner-top-right {
        @include respond-to(mobile) {
            margin-top: 0;
        }
        @include respond-to(phones) {
            margin-top: rem-calc(40);
        }
    }

    &__banner-image {
        position: relative;

        img {
            max-width: 100%;
        }
    }

    &__banner-bottom-content {
        padding: rem-calc(20) 0 0;

        h5 {
            @include font(main_bold, rem-calc(16), 1.5rem);

            font-weight: 600;
            text-decoration: underline;
        }

        p {
            color: rgb(29, 29, 29);
        }

        @include respond-to(mobile) {
            padding: rem-calc(16) 0 0;
        }

        a {
            /* stylelint-disable */
            text-underline-offset: rem-calc(4);
            /* stylelint-enable */
            @include respond-to(mobile) {
                /* stylelint-disable */
                text-decoration-thickness: rem-calc(1.5);
                /* stylelint-enable */
            }
        }
    }
}

.f-giftcardinput {
    &__balance {
        max-width: rem-calc(283);
        width: 100%;

        @include respond-to(mobile) {
            width: rem-calc(283);
        }
    }

    &__pin {
        max-width: rem-calc(200);
        width: 100%;
    }

    &__button {
        max-width: rem-calc(208);
        width: 100%;
    }
}

.x-ua-hover-div {
    position: absolute;
    left: 0;
    top: 0;
    right: 0;
    height: 100%;
    padding-top: 30%;
    background-color: rgba(0, 0, 0, 0.5);
    opacity: 0;
    transition: all 0.5s ease;
    text-align: center;

    .x-ua-new-white-cta {
        display: inline-block;
        padding: rem-calc(11.5) rem-calc(20);
        border: 2px solid $white;
        color: $white;
        font-size: rem-calc(13);
        font-weight: $font-bold;
    }

    &:hover {
        opacity: 1;
    }
}

.gift-card {
    &__balance-form-row {
        .b-input_row-label {
            font-size: rem-calc(12);
            line-height: rem-calc(17);
        }

        .error-field input {
            &:hover,
            &:active,
            &:focus {
                border-color: $red;
            }
        }
    }

    &__balance-form-row.b-submit-result {
        display: flex;

        .form-row-button {
            max-width: 100%;
            // @include grid-col(6);
            padding-right: 0;
            padding-left: 0 !important;
        }
    }

    &__balance-result {
        font-size: rem-calc(24);
        font-weight: bold;
        letter-spacing: rem-calc(0.5);
        padding-bottom: rem-calc(24);
    }

    &__balance-title {
        font-size: unset;
        line-height: rem-calc(32);
        font-weight: 600;
    }

    &__balance-symbol,
    &__balance-value {
        font-size: unset;
        line-height: 1;
        font-weight: $font-bold;
    }

    &__balance-ca-symbol {
        @include font(main_bold, rem-calc(22), rem-calc(1));
        @include respond-to(phones) {
            font-size: rem-calc(18);
        }
        @include respond-to(iphonef) {
            font-size: rem-calc(16);
        }
    }

    &__expiry-title,
    &__expiry-value {
        font-size: rem-calc(18);
        line-height: rem-calc(24);
        font-weight: 500;
    }
}

.giftCard-available-balance-errormsg {
    color: $red;
}

.b-cardnumber_demo {
    display: flex;
    align-items: center;
    padding-top: 5px;

    span.b-demo-image {
        background: url('../../images/iconimages/cardnumber.png') no-repeat;
        height: rem-calc(23);
        width: rem-calc(40);
        max-width: 100%;
        line-height: 1;
    }

    span {
        font-size: rem-calc(11);
        color: $red;
        text-transform: uppercase;
        font-weight: $font-bold;
        line-height: 1;
        margin-left: 5px;
    }
}

.giftcards-landing-page {
    padding: 0 3.5rem;
    margin: 0 auto;
    max-width: 1440px;
    @include respond-to(mobile) {
        overflow-x: hidden;
        position: relative;
        padding-left: 1rem;
        padding-right: 1rem;
    }
}
