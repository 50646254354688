body {
    &.atome-hidden {
        overflow: hidden;
    }

    #maincontent {
        position: relative;
    }
}

.atome-popup-show {
    visibility: hidden;
    opacity: 0;
    transition: all 0.4s ease-in-out;
    transform: scale(1.3);
    position: fixed;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    width: 100%;
    height: 100%;
    z-index: 9999;

    img {
        vertical-align: middle;
        border-style: none;
    }

    .atome-popup {
        position: absolute;
        top: 50%;
        transform: translateY(-50%);
        left: 0;
        right: 0;
        bottom: 0;
        width: calc(100% - 1em);
        max-width: 35.93em;
        margin: 0 auto;
        background-color: #ffff;
        height: 100%;
        max-height: 25em;

        .content-right {
            padding: 2.6em 0.95em 0;

            .content-head {
                width: 100%;
                text-align: center;

                .atome-logo {
                    max-width: 5.625em;
                    margin-bottom: 0.7em;
                }

                span {
                    display: block;
                    font-weight: 700;
                    font-size: 0.95em;
                }

                p {
                    font-size: 0.878em;
                    margin: 0;
                }
            }

            .content-wrapper-right {
                margin-top: 2.3125em;

                .pay-steps {
                    text-align: center;
                    padding: 0 0.625em 2.8125em;

                    .icon-img {
                        max-width: 25%;
                        flex: 1 0 25%;
                        margin: 0 auto;

                        .img-asset {
                            margin-bottom: 1.25em;
                            height: 2.8125em;

                            img {
                                width: 100%;
                            }
                        }
                    }

                    .content {
                        span {
                            font-size: 0.878em;
                            word-spacing: -0.125em;

                            &.text-bold {
                                display: block;
                                font-size: 0.878em;
                                font-weight: 700;
                                line-height: 1.3;
                            }
                        }
                    }
                }

                .atome-redirect {
                    justify-content: center;

                    a {
                        background: #0998ff;
                        font-size: 0.878em;
                        padding: 0.4375em 0.95em;
                        width: 12.5em;
                        color: #fff;
                    }
                }
            }
        }

        .close-btn {
            position: absolute;
            top: 0.8em;
            right: 0.8em;
            font-size: 1.125em;
            transition: all 0.3s ease-in-out;
            cursor: pointer;
            z-index: 2;

            .fa-close {
                &::after {
                    display: inline-block;
                    content: "\00d7";
                    font-size: 26px;
                }
            }
        }

        @include respond-to(mobile) {
            max-width: 21.87em;
            max-height: 29.75em;

            .content-right {
                padding: 0.625em 0.95em;

                .content-wrapper-right {
                    margin-top: 0;

                    .content {
                        text-align: center;
                        width: 100%;
                        margin-bottom: 0.75em;

                        span {
                            display: block;
                            font-size: 1.125em;
                            font-weight: 700;
                        }

                        .para-text {
                            font-size: 0.8125em;
                            margin: 0;
                            font-weight: normal;
                        }

                        .atome-logo {
                            max-width: 5.3em;
                        }
                    }

                    .pay-steps {
                        padding: 1.25em 0;
                        display: flex;
                        justify-content: space-between;
                        align-items: center;

                        .content {
                            text-align: left;
                            max-width: 65%;
                            flex: 1 0 65%;

                            span {
                                font-size: 0.95em;
                                line-height: 1.3;
                                display: block;
                                font-weight: 700;
                                word-spacing: 0;
                            }
                        }

                        .icon-img {
                            .img-asset {
                                max-width: 2.5em;
                                margin: 0 auto;
                            }
                        }
                    }

                    .atome-redirect {
                        a {
                            padding: 0.625em 0.95em;
                            width: 17.5em;
                        }
                    }
                }
            }
        }
    }

    .overlay {
        position: fixed;
        width: 100%;
        height: 100%;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        background-color: rgba(0, 0, 0, 0.5);
        cursor: pointer;
    }

    &.show {
        visibility: visible;
        opacity: 1;
        transform: scale(1);
        padding-right: 1em;
    }
}

.atome_widget {
    .atome-paragraph {
        margin: 6px 0 0;

        .atome-amount {
            font-weight: 700;
        }

        .atome-logo {
            height: 1.1875em;
            cursor: pointer;
            transition: transform 0.3s ease-out;
            vertical-align: sub;
        }
    }
}

.plp-marketing {
    .atome-paragraph {
        font-size: 0.878em;
        text-align: left;

        .atome-logo {
            height: 1.1875em;
        }
    }
}

.atome-popup-show {
    .row {
        display: -webkit-box;
        display: -ms-flexbox;
        display: flex;
        -ms-flex-wrap: wrap;
        flex-wrap: wrap;

        .row {
            margin-right: 0;
            margin-left: 0;
            max-width: none;
            padding-right: 0;
            padding-left: 0;
        }
    }

    .col-md-4,
    .col-md-12 {
        position: relative;
        width: 100%;
        min-height: 1px;
    }

    .d-md-block {
        display: block !important;
    }

    .d-md-none {
        display: none !important;
    }

    .btn {
        display: inline-block;
        font-weight: 400;
        text-align: center;
        white-space: nowrap;
        vertical-align: middle;
        -webkit-user-select: none;
        -moz-user-select: none;
        -ms-user-select: none;
        user-select: none;
        border: 1px solid transparent;
        padding: 0.375rem 0.75rem;
        font-size: 1rem;
        line-height: 1.5;
        border-radius: 0.25rem;
    }

    .btn:hover,
    .btn:focus {
        text-decoration: none;
    }

    .btn:focus,
    .btn.focus {
        outline: 0;
        box-shadow: 0 0 0 0.2rem rgba(0, 123, 255, 0.25);
    }

    @include respond-to(desktop) {
        .col-md-12 {
            -webkit-box-flex: 0;
            -ms-flex: 0 0 100%;
            flex: 0 0 100%;
            max-width: 100%;
        }

        .col-md-4 {
            -webkit-box-flex: 0;
            -ms-flex: 0 0 33.333333%;
            flex: 0 0 33.333333%;
            max-width: 33.333333%;
        }
    }
}
