///	This mixin is especially useful for error message text

@mixin error_message {
    @include t-text-4;

    display: block;
    margin-top: rem-calc(8);
    text-align: left;
    color: $error-color;
}
