.b-footer_payment {
    @include respond-to(mobile) {
        margin-bottom: rem-calc(15);
    }

    &-title {
        @include font(main_med, rem-calc(12));
    }

    &-row {
        display: flex;
        flex-wrap: wrap;
        margin-left: rem-calc(-4);
        margin-right: rem-calc(-4);
    }

    &-item {
        flex: 1 1 rem-calc(48);
        max-width: rem-calc(48);
        padding-left: rem-calc(4);
        padding-right: rem-calc(4);
    }
}
